import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { ApiCommon } from "../ApiCommon.tsx";
import DatamartOrganizationRequest from "../../types/request/DatamartOrganizationRequest.tsx";
import DatamartOrganizationResponse from "../../types/response/DatamartOrganizationResponse.tsx";
import DatamartSummaryRequest from "../../types/request/DatamartSummaryRequest.tsx";
import DatamartSummaryResponse from "../../types/response/DatamartSummaryResponse.tsx";
import DatamartProductResponse from "../../types/response/DatamartProductResponse.tsx";
import DatamartProductRequest from "../../types/request/DatamartProductRequest.tsx";
import DatamartEquipmentRequest from "../../types/request/DatamartEquipmentRequest.tsx";
import DatamartEquipmentResponse from "../../types/response/DatamartEquipmentResponse.tsx";
import DatamartRenewableEnergyRequest from "../../types/request/DatamartRenewableEnergyRequest.tsx";
import DatamartRenewableEnergyResponse from "../../types/response/DatamartRenewableEnergyResponse.tsx";

const DatamartApi = () => {
  const { CustomHeaders } = ApiCommon();

  /**
   * 企業軸データマート取得API
   * @param request
   */
  const DatamartOrganization = (
    request: DatamartOrganizationRequest,
    onSuccess,
    onError
  ) => {
    return useQuery(
      "datamartOrganization",
      async () => {
        const response = await axios.post<DatamartOrganizationResponse>(
          process.env.REACT_APP_HOST_URL + "/v1/datamarts/organization",
          request,
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };
  /**
   * サマリデータマート取得API
   * @param request
   */
  const DatamartSummary = (
    request: DatamartSummaryRequest,
    onSuccess,
    onError
  ) => {
    return useQuery(
      "datamartSummary",
      async () => {
        const response = await axios.post<DatamartSummaryResponse>(
          process.env.REACT_APP_HOST_URL + "/v1/datamarts/summary",
          request,
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };
  /**
   * 製品軸データマート取得API
   * @param request
   */
  const DatamartProduct = (
    request: DatamartProductRequest,
    onSuccess,
    onError
  ) => {
    return useQuery(
      "datamartProduct",
      async () => {
        const response = await axios.post<DatamartProductResponse>(
          process.env.REACT_APP_HOST_URL + "/v1/datamarts/product",
          request,
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };
  /**
   * 製品軸データマート取得API
   * @param request
   */
  const DatamartProductMutation = (onSuccess, onError) => {
    return useMutation(
      async (request: DatamartProductRequest) => {
        const response = await axios.post<DatamartProductResponse>(
          process.env.REACT_APP_HOST_URL + "/v1/datamarts/product",
          request,
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };
  /**
   * 設備データマート取得API
   * @param request
   */
  const DatamartEquipment = (
    request: DatamartEquipmentRequest,
    onSuccess,
    onError
  ) => {
    return useQuery(
      "datamartEquipment",
      async () => {
        const response = await axios.post<DatamartEquipmentResponse>(
          process.env.REACT_APP_HOST_URL + "/v1/datamarts/equipment",
          request,
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };
  /**
   * 再エネ調達データマート取得API
   * @param request
   */
  const DatamartRenweableEnergy = (onSuccess, onError) => {
    return useMutation(
      async (request: DatamartRenewableEnergyRequest) => {
        const response = await axios.post<DatamartRenewableEnergyResponse>(
          process.env.REACT_APP_HOST_URL + "/v1/datamarts/renewable-energy",
          request,
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  return {
    DatamartOrganization,
    DatamartSummary,
    DatamartProduct,
    DatamartProductMutation,
    DatamartEquipment,
    DatamartRenweableEnergy,
  };
};

export default DatamartApi;
