import { useMutation } from "react-query";
import axios from "axios";
import { ExternalLinks } from "../../types/response/ExternalLinksResponse.tsx";
import { ApiCommon } from "../ApiCommon.tsx";

// リンクAPI
const LinksApi = () => {
  const { CustomHeaders } = ApiCommon();
  /**
   * リンク取得API
   * @param request
   */
  const GetLinks = (onSuccess, onError) => {
    return useMutation(
      async () => {
        const response = await axios.get<ExternalLinks[]>(
          process.env.REACT_APP_HOST_URL + "/v1/links/",
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  /**
   * リンクリダイレクトAPI
   * @param request
   */
  const GetLinksRedirect = (onSuccess, onError) => {
    return useMutation(
      async (url: string) => {
        const response = await axios.get(
          process.env.REACT_APP_HOST_URL +
            "/v1/links/redirect?url=" +
            encodeURIComponent(url),
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  return { GetLinks, GetLinksRedirect };
};

export default LinksApi;
