import { useContext, useState } from "react";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import { useNavigate } from "react-router-dom";
import { jwtDecode, JwtPayload } from "jwt-decode";
import RefreshApi from "../features/api/RefreshApi.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";

export const useRefresh = () => {
  const Message = MessageType();
  const [isSuccess, setSuccess] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(false);
  const appContext = useContext(AppContext);
  const { onCommonError } = ApiCommon();
  const navigate = useNavigate();
  const { RefreshToken } = RefreshApi();

  /**
   * IDトークン、アクセストークン期日確認
   * 有効期限内のデータか確認
   * true:有効期限内 false:有効期限外
   */
  const checkExpiryDate = () => {
    let access_token = appContext?.accessToken;
    if (!access_token || !access_token.exp) {
      access_token = jwtDecode(
        localStorage.getItem("access_token") || ""
      ) as JwtPayload;
      const id_token = jwtDecode(localStorage.getItem("id_token") || "");
      appContext?.setAccessToken(access_token);
      appContext?.setIdToken(id_token);
    }
    if (access_token && access_token.exp) {
      // 有効期限確認
      const expiryDate = new Date(access_token.exp * 1000);
      const today = new Date();
      if (today.getTime() < expiryDate.getTime()) {
        return true;
      }
    }
    return false;
  };

  /**
   * リフレッシュAPI成功時
   * アクセストークン、IDトークンをローカルストレージに再格納する
   */
  const onRefreshSuccess = (data) => {
    setLoading(false);
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    const access_token = jwtDecode(data.access_token);
    const id_token = jwtDecode(data.id_token);
    localStorage.setItem("access_token", data.access_token);
    localStorage.setItem("id_token", data.id_token);
    appContext?.setAccessToken(access_token);
    appContext?.setIdToken(id_token);
    setSuccess(true);
  };

  /**
   * リフレッシュAPI失敗時
   * エラーメッセージを設定し、ログイン画面へ遷移する
   */
  const onRefreshError = (error) => {
    onCommonError(error, Message.ERROR_ERROR_RELOGIN);
    setSuccess(false);
    setLoading(false);
    return navigate("/admin/login?status=403");
  };

  /**
   * リフレッシュAPI処理
   * @returns
   */
  const refreshApi = async () => {
    setSuccess(false);
    setLoading(true);
    appContext?.setErrorOpen(false);
    appContext?.setAlertMessage("");
    exeRefresh.refetch();
  };

  // リフレッシュAPI実行
  const exeRefresh = RefreshToken(onRefreshSuccess, onRefreshError);

  return { refreshApi, checkExpiryDate, isSuccess, setSuccess, isLoading };
};
