import React, { useContext, useEffect } from "react";
import { Box, List } from "@mui/material";
import "@fontsource/poppins/600.css";
import classes from "./SideMenuField.module.scss";
import SideMenu, { SideMenuChild } from "../../../types/SideMenu.tsx";
import SideMenuItem from "../../parts/ListItem/SideMenuItem.tsx";
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import AppContext from "../../../contexts/AppContext.tsx";
import { IconButton, Typography } from "@mui/material";
import { ReactComponent as Symbol } from "../../../assets/images/logo_symbol.svg";
import { ReactComponent as LeftArrow } from "../../../assets/images/SideMenu/icon_left_arrow.svg";
import { useNavigate, useLocation } from "react-router-dom";
import SideMenusType from "../../../types/SideMenus.tsx";
import UserContext from "../../../contexts/UserContext.tsx";
import {
  SettingSideMenuEnum,
  SideMenuEnum,
} from "../../../types/enum/SideMenuEnum.tsx";
import { useTranslation } from "react-i18next";

type SideMenuFieldProps = {
  settingFlag: boolean;
};
const SideMenuField: React.FC<SideMenuFieldProps> = (props) => {
  const { SideMenus, BottomSideMenus, SettingSideMenus } = SideMenusType();
  const location = useLocation();
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const energySavingFlag = userContext ? userContext.energySavingFlag : false;
  const freeAnalysisFlag = userContext ? userContext.freeAnalysisFlag : false;
  const productSpecificFlag = userContext
    ? userContext.productSpecificFlag
    : false;
  const sideMenus = props.settingFlag ? SettingSideMenus : SideMenus;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleDrawerToggle = () => {
    appContext?.setSideOpen(!appContext.sideOpen);
  };

  const handleListItemClick = (id: string) => {
    let selectedId = id;
    if (id === SideMenuEnum.product_parent) {
      selectedId = SideMenuEnum.summary;
    } else if (id === SideMenuEnum.company_parent) {
      selectedId = SideMenuEnum.company;
    } else if (id === SettingSideMenuEnum.security) {
      selectedId = SettingSideMenuEnum.setting_otp;
    }

    appContext?.setSideItem(selectedId);
    sideNavigate(selectedId);
  };

  const sideNavigate = (id: string) => {
    if (id) {
      return navigate(`/${id}`, { state: { from: location.pathname } });
    }
  };

  const isChildren = (item: SideMenu) => {
    if (
      item.children.length !== 0 &&
      item.children.find((value) => {
        return value.id === appContext?.sideItem;
      })
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    // TODO 権限によるサイドメニューの表示一覧作成
  }, []);

  const checkOption = (child: SideMenuChild) => {
    if (child.id === SideMenuEnum.product) {
      return productSpecificFlag;
    }
    return true;
  };

  /**
   * 仮設定TODO後日削除
   */
  const checkOptionDemo = (item: SideMenu) => {
    if (item.id === SideMenuEnum.product_parent) {
      return productSpecificFlag;
    }
    return true;
  };

  const handleBackToHomeClick = () => {
    return navigate(`/${SideMenuEnum.home}`, {
      state: { from: location.pathname },
    });
  };

  return (
    <>
      <Box className={classes.icons}>
        <Symbol />
        {appContext?.sideOpen && (
          <Box className={classes.iconsTitle}>EcoNiPass</Box>
        )}
      </Box>
      <List sx={{ pt: 0 }}>
        {sideMenus.map((item) => (
          <>
            {checkOptionDemo(item) && (
              <React.Fragment key={item.id}>
                <SideMenuItem
                  id={item.id}
                  title={item.title}
                  icon={item.id}
                  open={appContext?.sideOpen || false}
                  selected={appContext?.sideItem === item.id}
                  settingFlag={props.settingFlag}
                  handleClick={(id) => handleListItemClick(id)}
                />
                {isChildren(item) && (
                  <List component="div" disablePadding>
                    {item.children?.map((child) => (
                      <>
                        {checkOption(child) && (
                          <SideMenuItem
                            id={child.id}
                            title={child.title}
                            icon={child.id}
                            open={appContext?.sideOpen || false}
                            isChild
                            selected={appContext?.sideItem === child.id}
                            settingFlag={props.settingFlag}
                            handleClick={(id) => handleListItemClick(id)}
                          />
                        )}
                      </>
                    ))}
                  </List>
                )}
              </React.Fragment>
            )}
          </>
        ))}
      </List>
      {appContext?.sideOpen ? (
        <Box
          className={
            props.settingFlag ? classes.openSettingDiv : classes.openDiv
          }
        >
          {!props.settingFlag && (
            <List component="div" disablePadding>
              {BottomSideMenus.map((item) => (
                <SideMenuItem
                  id={item.id}
                  title={item.title}
                  icon={item.id}
                  open={appContext?.sideOpen || false}
                  selected={appContext?.sideItem === item.id}
                  settingFlag={props.settingFlag}
                  handleClick={(id) => handleListItemClick(id)}
                />
              ))}
            </List>
          )}
          <List component="div" disablePadding className={classes.openButton}>
            <KeyboardDoubleArrowLeft onClick={handleDrawerToggle} />
          </List>
          {props.settingFlag && (
            <List component="div" disablePadding>
              <IconButton
                className={classes.backToHomeButton}
                onClick={handleBackToHomeClick}
              >
                <LeftArrow className={classes.backToHomeButtonImage} />
                <Typography
                  variant="body1"
                  component="div"
                  sx={{ flexGrow: 1 }}
                  className={classes.backToHomeButtonTitle}
                >
                  {t("type.sideMenu.settingSideMunus.backToHome")}
                </Typography>
              </IconButton>
            </List>
          )}
        </Box>
      ) : (
        <Box
          className={
            props.settingFlag ? classes.closeSettingDiv : classes.closeDiv
          }
        >
          {!props.settingFlag && (
            <List component="div" disablePadding>
              {BottomSideMenus.map((item) => (
                <SideMenuItem
                  id={item.id}
                  title={item.title}
                  icon={item.id}
                  open={appContext?.sideOpen || false}
                  selected={appContext?.sideItem === item.id}
                  settingFlag={props.settingFlag}
                  handleClick={(id) => handleListItemClick(id)}
                />
              ))}
            </List>
          )}
          <List component="div" disablePadding className={classes.closeButton}>
            <KeyboardDoubleArrowRight onClick={handleDrawerToggle} />
          </List>
          {props.settingFlag && (
            <List component="div" disablePadding>
              <IconButton
                className={classes.backToHomeCloseButton}
                onClick={handleBackToHomeClick}
              >
                <LeftArrow className={classes.backToHomeButtonImage} />
              </IconButton>
            </List>
          )}
        </Box>
      )}
    </>
  );
};

export default SideMenuField;
