import { useQuery } from "react-query";
import axios from "axios";
import MasterDataResponse from "../../types/response/MasterDataResponse.tsx";
import CommonUtils from "../../common/utils/CommonUtils.tsx";
import { ApiCommon } from "../ApiCommon.tsx";

const MasterDataApi = () => {
  const { CustomHeaders } = ApiCommon();
  /**
   * マスターデータ取得API
   * @param request
   */
  const GetMasterData = (onSuccess, onError) => {
    return useQuery(
      "master",
      async () => {
        const response = await axios.get<MasterDataResponse>(
          process.env.REACT_APP_HOST_URL + "/v1/masterdata/",
          {
            params: {
              time_zone_offset: CommonUtils.getTimeZoneOffset(),
            },
            headers: CustomHeaders(),
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  return { GetMasterData };
};

export default MasterDataApi;
