import React from "react";
import classes from "./SettingAccessControlLayout.module.scss";
import { Box } from "@mui/material";
import AccessControlForm from "../../forms/SettingAccessControl/AccessControlForm.tsx";

type SettingAccessControlLayoutProps = {};

const SettingAccessControlLayout: React.FC<SettingAccessControlLayoutProps> = (
  props
) => {
  return (
    <Box className={classes.accessControl}>
      <AccessControlForm />
    </Box>
  );
};

export default SettingAccessControlLayout;
