import { useContext, useState } from "react";
import FileApi from "../features/api/FileApi.tsx";
import FileOrganizationRequest, {
  defaultOrganizationRequest,
} from "../types/request/FileOrganizationRequest.tsx";
import FileProductRequest, {
  defaultProductRequest,
} from "../types/request/FileProductRequest.tsx";
import FileSummaryRequest, {
  defaultSummaryRequest,
} from "../types/request/FileSummaryRequest.tsx";
import DatamartSearchContext from "../contexts/DatamartSearchContext.tsx";
import FileEquipmentRequest, {
  defaultEquipmentRequest,
} from "../types/request/FileEquipmentRequest.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";

export const useDatamartFile = () => {
  const { searchData } = useContext(DatamartSearchContext);
  const { onCommonBlobError } = ApiCommon();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const [organizationRequest, setOrganizationRequest] =
    useState<FileOrganizationRequest>(defaultOrganizationRequest);
  const [summaryRequest, setSummaryRequest] = useState<FileSummaryRequest>(
    defaultSummaryRequest
  );
  const [productRequest, setProductRequest] = useState<FileProductRequest>(
    defaultProductRequest
  );
  const [equipmentRequest, setEquipmentRequest] =
    useState<FileEquipmentRequest>(defaultEquipmentRequest);
  const {
    DownloadOrganization,
    DownloadSummary,
    DownloadProduct,
    DownloadEquipment,
  } = FileApi();

  /**
   * ダウンロードAPI成功時
   */
  const onSuccess = (data) => {
    setIsLoading(false);
    let url;
    if (type === "csv") {
      url = window.URL.createObjectURL(
        new Blob([data.data], { type: "text/csv;charset=utf-8;" })
      );
    } else {
      url = window.URL.createObjectURL(new Blob([data.data]));
    }

    const link = document.createElement("a");
    link.href = url;
    const headers = data.headers;
    const contentDisposition = headers["content-disposition"];

    // ダウンロードファイル名を指定
    let fileName = "";
    const fileNameMatch =
      contentDisposition && contentDisposition.match(/filename\*=utf-8''(.+)/);
    if (fileNameMatch && fileNameMatch[1]) {
      fileName = decodeURIComponent(fileNameMatch[1]);
    }
    if (!fileName) {
      // リンクを削除
      link.remove();
      return;
    }
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();

    // リンクを削除
    link.remove();
  };

  /**
   * ダウンロードAPI失敗時
   */
  const onError = (error) => {
    onCommonBlobError(error);
    setIsLoading(false);
  };

  /**
   * 企業軸帳票出力API処理
   * @returns
   */
  const organizationFiles = async (type: string) => {
    if (isLoading) return;
    setType(type);
    organizationRequest.file_type = type;
    organizationRequest.filter_params = searchData.filter_organization_params;
    setOrganizationRequest(organizationRequest);
    setIsLoading(true);
    exeOrganizationFiles.refetch();
  };

  /**
   * サマリ帳票出力API処理
   * @returns
   */
  const summaryFiles = async (type: string) => {
    if (isLoading) return;
    setType(type);
    summaryRequest.file_type = type;
    summaryRequest.filter_params = searchData.filter_params;
    setSummaryRequest(summaryRequest);
    setIsLoading(true);
    exeSummaryFiles.refetch();
  };

  /**
   * 製品軸帳票出力API処理
   * @returns
   */
  const productFiles = async (type: string) => {
    if (isLoading) return;
    setType(type);
    productRequest.file_type = type;
    const filter = searchData.filter_params;
    delete filter.self_manufacturing;
    productRequest.filter_params = filter;
    setProductRequest(productRequest);
    setIsLoading(true);
    exeProductFiles.refetch();
  };

  /**
   * 設備帳票出力API処理
   * @returns
   */
  const equipmentFiles = async (type: string) => {
    if (isLoading) return;
    setType(type);
    equipmentRequest.file_type = type;
    equipmentRequest.filter_params = searchData.filter_params;
    equipmentRequest.filter_params.self_manufacturing = equipmentRequest
      .filter_params.self_manufacturing
      ? equipmentRequest.filter_params.self_manufacturing
      : [];
    setEquipmentRequest(equipmentRequest);
    setIsLoading(true);
    exeEquipmentFiles.refetch();
  };

  // 企業軸帳票出力API実行
  const exeOrganizationFiles = DownloadOrganization(
    organizationRequest,
    onSuccess,
    onError
  );

  // サマリ帳票出力API実行
  const exeSummaryFiles = DownloadSummary(summaryRequest, onSuccess, onError);

  // 製品軸帳票出力API実行
  const exeProductFiles = DownloadProduct(productRequest, onSuccess, onError);

  // 設備帳票出力API実行
  const exeEquipmentFiles = DownloadEquipment(
    equipmentRequest,
    onSuccess,
    onError
  );

  return {
    organizationFiles,
    summaryFiles,
    productFiles,
    equipmentFiles,
    isLoading,
  };
};
