import { useMutation } from "react-query";
import axios from "axios";

// 外部API
const ExternalApi = {
  /**
   * マスターデータ取得API
   * @param request
   */
  GetGlobalIPAddress: (onSuccess, onError) => {
    return useMutation(
      async () => {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/global_ip",
          {
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },
};

export default ExternalApi;
