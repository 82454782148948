import React from "react";
import ContentLayout from "../../layouts/Common/ContentLayout.tsx";
import SettingOptLayout from "../../layouts/SettingOtp/SettingOtpLayout.tsx";
import { SecurityProvider } from "../../../contexts/SecurityContext.tsx";

const SettingOtpPage = () => {
  return (
    <SecurityProvider>
      <ContentLayout>
        <SettingOptLayout />
      </ContentLayout>
    </SecurityProvider>
  );
};

export default SettingOtpPage;
