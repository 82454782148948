import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Apartment,
  PrecisionManufacturing,
  TextSnippet,
  ContentPasteSearch,
  CorporateFare,
  Inventory2,
  QueryStats,
  Construction,
  Security,
  Lock,
  LockPerson,
  LaptopChromebook,
  ManageAccounts,
  Description,
  EnergySavingsLeaf,
} from "@mui/icons-material";
import { ReactComponent as HomeIcon } from "../../../assets/images/SideMenu/icon_home.svg";
import { ReactComponent as Keyboard } from "../../../assets/images/SideMenu/icon_data_input_color.svg";
import { ReactComponent as Warming } from "../../../assets/images/SideMenu/icon_warming.svg";
import { ReactComponent as Language } from "../../../assets/images/SideMenu/icon_language.svg";
import { ReactComponent as LinkIcon } from "../../../assets/images/SideMenu/icon_link.svg";
import React from "react";
import classes from "./SideMenuItem.module.scss";
import {
  BottomSideMenusEnum,
  SettingSideMenuEnum,
  SideMenuEnum,
} from "../../../types/enum/SideMenuEnum.tsx";

type SideMenuItemProps = {
  id: string;
  title: string;
  icon: string;
  className?: string;
  selected: boolean;
  open: boolean;
  settingFlag: boolean;
  isChild?: boolean;
  handleClick(item: string): void;
};

const itemIcon = (icon) => {
  switch (icon) {
    case SideMenuEnum.home:
      return <HomeIcon />;
    case SideMenuEnum.data_input:
      return <Keyboard />;
    case SideMenuEnum.company:
      return <CorporateFare />;
    case SideMenuEnum.company_parent:
      return <Apartment />;
    case SideMenuEnum.product:
      return <Inventory2 />;
    case SideMenuEnum.product_parent:
      return <PrecisionManufacturing />;
    case "energy_saving":
      return <TextSnippet />;
    case "warming_countermeasures":
      return <Warming />;
    case "product_free_analysis":
    case "company_free_analysis":
      return <QueryStats />;
    case SideMenuEnum.summary:
      return <ContentPasteSearch />;
    case SideMenuEnum.facility:
      return <Construction />;
    case BottomSideMenusEnum.external_links:
      return <LinkIcon />;
    case SettingSideMenuEnum.language:
      return <Language />;
    case SettingSideMenuEnum.users_manage:
      return <ManageAccounts />;
    case SettingSideMenuEnum.security:
      return <Security />;
    case SettingSideMenuEnum.change_password:
      return <Lock />;
    case SettingSideMenuEnum.setting_otp:
      return <LockPerson />;
    case SettingSideMenuEnum.access_control:
      return <LaptopChromebook />;
    case SettingSideMenuEnum.logs:
      return <Description />;
    case SideMenuEnum.renewable_energy:
      return <EnergySavingsLeaf />;
    default:
      return "";
  }
};

const SideMenuItem: React.FC<SideMenuItemProps> = (props) => {
  const getListItemClassName = () => {
    if (props.settingFlag) {
      return props.selected ? classes.selectedSettingItem : "";
    } else {
      return props.selected ? classes.selectedItem : "";
    }
  };

  return (
    <ListItem
      className={`${classes.listItem} ${getListItemClassName()} ${
        props.isChild ? classes.childItem : ""
      } ${props.className}`}
    >
      <ListItemButton
        onClick={() => props.handleClick(props.id)}
        className={classes.sideButton}
      >
        <ListItemIcon className={classes.itemIcon}>
          {itemIcon(props.icon)}
        </ListItemIcon>
        {props.open && (
          <ListItemText primary={props.title} className={classes.title} />
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default SideMenuItem;
