export const defaultCheckBoxType = {
  scopes: {},
  organizations: {},
  offices: {},
  energies: {},
  major_categories: {},
  middle_categories: {},
  minor_categories: {},
  parts_or_energies: {},
  products: {},
  process: {},
};

export type CheckBoxType = {
  code: string;
  checked: boolean;
  label: string;
};

export type CheckBoxDataType = {
  scopes: { [key: string]: CheckBoxType };
  organizations: { [key: string]: CheckBoxType };
  offices: { [key: string]: CheckBoxType };
  energies: { [key: string]: CheckBoxType };
  major_categories: { [key: string]: CheckBoxType };
  middle_categories: { [key: string]: CheckBoxType };
  minor_categories: { [key: string]: CheckBoxType };
  parts_or_energies: { [key: string]: CheckBoxType };
  products: { [key: string]: CheckBoxType };
  process: { [key: string]: CheckBoxType };
};

export default CheckBoxDataType;
