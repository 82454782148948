import React from "react";
import classes from "./CurrentIPAddressField.module.scss";
import { useTranslation } from "react-i18next";
import IPAddress from "../../parts/Item/IPAddress.tsx";

const CurrentIPAddressField = () => {
  const { t } = useTranslation();

  return (
    <>
      {t("setting.accessControl.currentIPAddress")}
      <IPAddress />
    </>
  );
};

export default CurrentIPAddressField;
