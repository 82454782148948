import { useMutation } from "react-query";
import axios from "axios";
import { ApiCommon } from "../ApiCommon.tsx";

const CorporateApi = () => {
  const { CustomHeaders } = ApiCommon();
  /**
   * 2段階認証設定API
   * @param useId
   */
  const PutCorporateMfa = (onSuccess, onError) => {
    return useMutation(
      async (request: { corporateNumber: string; mfaFlag: boolean }) => {
        const response = await axios.put(
          process.env.REACT_APP_HOST_URL +
            "/v1/corporates/" +
            request.corporateNumber +
            "/mfa",
          {
            mfa_flag: request.mfaFlag,
          },
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  return {
    PutCorporateMfa,
  };
};

export default CorporateApi;
