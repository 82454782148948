import { Card, CardActionArea, CardContent } from "@mui/material";
import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import classes from "./ForwardButton.module.scss";

type CloseButtonProps = {
  className?: string;
  iconClassName?: string;
  handleClick: React.MouseEventHandler;
};

const ForwardButton: React.FC<CloseButtonProps> = (props) => {
  return (
    <Card className={`${props.className} ${classes.card}`}>
      <CardActionArea onClick={props.handleClick}>
        <CardContent
          className={`${props.iconClassName} ${classes.cardContent}`}
        >
          <ArrowForwardIosIcon sx={{ color: "text.secondary" }} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ForwardButton;
