import { useQuery } from "react-query";
import axios from "axios";
import InquiriesRequest from "../../types/request/InquiriesRequest.tsx";
import { ApiCommon } from "../ApiCommon.tsx";

const InquiryApi = () => {
  const { CustomHeaders } = ApiCommon();
  /**
   * お問い合わせ送信API
   * @param request
   */
  const PostInquiry = (request: InquiriesRequest, onSuccess, onError) => {
    return useQuery(
      "postInquiry",
      async () => {
        const response = await axios.post(
          process.env.REACT_APP_HOST_URL + "/v1/inquiries/",
          request,
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };
  return { PostInquiry };
};

export default InquiryApi;
