import React, { useContext, useEffect, useState } from "react";
import classes from "./CorporatListForm.module.scss";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { UserManageFuatures } from "../../../features/UserManageFuatures.tsx";
import UserContext from "../../../contexts/UserContext.tsx";
import { UserInfo } from "../../../types/response/UserInfoResponse.tsx";
import Oragnization from "../../../types/response/Oragnization.tsx";
import EditOptDialogForm from "./EditOptDialogForm.tsx";
import { useCorporate } from "../../../hooks/useCorporate.tsx";
import SecurityContext from "../../../contexts/SecurityContext.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";
import { UserFuatures } from "../../../features/UserFuatures.tsx";

type CorporatListFormProps = {};

const CorporatListForm: React.FC<CorporatListFormProps> = (props) => {
  const { t } = useTranslation();
  const { isAdministrator } = UserFuatures();
  const { dispChipOtp } = UserManageFuatures();
  const { putCorporateMfaApi, corporateApiSuccess, corporateApiLoading } =
    useCorporate();
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const securityContext = useContext(SecurityContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [editCorporat, setEditCorporat] = useState<Oragnization>();
  const [editOpen, setEditOpen] = useState(false);
  const isAdmin = isAdministrator();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditClick = (data: Oragnization) => {
    setEditCorporat({ ...data });
    setEditOpen(true);
  };
  const handleEditClose = () => {
    setEditOpen(false);
  };
  const handleEditSave = (corporateNumber: string, mfaFlag: boolean) => {
    putCorporateMfaApi(corporateNumber, mfaFlag);
  };

  /**
   * ユーザー更新処理が正常終了したらダイアログをクローズする
   */
  useEffect(() => {
    if (corporateApiSuccess) handleEditClose();
  }, [corporateApiSuccess]);

  /**
   * 表示ユーザー一覧データ作成処理
   * 絞り込み内容に応じて、表示するユーザー一覧を作成する
   * @returns
   */
  const dispCorporatList = () => {
    const result: Oragnization[] = [];

    // 同じ法人番号のデータがある場合、2段階認証(mfa_flag)がtrueになっているデータを採用
    userInfo.organizations.forEach((data) => {
      const index = result.findIndex((val) => {
        return val.corporate_number === data.corporate_number;
      });
      if (index === -1) {
        result.push(data);
      } else if (data.mfa_flag === true) {
        result[index].mfa_flag = data.mfa_flag;
      }
    });

    return result
      .filter((data) => {
        return (
          data.company_name.includes(securityContext.searchText || "") ||
          data.office_name.includes(securityContext.searchText || "") ||
          data.corporate_number.includes(securityContext.searchText || "")
        );
      })
      .filter((data) => {
        return (
          securityContext.searchOpt === "all" ||
          (data.mfa_flag !== undefined &&
            data.mfa_flag.toString() === securityContext.searchOpt)
        );
      });
  };

  return (
    <>
      <TableContainer
        className={classes.activeInfoTable}
        sx={{ maxHeight: "55vh" }}
      >
        <Table
          size="small"
          sx={{ tableLayout: "fixed", width: "100%", height: "100%" }}
          stickyHeader
        >
          <TableHead>
            <TableRow key="th">
              <TableCell sx={{ width: "35%" }}>
                {t("setting.otpSetting.corporatTable.companyName")}
              </TableCell>
              <TableCell sx={{ width: "25%" }}>
                {t("setting.otpSetting.corporatTable.corporatNumber")}
              </TableCell>
              <TableCell sx={{ width: "20%" }}>
                {t("setting.otpSetting.corporatTable.otp")}
              </TableCell>
              <TableCell sx={{ width: "20%" }}>
                {t("setting.otpSetting.corporatTable.edit")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ overflowY: "auto" }}>
            {dispCorporatList().map((data, i) => {
              return (
                <TableRow key={"td_" + i}>
                  <TableCell sx={{ width: "35%" }}>
                    {data.company_name}
                  </TableCell>
                  <TableCell sx={{ width: "25%" }}>
                    {data.corporate_number}
                  </TableCell>
                  <TableCell sx={{ width: "20%" }}>
                    {dispChipOtp(data.mfa_flag, classes.chipCell)}
                  </TableCell>
                  <TableCell sx={{ width: "20%" }}>
                    <IconButton
                      onClick={() => handleEditClick(data)}
                      disabled={!isAdmin}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        labelRowsPerPage={t("common.rowsPerPage")}
        component="div"
        count={dispCorporatList().length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.tablePagination}
      />
      <EditOptDialogForm
        open={editOpen}
        handleClose={handleEditClose}
        handleSave={handleEditSave}
        editCorporat={editCorporat}
      />
      {corporateApiLoading && <CircularProgressDialog />}
    </>
  );
};

export default CorporatListForm;
