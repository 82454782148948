import { useMutation, useQuery } from "react-query";
import axios from "axios";
import UploadsHisotriesRequest from "../../types/request/UploadsHisotriesRequest";
import UploadsHistoriesResponse from "../../types/response/UploadsHistoriesResponse";
import FileUploadRequest from "../../types/request/FileUploadRequest";
import FileDownloadRequest from "../../types/request/FileDownloadRequest";
import FileOrganizationRequest from "../../types/request/FileOrganizationRequest.tsx";
import FileSummaryRequest from "../../types/request/FileSummaryRequest.tsx";
import FileProductRequest from "../../types/request/FileProductRequest.tsx";
import FileEquipmentRequest from "../../types/request/FileEquipmentRequest.tsx";
import FileDeleteRequest from "../../types/request/FileDeleteRequest.tsx";
import CommonUtils from "../../common/utils/CommonUtils.tsx";
import { ApiCommon } from "../ApiCommon.tsx";
import FileOperationLogsRequest from "../../types/request/FileOperationLogsRequest.tsx";

const qs = require("qs");

const FileApi = () => {
  const {
    CustomHeaders,
    CustomHeadersFormData,
    CustomHeadersOctetStream,
    CustomHeadersCsv,
  } = ApiCommon();

  /**
   * アップロード履歴API
   * @param request
   */
  const UploadsHistories = (
    request: UploadsHisotriesRequest,
    onSuccess,
    onError
  ) => {
    return useQuery(
      "uploadsHistories",
      async () => {
        const response = await axios.get<UploadsHistoriesResponse[]>(
          process.env.REACT_APP_HOST_URL + "/v1/histories/uploads",
          {
            params: request,
            paramsSerializer: (params) =>
              qs.stringify(params, { arrayFormat: "repeat" }),
            headers: CustomHeaders(),
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  /**
   * アップロードAPI
   * @param request
   */
  const UploadFiles = (request: FileUploadRequest, onSuccess, onError) => {
    return useQuery(
      "uploadFiles",
      async () => {
        const response = await axios.post(
          process.env.REACT_APP_HOST_URL +
            "/v1/files/upload?file_type=" +
            request.file_type,
          request.files,
          {
            headers: CustomHeadersFormData(),
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  /**
   * ダウンロードAPI
   * @param request
   */
  const DownloadFiles = (request: FileDownloadRequest, onSuccess, onError) => {
    return useQuery(
      "downloadFiles",
      async () => {
        const response = await axios.get<Blob>(
          process.env.REACT_APP_HOST_URL + "/v1/files/download",
          {
            params: request,
            responseType: "blob",
            headers: CustomHeadersOctetStream(),
          }
        );
        return { data: response.data, headers: response.headers };
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  /**
   * ファイルデータ削除API
   * @param request
   */
  const DeleteFiles = (request: FileDeleteRequest, onSuccess, onError) => {
    return useQuery(
      "deleteFiles",
      async () => {
        const response = await axios.delete(
          process.env.REACT_APP_HOST_URL + "/v1/files/delete",
          {
            data: request,
            headers: CustomHeaders(),
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  /**
   * 企業軸帳票出力API
   * @param request
   */
  const DownloadOrganization = (
    request: FileOrganizationRequest,
    onSuccess,
    onError
  ) => {
    return useQuery(
      "downloadOrganization",
      async () => {
        const response = await axios.post<Blob>(
          process.env.REACT_APP_HOST_URL +
            "/v1/files/outputs/organization/download",
          request,
          {
            responseType: "blob",
            params: {
              time_zone_offset: CommonUtils.getTimeZoneOffset(),
            },
            headers: CustomHeadersOctetStream(),
          }
        );
        return { data: response.data, headers: response.headers };
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  /**
   * サマリ帳票出力API
   * @param request
   */
  const DownloadSummary = (request: FileSummaryRequest, onSuccess, onError) => {
    return useQuery(
      "downloadSummary",
      async () => {
        const response = await axios.post<Blob>(
          process.env.REACT_APP_HOST_URL + "/v1/files/outputs/summary/download",
          request,
          {
            responseType: "blob",
            params: {
              time_zone_offset: CommonUtils.getTimeZoneOffset(),
            },
            headers: CustomHeadersOctetStream(),
          }
        );
        return { data: response.data, headers: response.headers };
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  /**
   * 製品軸帳票出力API
   * @param request
   */
  const DownloadProduct = (request: FileProductRequest, onSuccess, onError) => {
    return useQuery(
      "downloadProduct",
      async () => {
        const response = await axios.post<Blob>(
          process.env.REACT_APP_HOST_URL + "/v1/files/outputs/product/download",
          request,
          {
            responseType: "blob",
            params: {
              time_zone_offset: CommonUtils.getTimeZoneOffset(),
            },
            headers: CustomHeadersOctetStream(),
          }
        );
        return { data: response.data, headers: response.headers };
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  /**
   * 設備帳票出力API
   * @param request
   */
  const DownloadEquipment = (
    request: FileEquipmentRequest,
    onSuccess,
    onError
  ) => {
    return useQuery(
      "downloadEquipment",
      async () => {
        const response = await axios.post<Blob>(
          process.env.REACT_APP_HOST_URL +
            "/v1/files/outputs/equipment/download",
          request,
          {
            responseType: "blob",
            params: {
              time_zone_offset: CommonUtils.getTimeZoneOffset(),
            },
            headers: CustomHeadersOctetStream(),
          }
        );
        return { data: response.data, headers: response.headers };
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  /**
   * ログ出力API
   * @param request
   */
  const DownloadOperatorLogs = (onSuccess, onError) => {
    return useMutation(
      async (request: FileOperationLogsRequest) => {
        const response = await axios.post<Blob>(
          process.env.REACT_APP_HOST_URL +
            "/v1/files/outputs/user-management/operator-logs/download",
          request,
          {
            responseType: "blob",
            params: {
              time_zone_offset: CommonUtils.getTimeZoneOffset(),
            },
            headers: CustomHeadersOctetStream(),
          }
        );
        return { data: response.data, headers: response.headers };
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  /**
   * ユーザー一覧CSV出力API
   * @param request
   */
  const DownloadUserManagement = (onSuccess, onError) => {
    return useMutation(
      async (organizationId: string) => {
        const response = await axios.post<Blob>(
          process.env.REACT_APP_HOST_URL +
            "/v1/files/outputs/user-management/" +
            organizationId +
            "/users/download",
          {},
          {
            responseType: "blob",
            params: {
              time_zone_offset: CommonUtils.getTimeZoneOffset(),
            },
            headers: CustomHeadersOctetStream(),
          }
        );
        return { data: response.data, headers: response.headers };
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  return {
    UploadsHistories,
    UploadFiles,
    DownloadFiles,
    DeleteFiles,
    DownloadOrganization,
    DownloadSummary,
    DownloadProduct,
    DownloadEquipment,
    DownloadOperatorLogs,
    DownloadUserManagement,
  };
};

export default FileApi;
