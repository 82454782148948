import React, { useContext, useState } from "react";
import { Box, Grid } from "@mui/material";
import classes from "./SearchRenewableEnergyField.module.scss";
import SearchDateField from "./SearchDateField.tsx";
import SearchCheckBoxField from "./SearchCheckBoxField.tsx";
import SearchButtonField from "./SearchButtonField.tsx";
import { useTranslation } from "react-i18next";
import DatamartSearchContext from "../../../contexts/DatamartSearchContext.tsx";
import SearchFeatures from "../../../features/SearchFeatures.tsx";
import DatamartProductContext from "../../../contexts/DatamartProductContext.tsx";
import SelectItem from "../../parts/Select/SelectItem.tsx";
import MasterContext from "../../../contexts/MasterContext.tsx";
import {
  defaultMasterDatas,
  MasterDatas,
} from "../../../types/response/MasterDataResponse.tsx";
import { UnitType } from "../../../types/enum/UnitTypeEnum.tsx";

type SearchRenewableEnergyFieldProps = {};

const SearchRenewableEnergyField: React.FC<SearchRenewableEnergyFieldProps> = (
  props
) => {
  const { t } = useTranslation();
  const { checkBoxData, searchData } = useContext(DatamartSearchContext);

  const masterContext = useContext(MasterContext);
  const masterData = masterContext?.masterData
    ? (masterContext.masterData as MasterDatas)
    : defaultMasterDatas;
  const {
    getCheckBoxInMiddleCategory,
    getCheckBoxInMinorCategory,
    getCheckBoxInPartsOrEnegies,
    setSearchDataBySelect,
  } = SearchFeatures();
  const [selectNumeratorUnit, setSelectNumeratorUnit] = useState(
    searchData.numerator_unit_type || "g"
  );
  const [selectDenominatorUnit, setSelectDenominatorUnit] = useState(
    searchData.denominator_unit_type || "unit_managed"
  );
  const { setSelectCells } = useContext(DatamartProductContext);
  const { NumeratorUnit, DenominatorUnit } = UnitType();

  const handleSelectNumeratorClick = (event) => {
    const name = event.target.value;
    setSelectNumeratorUnit(name);
    setSearchDataBySelect(name, "numerator_unit_type", masterData, true);
  };

  const handleSelectDenominatorClick = (event) => {
    const name = event.target.value;
    setSelectDenominatorUnit(name);
    setSearchDataBySelect(name, "denominator_unit_type", masterData, true);
  };

  const resetData = () => {
    setSelectCells({});
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Box className={classes.searchItem}>
          <SearchDateField
            title={t("header.search.renewableEnergy.targetYear")}
            fromDate={searchData.filter_params.from_target_year_month}
            toDate={searchData.filter_params.to_target_year_month}
          />
          <SearchDateField
            title={t("header.search.renewableEnergy.baseYear")}
            fromDate={searchData.filter_params.from_base_year_month}
            toDate={searchData.filter_params.to_base_year_month}
            isBase={true}
          />
        </Box>
        <Box className={classes.searchItem}>
          <SearchCheckBoxField
            checkBoxData={checkBoxData.process}
            type="process"
            title={t("header.search.renewableEnergy.checkBoxProcess")}
          />
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box className={classes.searchItem}>
          <SearchCheckBoxField
            checkBoxData={checkBoxData.major_categories}
            type="major_categories"
            title={t("header.search.renewableEnergy.checkBoxMajor")}
          />
        </Box>
        <Box className={classes.searchItem}>
          <SearchCheckBoxField
            checkBoxData={getCheckBoxInMiddleCategory(checkBoxData)}
            type="middle_categories"
            title={t("header.search.renewableEnergy.checkBoxMiddle")}
          />
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box className={classes.searchItem}>
          <SearchCheckBoxField
            checkBoxData={getCheckBoxInMinorCategory(checkBoxData)}
            type="minor_categories"
            title={t("header.search.renewableEnergy.checkBoxMinor")}
          />
        </Box>
        <Box className={classes.searchItem}>
          <SearchCheckBoxField
            checkBoxData={getCheckBoxInPartsOrEnegies(checkBoxData)}
            type="parts_or_energies"
            title={t("header.search.renewableEnergy.checkBoxProductEnergies")}
          />
        </Box>
      </Grid>
      <Grid item xs={2} className={classes.searchButtonGrid}>
        <div className={classes.unitSelectItems}>
          <SelectItem
            title={t("header.search.renewableEnergy.selectNumeratorUnit")}
            handleClick={handleSelectNumeratorClick}
            selectValue={selectNumeratorUnit}
            values={NumeratorUnit}
            className={classes.selectItem}
          />
          <SelectItem
            title={t("header.search.renewableEnergy.selectDenominatorUnit")}
            handleClick={handleSelectDenominatorClick}
            selectValue={selectDenominatorUnit}
            values={DenominatorUnit}
            className={classes.selectItem}
          />
        </div>
        <div className={classes.searchButtonItem}>
          <SearchButtonField resetData={resetData} />
        </div>
      </Grid>
    </Grid>
  );
};

export default SearchRenewableEnergyField;
