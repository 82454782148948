import { useQuery } from "react-query";
import axios from "axios";
import { ApiCommon } from "../ApiCommon.tsx";

const LoginApi = () => {
  const { CustomHeader, CustomHeaders } = ApiCommon();
  /**
   * ログインAPI
   * @param id
   * @param password
   */
  const Login = (
    id: string,
    password: string,
    language: string,
    onSuccess,
    onError
  ) => {
    return useQuery(
      "login",
      async () => {
        const response = await axios.post(
          process.env.REACT_APP_HOST_URL + "/v1/admin/auth/login",
          {
            login_id: id,
            password: password,
            language: language,
          },
          {
            headers: CustomHeader(),
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  /**
   * 2段階認証ログインAPI
   * @param id
   * @param otp
   */
  const OtpLogin = (id: string, otp: string, onSuccess, onError) => {
    return useQuery(
      "otpLogin",
      async () => {
        const response = await axios.post(
          process.env.REACT_APP_HOST_URL + "/v1/admin/auth/login/otp",
          {
            login_id: id,
            otp: otp,
          },
          {
            headers: CustomHeader(),
            withCredentials: true,
          }
        );
        return response;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  /**
   * ログアウトAPI
   */
  const Logout = (onSuccess, onError) => {
    return useQuery(
      "Logout",
      async () => {
        const response = await axios.delete(
          process.env.REACT_APP_HOST_URL + "/v1/admin/auth/logout",
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  return { Login, OtpLogin, Logout };
};

export default LoginApi;
