import { useQuery, useMutation } from "react-query";
import axios from "axios";
import UserInfoResponse from "../../types/response/UserInfoResponse.tsx";
import UserInfoRequest from "../../types/request/UserInfoRequest.tsx";
import LanguageRequest from "../../types/request/LanguageRequest.tsx";
import { ApiCommon } from "../ApiCommon.tsx";
import CreateUserRequest from "../../types/request/CreateUserRequest.tsx";
import AssignOrganizationRequest from "../../types/request/AssignOrganizationRequest.tsx";
import OrganizationsResponse from "../../types/response/OrganizationsResponse.tsx";

const UserApi = () => {
  const { CustomHeaders } = ApiCommon();
  /**
   * ユーザー情報取得API
   * @param useId
   */
  const GetUserInfo = (userId: string, onSuccess, onError) => {
    return useQuery(
      "getUser",
      async () => {
        const response = await axios.get<UserInfoResponse>(
          process.env.REACT_APP_HOST_URL + "/v1/users/" + userId,
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };
  /**
   * ユーザー情報更新API
   * @param useId
   */
  const PutUserInfo = (onSuccess, onError) => {
    return useMutation(
      async (request: UserInfoRequest) => {
        const response = await axios.put<UserInfoResponse>(
          process.env.REACT_APP_HOST_URL + "/v1/users/" + request.userId,
          request.requestBody,
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };
  /**
   * 言語設定API
   * @param useId
   */
  const PutLanguage = (onSuccess, onError) => {
    return useMutation(
      async (request: LanguageRequest) => {
        const response = await axios.put(
          process.env.REACT_APP_HOST_URL + "/v1/users/language",
          request,
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };
  /**
   * ユーザー新規作成API
   * @param useId
   */
  const CreateUser = (onSuccess, onError) => {
    return useMutation(
      async (request: CreateUserRequest) => {
        const response = await axios.post(
          process.env.REACT_APP_HOST_URL + "/v1/users/",
          request,
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };
  /**
   * ユーザー削除API
   * @param useId
   */
  const DeleteUser = (onSuccess, onError) => {
    return useMutation(
      async (userId: string) => {
        const response = await axios.delete(
          process.env.REACT_APP_HOST_URL + "/v1/users/" + userId,
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };
  /**
   * 顧客割り当て更新API
   * @param useId
   */
  const PutAssignOrganization = (onSuccess, onError) => {
    return useMutation(
      async (request: AssignOrganizationRequest) => {
        const response = await axios.put(
          process.env.REACT_APP_HOST_URL +
            "/v1/users/" +
            request.userId +
            "/assign-organization",
          request.requestBody,
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };
  /**
   * 顧客情報取得API
   * @param useId
   */
  const GetOrganizations = (onSuccess, onError) => {
    return useMutation(
      async (userId: string) => {
        const response = await axios.get<OrganizationsResponse>(
          process.env.REACT_APP_HOST_URL +
            "/v1/users/" +
            userId +
            "/organizations",
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  return {
    GetUserInfo,
    PutUserInfo,
    PutLanguage,
    CreateUser,
    DeleteUser,
    PutAssignOrganization,
    GetOrganizations,
  };
};

export default UserApi;
