import React, { useContext, useEffect, useState } from "react";
import classes from "./UsersButtonRightField.module.scss";
import { useTranslation } from "react-i18next";
import { IconButton, Typography } from "@mui/material";
import { ReactComponent as CsvImage } from "../../../assets/images/Setting/icon_csv.svg";
import BaseButton from "../../parts/Button/BaseButton.tsx";
import AddUserDialogForm from "../../forms/SettingUsers/AddUserDialogForm.tsx";
import CreateUserRequest from "../../../types/request/CreateUserRequest.tsx";
import UserContext from "../../../contexts/UserContext.tsx";
import { UserInfo } from "../../../types/response/UserInfoResponse.tsx";
import UsersManageContext from "../../../contexts/UsersManageContext.tsx";
import AppContext from "../../../contexts/AppContext.tsx";
import MessageType from "../../../types/Message.tsx";
import { useUser } from "../../../hooks/useUser.tsx";
import { useFile } from "../../../hooks/useFile.tsx";
import { useOrganization } from "../../../hooks/useOrganization.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";
import { UserFuatures } from "../../../features/UserFuatures.tsx";

const UsersButtonRightField = () => {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const usersMagage = useContext(UsersManageContext);
  const Message = MessageType();
  const { isAdministrator } = UserFuatures();
  const { postCreateUserApi, userApiSuccess, userApiLoading } = useUser();
  const { getUsersApi, organizationApiLoading } = useOrganization();
  const { downloadUserManagement, isLoading } = useFile();
  const [addOpen, setAddOpen] = useState(false);

  const handleAddUserClick = () => {
    if (usersMagage.selectSearch) {
      setAddOpen(true);
    } else {
      appContext.setErrorOpen(true);
      appContext.setAlertMessage(Message.ERROR_NO_CREATE_OPEN_DIALOG_DATA);
    }
  };

  const handleAddClose = () => {
    setAddOpen(false);
  };

  const getOrganizationName = () => {
    const organization = userInfo.organizations.find((val) => {
      return val.id === usersMagage.selectSearch;
    });
    return organization ? organization.company_name : "";
  };

  const handleAddSave = (param: CreateUserRequest) => {
    postCreateUserApi(param);
  };

  /**
   * ユーザー新規作成処理が正常終了したらダイアログをクローズする
   */
  useEffect(() => {
    if (userApiSuccess) {
      handleAddClose();
      getUsersApi(usersMagage.selectSearch);
    }
  }, [userApiSuccess]);

  /**
   * CSVエクスポート処理
   */
  const handleDownloadCsvClick = () => {
    downloadUserManagement(usersMagage.selectSearch);
  };

  return (
    <>
      <BaseButton
        label={t("setting.users.addEditUser.addUserTitle")}
        handleClick={handleAddUserClick}
        className={classes.addUserButton}
        disabled={!isAdministrator()}
      />
      <IconButton
        onClick={handleDownloadCsvClick}
        className={classes.csvButton}
      >
        <CsvImage />
        <Typography
          variant="body1"
          component="div"
          sx={{ flexGrow: 1 }}
          className={classes.iconTitle}
        >
          {t("common.exxport")}
        </Typography>
      </IconButton>
      <AddUserDialogForm
        open={addOpen}
        handleClose={handleAddClose}
        handleSave={handleAddSave}
        customerId={usersMagage.selectSearch}
        customerName={getOrganizationName()}
        isAdministrator={true}
      />
      {(organizationApiLoading || userApiLoading || isLoading) && (
        <CircularProgressDialog />
      )}
    </>
  );
};

export default UsersButtonRightField;
