import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React from "react";
import classes from "./SelectItem.module.scss";
import SelectItemType from "../../../types/SelectItemType.tsx";

type SelectItemProps = {
  title: string;
  selectValue: string;
  values: SelectItemType[];
  className?: string;
  classNameTitle?: string;
  classNameSelect?: string;
  disabled?: boolean;
  handleClick: (event: SelectChangeEvent<string>) => void;
};

const SelectItem: React.FC<SelectItemProps> = (props) => {
  return (
    <Box className={`${classes.selectBox} ${props.className}`}>
      <Typography
        sx={{ textAlign: "left" }}
        className={`${classes.title} ${props.classNameTitle}`}
      >
        {props.title}
      </Typography>
      <Select
        value={props.selectValue}
        onChange={props.handleClick}
        displayEmpty
        className={`${classes.selectCategory} ${props.classNameSelect}`}
        size="small"
        inputProps={{ "aria-label": "Without label" }}
        disabled={props.disabled ? true : false}
      >
        {props.values.map((value) => {
          return (
            <MenuItem
              value={value.value}
              key={value.value}
              // sx={{ fontSize: "1vh" }}
            >
              {value.label}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};

export default SelectItem;
