import { useContext } from "react";
import UserContext from "../contexts/UserContext.tsx";
import { UserInfo } from "../types/response/UserInfoResponse.tsx";
import RoleEnum from "../types/enum/RoleEnum.tsx";

export const UserFuatures = () => {
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const { Role } = RoleEnum();

  /**
   * ユーザー名取得処理
   * @returns
   */
  const getUserName = () => {
    if (userInfo) {
      const firstName = userInfo.first_name || "";
      const lastName = userInfo.last_name || "";
      if (firstName && lastName) {
        return lastName + " " + firstName;
      } else if (firstName) {
        return firstName;
      }
      return lastName;
    }
    return "";
  };

  /**
   * システム管理者、カスタマーサクセス権限チェック
   * @returns true: 管理者権限あり false: 管理者権限なし
   */
  const isSystemAdministrator = (): boolean => {
    if (!userContext) return false;
    const role = userContext.role;
    if (
      role.includes(Role.administrator.code) ||
      role.includes(Role.cs_manager.code)
    ) {
      return true;
    }
    return false;
  };

  /**
   * ユーザー管理者権限チェック
   * @returns true: 管理者権限あり false: 管理者権限なし
   */
  const isAdministrator = (): boolean => {
    if (!userContext) return false;
    const role = userContext.role;
    if (
      role.includes(Role.administrator.code) ||
      role.includes(Role.cs_manager.code) ||
      role.includes(Role.user_administrator.code)
    ) {
      return true;
    }
    return false;
  };

  /**
   * ユーザー一般権限チェック
   * @returns true: 一般権限あり false: 一般権限なし
   */
  const isDataUpdater = (): boolean => {
    if (!userContext) return false;
    const role = userContext.role;
    if (
      role.includes(Role.administrator.code) ||
      role.includes(Role.cs_manager.code) ||
      role.includes(Role.user_administrator.code) ||
      role.includes(Role.data_updater.code)
    ) {
      return true;
    }
    return false;
  };

  return { getUserName, isSystemAdministrator, isAdministrator, isDataUpdater };
};
