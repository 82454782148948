import React from "react";
import { Box } from "@mui/material";
import DatePicker from "react-datepicker";
import classes from "./DateField.module.scss";
import { useTranslation } from "react-i18next";
import { ja } from "date-fns/locale";

type DateFieldProps = {
  fromDate: Date;
  setFromDate: React.Dispatch<React.SetStateAction<Date>>;
  toDate: Date;
  setToDate: React.Dispatch<React.SetStateAction<Date>>;
  className?: string;
  dateClassName?: string;
};

const DateField: React.FC<DateFieldProps> = (props) => {
  const { t, i18n } = useTranslation();

  const handleFromChange = (date: Date | null) => {
    if (date) {
      props.setFromDate(date);
    }
  };

  const handleToChange = (date: Date | null) => {
    if (date) {
      props.setToDate(date);
    }
  };

  return (
    <>
      <Box className={props.className}>
        {t("common.fromDate")}
        <DatePicker
          selected={props.fromDate}
          dateFormat="yyyy/MM/dd HH:mm"
          onChange={handleFromChange}
          showTimeSelect
          timeIntervals={15}
          timeCaption={i18n.language === "ja" ? "時間" : "Times"}
          timeFormat="HH:mm"
          dateFormatCalendar={
            i18n.language === "ja" ? "yyyy年 MM月" : "MMMM yyyy"
          }
          locale={i18n.language === "ja" ? ja : "en"}
          minDate={new Date(2010, 0)}
          maxDate={new Date(2030, 11)}
          className={props.dateClassName}
          popperPlacement="top-start"
        />
        {t("common.toDate")}
        <DatePicker
          selected={props.toDate}
          dateFormat="yyyy/MM/dd HH:mm"
          onChange={handleToChange}
          showTimeSelect
          timeIntervals={15}
          timeCaption={i18n.language === "ja" ? "時間" : "Times"}
          timeFormat="HH:mm"
          dateFormatCalendar={
            i18n.language === "ja" ? "yyyy年 MM月" : "MMMM yyyy"
          }
          locale={i18n.language === "ja" ? ja : "en"}
          minDate={props.fromDate}
          maxDate={new Date(2030, 11)}
          className={props.dateClassName}
          popperPlacement="top-start"
        />
      </Box>
    </>
  );
};

export default DateField;
