import React, { useContext } from "react";
import classes from "./UsersFilterForm.module.scss";
import { useTranslation } from "react-i18next";
import { Paper, Typography } from "@mui/material";
import InputSearchText from "../../parts/TextField/InputSearchText.tsx";
import SelectItem from "../../parts/Select/SelectItem.tsx";
import UsersManageContext from "../../../contexts/UsersManageContext.tsx";
import { UserManageFuatures } from "../../../features/UserManageFuatures.tsx";
import { UsersManageMode } from "../../../types/enum/UsersManageEnum.tsx";

const UsersFilterForm = () => {
  const { t } = useTranslation();
  const usersMagage = useContext(UsersManageContext);
  const { createSelectRoles, createSelectActives } = UserManageFuatures();

  const selectRoleValues = [
    {
      value: "all",
      label: t("common.selectAll"),
    },
  ].concat(createSelectRoles());

  const selectActiveValues = [
    {
      value: "all",
      label: t("common.selectAll"),
    },
  ].concat(createSelectActives());

  const inputSearchTextChange = (e: any) => {
    usersMagage.setSearchText(e.target.value);
  };
  const selectSelectRoleClick = (e: any) => {
    usersMagage.setSearchRole(e.target.value);
  };
  const selectSelectActiveClick = (e: any) => {
    usersMagage.setSearchActive(e.target.value);
  };

  return (
    <Paper sx={{ display: "flex" }} className={classes.userFilterForm}>
      <Typography variant="body1" component="div" className={classes.title}>
        {t("setting.users.search.title")}
      </Typography>
      <InputSearchText
        placeholder={
          usersMagage.mode === UsersManageMode.users
            ? t("setting.users.search.text")
            : t("setting.customers.search.text")
        }
        className={classes.inputSearchText}
        handleChange={inputSearchTextChange}
      />
      <SelectItem
        title={t("setting.users.search.role")}
        values={selectRoleValues}
        selectValue={usersMagage.searchRole}
        handleClick={selectSelectRoleClick}
        className={classes.selectItem}
        classNameSelect={classes.selectItemSelect}
        classNameTitle={classes.selectItemTitle}
      />
      <SelectItem
        title={t("setting.users.search.active")}
        values={selectActiveValues}
        selectValue={usersMagage.searchActive}
        handleClick={selectSelectActiveClick}
        className={classes.selectItem}
        classNameSelect={classes.selectItemSelect}
        classNameTitle={classes.selectItemTitle}
      />
    </Paper>
  );
};

export default UsersFilterForm;
