import { useContext, useState } from "react";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import MasterContext from "../contexts/MasterContext.tsx";
import MasterDataApi from "../features/api/MasterDataApi.tsx";
import { MasterDatas } from "../types/response/MasterDataResponse.tsx";
import SearchFeatures from "../features/SearchFeatures.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";

export const useMasterData = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);
  const masterContext = useContext(MasterContext);
  const { onCommonError } = ApiCommon();
  const { setSearchDatabyMasterData } = SearchFeatures();
  const [masterApiLoading, setMasterApiLoading] = useState(true);
  const { GetMasterData } = MasterDataApi();

  /**
   * マスターデータ取得API成功時
   */
  const onSuccess = (data: MasterDatas) => {
    setMasterApiLoading(false);
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    if (masterContext) masterContext.setMasterData(data);
    setSearchDatabyMasterData(data);
  };

  /**
   * マスターデータ取得API失敗時
   */
  const onError = (error) => {
    onCommonError(error);
    setMasterApiLoading(false);
  };

  /**
   * マスターデータ取得
   */
  const getMasterDataApi = () => {
    setMasterApiLoading(true);
    exeMasterData.refetch();
  };

  // マスターデータ取得API実行
  const exeMasterData = GetMasterData(onSuccess, onError);

  return { getMasterDataApi, masterApiLoading, setMasterApiLoading };
};
