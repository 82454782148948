import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { motion, AnimatePresence } from "framer-motion";
import classes from "./AssignCustomerDialogForm.module.scss";
import { useTranslation } from "react-i18next";
import ActiveEnum from "../../../types/enum/ActiveEnum.tsx";
import BaseButton from "../../parts/Button/BaseButton.tsx";
import InputSearchText from "../../parts/TextField/InputSearchText.tsx";
import SelectItem from "../../parts/Select/SelectItem.tsx";
import UsersManageContext from "../../../contexts/UsersManageContext.tsx";
import { UserManageFuatures } from "../../../features/UserManageFuatures.tsx";
import { AssignOrganization } from "../../../types/request/AssignOrganizationRequest.tsx";

type AssignCustomerDialogFormProps = {
  className?: string;
  open: boolean;
  handleClose: () => void;
  handleSave: (body: AssignOrganization[]) => void;
};

export type AssignCustomer = {
  organization_id: string;
  role_id: string;
  active_flag: boolean;
  // code: string;
  name: string;
  checked: boolean;
};

const AssignCustomerDialogForm: React.FC<AssignCustomerDialogFormProps> = (
  props
) => {
  const { t } = useTranslation();
  const usersMagage = useContext(UsersManageContext);
  const {
    createAssignCustomers,
    createNotAssignCustomers,
    createSelectRoles,
    createSelectActives,
  } = UserManageFuatures();
  const [searchText, setSearchText] = useState<string>("");
  const [customerList, setCustomerList] = useState<AssignCustomer[]>([]);
  const [assginCustomers, setAssginCustomers] = useState<AssignCustomer[]>([]);

  useEffect(() => {
    setSearchText("");
    setCustomerList(createNotAssignCustomers());
    setAssginCustomers(createAssignCustomers());
  }, [props.open]);

  const handleCheckAddCustomer = (code: string) => {
    if (customerList && customerList.length) {
      customerList.forEach((customer, index) => {
        if (customer.organization_id === code) {
          return (customer.checked = !customer.checked);
        }
      });
      setCustomerList([...customerList]);
    }
  };

  const handleAddClick = () => {
    const addCustomer = customerList.filter((customer) => customer.checked);
    if (addCustomer.length) {
      const customers = customerList.filter((customer) => !customer.checked);
      assginCustomers.push(...addCustomer);
      setCustomerList([...customers]);
      setAssginCustomers([...assginCustomers]);
    }
  };

  const handleRemove = (code: string, index: number) => {
    const deleteCustomer = assginCustomers[index];
    deleteCustomer.checked = false;
    customerList.push(deleteCustomer);
    setCustomerList([...customerList]);
    assginCustomers.splice(index, 1);
    setAssginCustomers([...assginCustomers]);
  };

  const setRoleId = (value: string, customer: AssignCustomer) => {
    assginCustomers.forEach((val) => {
      if (val.organization_id === customer.organization_id) {
        val.role_id = value;
      }
    });
    setAssginCustomers([...assginCustomers]);
  };

  const setActive = (value: string, customer: AssignCustomer) => {
    assginCustomers.forEach((val) => {
      if (val.organization_id === customer.organization_id) {
        val.active_flag = value === "true" ? true : false;
      }
    });
    setAssginCustomers([...assginCustomers]);
  };

  /**
   * 更新ボタン押下処理
   * @returns
   */
  const handleSaveUpdateClick = () => {
    const body: AssignOrganization[] = [];
    assginCustomers.forEach((val) => {
      body.push({
        organization_id: val.organization_id,
        role_id: val.role_id,
        active_flag: val.active_flag,
      });
    });
    props.handleSave(body);
  };

  const dispCustomerList = () => {
    return customerList.filter((val) => {
      return (
        val.name.includes(searchText) ||
        val.organization_id.includes(searchText)
      );
    });
  };

  return (
    <Dialog className={classes.dialog} open={props.open}>
      <DialogTitle className={classes.title}>
        <div>{`${t("setting.customers.assignCustomer.title")}`}</div>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={1} sx={{ pt: "1vh", height: "100%" }}>
          <Grid xs={3} sx={{ height: "100%" }}>
            <Paper sx={{ height: "100%" }}>
              <InputSearchText
                placeholder={t("setting.customers.assignCustomer.search")}
                className={classes.inputSearchText}
                handleChange={(e) => setSearchText(e.target.value)}
              />
              <List className={classes.list}>
                {dispCustomerList().map((customer) => (
                  <>
                    <ListItem
                      sx={{
                        backgroundColor: customer.checked ? "#bdbdbd" : "",
                      }}
                    >
                      <ListItemButton
                        sx={{ display: "block" }}
                        onClick={() =>
                          handleCheckAddCustomer(customer.organization_id)
                        }
                      >
                        <ListItemText
                          sx={{ m: 0 }}
                          className={classes.customerName}
                        >
                          {customer.name}
                        </ListItemText>
                        <ListItemText sx={{ m: 0 }} inset>
                          {customer.organization_id}
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </>
                ))}
              </List>
              <BaseButton
                label={t("setting.customers.assignCustomer.addButton")}
                handleClick={handleAddClick}
                className={classes.addButton}
              />
            </Paper>
          </Grid>
          <Grid
            xs={9}
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <AnimatePresence>
              {assginCustomers.map((customer, i) => (
                <motion.div
                  key={customer.organization_id}
                  layout
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className={classes.assignCustomer}
                >
                  <Paper>
                    <Typography
                      variant="h5"
                      component="div"
                      className={classes.assignItemTitle}
                    >
                      {customer.name}
                      <IconButton
                        onClick={() =>
                          handleRemove(customer.organization_id, i)
                        }
                        className={classes.removeButton}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </Typography>
                    <SelectItem
                      handleClick={(e) => setRoleId(e.target.value, customer)}
                      selectValue={customer.role_id}
                      title={t("setting.customers.assignCustomer.role")}
                      values={createSelectRoles()}
                      className={classes.select}
                      classNameSelect={classes.selectItem}
                      classNameTitle={classes.selectTitle}
                    />
                    <SelectItem
                      handleClick={(e) => setActive(e.target.value, customer)}
                      selectValue={customer.active_flag ? "true" : "false"}
                      title={t("setting.customers.assignCustomer.active")}
                      values={createSelectActives()}
                      className={classes.select}
                      classNameSelect={classes.selectItem}
                      classNameTitle={classes.selectTitle}
                    />
                  </Paper>
                </motion.div>
              ))}
            </AnimatePresence>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={props.handleClose}
          color="secondary"
        >
          {t("setting.users.addEditUser.cancel")}
        </Button>
        <Button
          className={classes.button}
          onClick={handleSaveUpdateClick}
          color="primary"
          // disabled={props.files.length === 0}
        >
          {t("setting.users.addEditUser.update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignCustomerDialogForm;
