import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import classes from "./EditUserDialogForm.module.scss";
import { useTranslation } from "react-i18next";
import ToggleSwitch from "../../parts/Switch/ToggleSwitch.tsx";
import SelectItem from "../../parts/Select/SelectItem.tsx";
import RoleEnum from "../../../types/enum/RoleEnum.tsx";
import UserContext from "../../../contexts/UserContext.tsx";
import { UserInfo } from "../../../types/response/UserInfoResponse.tsx";
import UpdateUserRequest from "../../../types/request/UpdateUserRequest.tsx";
import { UserManageFuatures } from "../../../features/UserManageFuatures.tsx";
import AppContext from "../../../contexts/AppContext.tsx";
import { Organizations } from "../../../types/response/OrganizationsResponse.tsx";
import ActiveEnum from "../../../types/enum/ActiveEnum.tsx";

type EditUserDialogFormProps = {
  className?: string;
  open: boolean;
  handleClose: () => void;
  handleSave: (param) => void;
  userId: string;
  firstName: string;
  lastName: string;
  editCustomer?: Organizations;
};

const EditUserDialogForm: React.FC<EditUserDialogFormProps> = (props) => {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const { Role } = RoleEnum();
  const { ActiveLabel } = ActiveEnum();
  const { checkUpdateUserParam, createSelectRoles } = UserManageFuatures();
  const [roleId, setRoleId] = useState<string>(Role.data_updater.code);
  const [activeFlag, setActiveFlag] = useState<boolean>(true);

  /**
   * ダイアログ表示時、入力項目を初期化する
   */
  useEffect(() => {
    setRoleId(
      props.editCustomer ? props.editCustomer.role_id : Role.data_updater.code
    );
    setActiveFlag(props.editCustomer ? props.editCustomer.active_flag : true);
  }, [props.open]);

  /**
   * 更新ボタン押下処理
   * @returns
   */
  const handleSaveUpdateClick = () => {
    const param = {
      first_name: props.firstName,
      last_name: props.lastName,
      email: props.userId,
      old_password: "",
      new_password: "",
      language: userInfo.language,
      organization_id: props.editCustomer
        ? props.editCustomer.organization_id
        : "",
      role_id: roleId,
      active_flag: activeFlag,
    } as UpdateUserRequest;
    const message = checkUpdateUserParam(param, "");
    if (message) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(message);
      return;
    }
    props.handleSave(param);
  };

  return (
    <Dialog className={classes.dialog} open={props.open}>
      <DialogTitle className={classes.title}>
        <div>{`${t("setting.users.addEditUser.editUserTitle")}`}</div>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <List className={classes.list}>
          {/* ユーザー情報 */}
          <ListItem className={classes.listTitle}>
            {t("setting.users.addEditUser.userInfo")}
          </ListItem>
          <List sx={{ pl: "1vw" }}>
            <ListItem>
              <ListItemText sx={{ width: "30%" }}>
                {t("setting.customers.selectTitle")}
              </ListItemText>
              <ListItemText
                sx={{ width: "70%" }}
              >{`: ${props.lastName} ${props.firstName}`}</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText sx={{ width: "30%" }}>
                {t("setting.users.addEditUser.userId")}
              </ListItemText>
              <ListItemText
                sx={{ width: "70%" }}
              >{`: ${props.userId}`}</ListItemText>
            </ListItem>
          </List>
          {/* 顧客情報 */}
          <ListItem className={classes.listTitle}>
            {t("setting.users.addEditUser.customerInfo")}
          </ListItem>
          <List sx={{ pl: "1vw" }}>
            <ListItem>
              <ListItemText sx={{ width: "20%" }}>
                {t("setting.users.addEditUser.customerName")}
              </ListItemText>
              <ListItemText sx={{ width: "80%" }}>{`: ${
                props.editCustomer ? props.editCustomer.office_name : ""
              }`}</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText sx={{ width: "20%" }}>
                {t("setting.users.addEditUser.customerId")}
              </ListItemText>
              <ListItemText sx={{ width: "80%" }}>{`: ${
                props.editCustomer ? props.editCustomer.organization_id : ""
              }`}</ListItemText>
            </ListItem>
          </List>
          {/* 権限 */}
          <ListItem className={`${classes.listTitle}`}>
            {t("setting.users.addEditUser.role")}
          </ListItem>
          <List sx={{ pl: "1vw" }}>
            <SelectItem
              title=""
              values={createSelectRoles()}
              selectValue={roleId}
              handleClick={(e) => setRoleId(e.target.value)}
            />
          </List>
          {/* アクティブ */}
          <ListItem className={classes.listTitle}>
            {t("setting.users.addEditUser.active")}
          </ListItem>
          <List sx={{ pl: "1vw" }}>
            <ToggleSwitch
              defaultChecked={activeFlag}
              handleChange={(e: any) => {
                setActiveFlag(e.target.checked);
              }}
              leftLabel={ActiveLabel.inactive.label}
              rightLabel={ActiveLabel.active.label}
              labelClassName={classes.toggleSwitch}
            />
          </List>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={props.handleClose}
          color="secondary"
        >
          {t("setting.users.addEditUser.cancel")}
        </Button>
        <Button
          className={classes.button}
          onClick={handleSaveUpdateClick}
          color="primary"
        >
          {t("setting.users.addEditUser.update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserDialogForm;
