import { useContext, useState } from "react";
import UserApi from "../features/api/UserApi.tsx";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import UserContext from "../contexts/UserContext.tsx";
import UserInfoRequest from "../types/request/UserInfoRequest.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";
import LanguageRequest from "../types/request/LanguageRequest.tsx";
import { UserInfo } from "../types/response/UserInfoResponse.tsx";
import CreateUserRequest from "../types/request/CreateUserRequest.tsx";
import AssignOrganizationRequest from "../types/request/AssignOrganizationRequest.tsx";
import { Organizations } from "../types/response/OrganizationsResponse.tsx";
import UsersManageContext from "../contexts/UsersManageContext.tsx";
import UpdateUserRequest from "../types/request/UpdateUserRequest.tsx";

export const useUser = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const usersMagage = useContext(UsersManageContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const { onCommonError } = ApiCommon();
  const {
    GetUserInfo,
    PutUserInfo,
    PutLanguage,
    CreateUser,
    DeleteUser,
    PutAssignOrganization,
    GetOrganizations,
  } = UserApi();
  const [userApiLoading, setUserApiLoading] = useState(false);
  const [userApiSuccess, setUserApiSuccess] = useState(false);
  let mfaFlag = false;

  /**
   * ユーザー情報取得API成功時
   */
  const onSuccess = (data) => {
    setUserApiLoading(false);
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    userContext?.setUserInfo(Object.assign({}, data));
  };

  /**
   * ユーザー情報取得API失敗時
   */
  const onError = (error) => {
    onCommonError(error);
    setUserApiLoading(false);
  };

  /**
   * ユーザー情報取得
   */
  const getUserApi = () => {
    setUserApiLoading(true);
    exeGetUser.refetch();
  };

  /**
   * ユーザー情報更新API成功時
   */
  const onPutUserSuccess = (data) => {
    userInfo.mfa_flag = mfaFlag;
    userContext?.setUserInfo(Object.assign({}, userInfo));
    appContext?.setSuccessOpen(true);
    appContext?.setAlertMessage(Message.SUCCESS_UPDATE_USER);
    setUserApiLoading(false);
    setUserApiSuccess(true);
  };

  /**
   * ユーザー情報更新
   */
  const putUserApi = (body: UpdateUserRequest, userId: string) => {
    if (!appContext?.idToken.sub) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_SYSTEM_ERROR);
      return;
    }
    const request: UserInfoRequest = {
      userId: userId,
      requestBody: body,
    };
    setUserApiLoading(true);
    setUserApiSuccess(false);
    exePutUser.mutate(request);
  };

  /**
   * 言語設定API成功時
   */
  const onLanguageSuccess = (data, variables: LanguageRequest) => {
    setUserApiLoading(false);
    getUserApi();
    appContext?.setAlertMessage(Message.SUCCESS_PUT_LANGUAGE);
    appContext?.setSuccessOpen(true);
  };

  /**
   * 言語設定API成功時(TOP画面専用)
   */
  const onLanguageTopSuccess = (data, variables: LanguageRequest) => {
    setUserApiLoading(false);
    getUserApi();
  };

  /**
   * 言語設定
   */
  const putLanguageApi = (language: string, isTop?: boolean) => {
    if (!appContext?.idToken.sub) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_SYSTEM_ERROR);
      return;
    }
    const param: LanguageRequest = {
      language: language,
    };
    setUserApiLoading(true);
    if (isTop) {
      exePutTopLanguage.mutate(param);
    } else {
      exePutLanguage.mutate(param);
    }
  };

  /**
   * ユーザー新規作成API成功時
   */
  const onCreateUserSuccess = (data, variables: CreateUserRequest) => {
    setUserApiLoading(false);
    setUserApiSuccess(true);
    appContext?.setAlertMessage(Message.SUCCESS_CREATE_USER);
    appContext?.setSuccessOpen(true);
  };

  /**
   * ユーザー新規作成
   */
  const postCreateUserApi = (request: CreateUserRequest) => {
    if (!appContext?.idToken.sub) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_SYSTEM_ERROR);
      return;
    }
    setUserApiLoading(true);
    setUserApiSuccess(false);
    exeCreateUser.mutate(request);
  };

  /**
   * ユーザー削除API成功時
   */
  const onDeleteUserSuccess = (data) => {
    setUserApiLoading(false);
    setUserApiSuccess(true);
    appContext?.setAlertMessage(Message.SUCCESS_DELETE_USER);
    appContext?.setSuccessOpen(true);
  };

  /**
   * ユーザー削除
   */
  const deleteUserApi = (userId: string) => {
    if (!appContext?.idToken.sub) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_SYSTEM_ERROR);
      return;
    }
    setUserApiLoading(true);
    setUserApiSuccess(false);
    exeDeleteUser.mutate(userId);
  };

  /**
   * 顧客割り当て更新API成功時
   */
  const onPutAssignOrganizationSuccess = (data) => {
    setUserApiLoading(false);
    setUserApiSuccess(true);
    appContext?.setAlertMessage(Message.SUCCESS_PUT_ASSIGN_ORGANIZATION);
    appContext?.setSuccessOpen(true);
  };

  /**
   * 顧客割り当て更新
   */
  const putAssignOrganizationApi = (request: AssignOrganizationRequest) => {
    if (!appContext?.idToken.sub) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_SYSTEM_ERROR);
      return;
    }
    setUserApiLoading(true);
    setUserApiSuccess(false);
    exePutAssignOrganization.mutate(request);
  };

  /**
   * 顧客情報取得API成功時
   */
  const onGetOrganizationsSuccess = (
    data: Organizations[],
    variables: LanguageRequest
  ) => {
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    setUserApiLoading(false);
    usersMagage.setOrganizations([...data]);
  };

  /**
   * 顧客情報取得
   */
  const getOrganizationsApi = (userId: string) => {
    if (!appContext?.idToken.sub) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_SYSTEM_ERROR);
      return;
    }
    setUserApiLoading(true);
    exeGetOrganizations.mutate(userId);
  };

  // ユーザー情報取得API実行
  const exeGetUser = GetUserInfo(
    appContext?.accessToken.sub || "",
    onSuccess,
    onError
  );

  // ユーザー情報更新API実行
  const exePutUser = PutUserInfo(onPutUserSuccess, onError);

  // 言語設定API実行
  const exePutLanguage = PutLanguage(onLanguageSuccess, onError);

  // 言語設定API実行(TOP画面専用)
  const exePutTopLanguage = PutLanguage(onLanguageTopSuccess, onError);

  // ユーザー新規作成API実行
  const exeCreateUser = CreateUser(onCreateUserSuccess, onError);

  // ユーザー削除API実行
  const exeDeleteUser = DeleteUser(onDeleteUserSuccess, onError);

  // 顧客割り当て更新API実行
  const exePutAssignOrganization = PutAssignOrganization(
    onPutAssignOrganizationSuccess,
    onError
  );

  // 顧客情報取得API実行
  const exeGetOrganizations = GetOrganizations(
    onGetOrganizationsSuccess,
    onError
  );

  return {
    userApiLoading,
    userApiSuccess,
    getUserApi,
    putUserApi,
    putLanguageApi,
    postCreateUserApi,
    deleteUserApi,
    putAssignOrganizationApi,
    getOrganizationsApi,
  };
};
