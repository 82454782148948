import React from "react";
import classes from "./LanguageLayout.module.scss";
import { Box } from "@mui/material";
import LanguageForm from "../../forms/SettingLanguage/LanguageForm.tsx";

type LanguageLayoutProps = {};

const LanguageLayout: React.FC<LanguageLayoutProps> = (props) => {
  return (
    <Box className={classes.language}>
      <LanguageForm />
    </Box>
  );
};

export default LanguageLayout;
