import { useContext, useState } from "react";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import DatamartApi from "../features/api/DatamartApi.tsx";
import DatamartOrganizationRequest from "../types/request/DatamartOrganizationRequest.tsx";
import { OrganizationDashboardType } from "../types/enum/DashboardTypeEnum.tsx";
import DatamartSearchContext from "../contexts/DatamartSearchContext.tsx";
import {
  DatamartOrganization,
  defaultDatamartOrganization,
} from "../types/response/DatamartOrganizationResponse.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";

export const useDatamartOrganization = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);
  const searchContext = useContext(DatamartSearchContext);
  const { onCommonError } = ApiCommon();
  const [isLoading, setLoading] = useState(false);
  const [datamartData, setDatamartData] = useState<DatamartOrganization>(
    defaultDatamartOrganization
  );
  const { DatamartOrganization } = DatamartApi();

  /**
   * 企業軸データマート取得API成功時
   */
  const onSuccess = (data: DatamartOrganization) => {
    setLoading(false);
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    } else if (!data.statistics.length) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_SEARCH_DATA);
      return;
    }
    setDatamartData(data);
  };

  /**
   * 企業軸データマート取得API失敗時
   */
  const onError = (error) => {
    onCommonError(error);
    setLoading(false);
  };

  /**
   * リクエストパラメータ生成処理
   * @returns
   */
  const createRequestParam = () => {
    let param = {} as DatamartOrganizationRequest;
    param.dashboard_types = OrganizationDashboardType;
    if (searchContext?.searchData.filter_organization_params) {
      param.filter_params = searchContext.searchData.filter_organization_params;
    }
    return param;
  };

  /**
   * 企業軸データマート取得
   */
  const getDatamartOrganizationApi = () => {
    setLoading(true);
    exeDatamartOrganization.refetch();
  };

  // 企業軸データマート取得API実行
  const exeDatamartOrganization = DatamartOrganization(
    createRequestParam(),
    onSuccess,
    onError
  );

  return { getDatamartOrganizationApi, datamartData, isLoading };
};
