import { useQuery } from "react-query";
import axios from "axios";
import PasswordChangeRequest from "../../types/request/PasswordChangeRequest.tsx";
import { ApiCommon } from "../ApiCommon.tsx";

const PasswordChangeApi = () => {
  const { CustomHeaders } = ApiCommon();
  /**
   * パスワード変更API
   * @param request
   */
  const PasswordChange = (
    request: PasswordChangeRequest,
    onSuccess,
    onError
  ) => {
    return useQuery(
      "passwordChange",
      async () => {
        const response = await axios.put(
          process.env.REACT_APP_HOST_URL + "/v1/auth/password_change",
          request,
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  return { PasswordChange };
};

export default PasswordChangeApi;
