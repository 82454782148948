import React, { useContext } from "react";
import { Chip } from "@mui/material";
import RoleEnum from "../types/enum/RoleEnum.tsx";
import ActiveEnum from "../types/enum/ActiveEnum.tsx";
import CreateUserRequest from "../types/request/CreateUserRequest.tsx";
import UpdateUserRequest from "../types/request/UpdateUserRequest.tsx";
import MessageType from "../types/Message.tsx";
import UserContext from "../contexts/UserContext.tsx";
import { UserInfo } from "../types/response/UserInfoResponse.tsx";
import { OrganizationUsers } from "../types/response/OrganizationUsersResponse.tsx";
import SelectItemType from "../types/SelectItemType.tsx";
import { useTranslation } from "react-i18next";
import UsersManageContext from "../contexts/UsersManageContext.tsx";
import { AssignCustomer } from "../components/forms/SettingUsers/AssignCustomerDialogForm.tsx";
import { UserFuatures } from "./UserFuatures.tsx";

export const UserManageFuatures = () => {
  const { t } = useTranslation();
  const { Role } = RoleEnum();
  const { ActiveLabel } = ActiveEnum();
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const usersMagage = useContext(UsersManageContext);
  const Message = MessageType();
  const { isSystemAdministrator } = UserFuatures();

  /**
   * 権限表示処理
   * @param role
   * @param className
   * @returns
   */
  const dispChipRole = (role: string, className: string) => {
    switch (role) {
      case Role.administrator.code:
        return (
          <Chip
            color="primary"
            label={Role.administrator.label}
            className={className}
          />
        );
      case Role.data_updater.code:
        return (
          <Chip
            color="success"
            label={Role.data_updater.label}
            className={className}
          />
        );
      case Role.read_only.code:
        return (
          <Chip
            color="secondary"
            label={Role.read_only.label}
            className={className}
          />
        );
      default:
        return (
          <Chip
            color="default"
            label={Role[role] ? Role[role].label : ""}
            className={className}
          />
        );
    }
  };

  /**
   * アクティブ表示処理
   * @param active
   * @param className
   * @returns
   */
  const dispChipActive = (active: boolean, className: string) => {
    if (active) {
      return (
        <Chip
          color="success"
          label={ActiveLabel.active.label}
          className={className}
        />
      );
    }
    return (
      <Chip
        color="default"
        label={ActiveLabel.inactive.label}
        className={className}
      />
    );
  };

  /**
   * 2段階認証表示処理
   * @param otp
   * @param className
   * @returns
   */
  const dispChipOtp = (otp: boolean, className: string) => {
    if (otp) {
      return <Chip color="success" label={"ON"} className={className} />;
    }
    return <Chip color="default" label={"OFF"} className={className} />;
  };

  /**
   * 新規ユーザー作成
   * 未入力項目チェック処理
   * @param param
   * @param rePassword
   * @returns
   */
  const checkCreateUserParam = (
    param: CreateUserRequest,
    rePassword: string
  ) => {
    let message = "";
    Object.keys(param).forEach((key) => {
      if (!["active_flag"].includes(key)) {
        if (!param[key]) message = Message.ERROR_NO_INPUT_DATA;
      }
    });
    if (!message && param.password !== rePassword) {
      message = Message.ERROR_NO_MATCH_PASSWORD;
    }
    return message;
  };

  /**
   * ユーザー更新
   * 入力項目チェック処理
   * @param param
   * @param rePassword
   * @returns
   */
  const checkUpdateUserParam = (
    param: UpdateUserRequest,
    rePassword: string
  ) => {
    let message = "";
    Object.keys(param).forEach((key) => {
      if (!["old_password", "new_password", "active_flag"].includes(key)) {
        if (!param[key]) message = Message.ERROR_NO_INPUT_DATA;
      }
    });
    if (!message && (param.new_password || param.old_password || rePassword)) {
      if (!param.new_password || !param.old_password) {
        message = Message.ERROR_NO_INPUT_DATA;
      } else if (param.new_password !== rePassword) {
        message = Message.ERROR_NO_MATCH_PASSWORD;
      }
    }
    return message;
  };

  /**
   * ユーザー編集時、パスワード表示非表示制御
   * 編集対象のユーザーがログインユーザーと一致している場合：表示
   * それ以外のユーザーの場合：非表示
   * @param userId
   * @returns
   */
  const isDispEditUserPassword = (userId: string | undefined) => {
    if (!userId) return true;
    return userInfo.id === userId;
  };

  /**
   * 権限ドロップダウンの選択一覧を作成
   * @returns
   */
  const createSelectRoles = () => {
    const selectValues: SelectItemType[] = [];
    const isSystemAdmin = isSystemAdministrator();
    Object.values(Role).forEach((val) => {
      if (isSystemAdmin) {
        selectValues.push({
          value: val.code,
          label: val.label,
        });
      } else if (
        val.code !== Role.cs_manager.code &&
        val.code !== Role.administrator.code
      )
        selectValues.push({
          value: val.code,
          label: val.label,
        });
    });
    return selectValues;
  };

  /**
   * アクティブドロップダウンの選択一覧を作成
   * @returns
   */
  const createSelectActives = () => {
    const selectValues: SelectItemType[] = [];
    Object.values(ActiveLabel).forEach((val) => {
      selectValues.push({
        value: val.code.toString(),
        label: val.label,
      });
    });
    return selectValues;
  };

  /**
   * 絞り込み内容リセット処理
   */
  const resetSearchData = () => {
    usersMagage.setSearchText("");
    usersMagage.setSearchRole("all");
    usersMagage.setSearchRole("all");
  };

  /**
   * 割り当て顧客一覧作成
   */
  const createAssignCustomers = () => {
    const result: AssignCustomer[] = [];
    usersMagage.organizations.forEach((val) => {
      result.push({
        organization_id: val.organization_id,
        role_id: val.role_id,
        active_flag: val.active_flag,
        name: val.office_name,
        checked: false,
      });
    });
    return result;
  };

  /**
   * 未割り当て顧客一覧作成
   */
  const createNotAssignCustomers = () => {
    const result: AssignCustomer[] = [];
    const organization = userInfo.organizations.filter((val) => {
      return !usersMagage.organizations.find((value) => {
        return value.organization_id === val.id;
      });
    });
    organization.forEach((val) => {
      result.push({
        organization_id: val.id,
        role_id: Role.data_updater.code,
        active_flag: true,
        name: val.office_name,
        checked: false,
      });
    });
    return result;
  };

  return {
    dispChipRole,
    dispChipActive,
    dispChipOtp,
    checkCreateUserParam,
    checkUpdateUserParam,
    isDispEditUserPassword,
    createSelectRoles,
    createSelectActives,
    resetSearchData,
    createAssignCustomers,
    createNotAssignCustomers,
  };
};
