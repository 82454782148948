import { useContext } from "react";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";

export const ApiCommon = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);

  /**
   * API共通エラー処理
   */
  const onCommonError = (error, message?) => {
    const response = error.response;
    const errorMessage = message ? message : Message.ERROR_SYSTEM_ERROR;
    if (response && response.data && response.data.detail) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(response.data.detail);
      return;
    }
    appContext?.setErrorOpen(true);
    appContext?.setAlertMessage(errorMessage);
    return;
  };

  /**
   * API共通エラー処理
   * レスポンス形式がBlobの場合
   */
  const onCommonBlobError = async (error, message?) => {
    const response = error.response;
    const errorMessage = message ? message : Message.ERROR_SYSTEM_ERROR;
    if (response && response.data) {
      const errorText = await response.data.text();
      const errorData = JSON.parse(errorText);
      if (errorData.detail) {
        appContext?.setErrorOpen(true);
        appContext?.setAlertMessage(errorData.detail);
        return;
      }
    }
    appContext?.setErrorOpen(true);
    appContext?.setAlertMessage(errorMessage);
    return;
  };

  /**
   * カスタムヘッダー
   * グローバルIPのみ
   * @returns
   */
  const CustomHeader = () => {
    return {
      "client-global-ip": appContext?.globalIP,
    };
  };

  /**
   * カスタムヘッダー
   * グローバルIP、アクセストークン
   * @returns
   */
  const CustomHeaders = () => {
    return {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      "client-global-ip": appContext?.globalIP,
    };
  };

  /**
   * カスタムヘッダー
   * グローバルIP、アクセストークン、フォームデータ
   * @returns
   */
  const CustomHeadersFormData = () => {
    return {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      "Content-Type": "multipart/form-data",
      "client-global-ip": appContext?.globalIP,
    };
  };

  /**
   * カスタムヘッダー
   * グローバルIP、アクセストークン、ファイル出力
   * @returns
   */
  const CustomHeadersOctetStream = () => {
    return {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Accept: "application/octet-stream",
      "client-global-ip": appContext?.globalIP,
    };
  };

  /**
   * カスタムヘッダー
   * グローバルIP、アクセストークン、ファイル出力
   * @returns
   */
  const CustomHeadersCsv = () => {
    return {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      ContentType: "text/csv",
      "client-global-ip": appContext?.globalIP,
    };
  };

  return {
    onCommonError,
    onCommonBlobError,
    CustomHeader,
    CustomHeaders,
    CustomHeadersFormData,
    CustomHeadersOctetStream,
    CustomHeadersCsv,
  };
};
