import React, { useContext, useState } from "react";
import { Container, Grid, List, ListItem, Typography } from "@mui/material";
import DownloadButton from "../../parts/Button/DownloadButton.tsx";
import UploadButton from "../../parts/Button/UploadButton.tsx";
import DeleteButton from "../../parts/Button/DeleteButton.tsx";
import classes from "./DataInputField.module.scss";
import FileType from "../../../types/FileType.tsx";
import FileUploadDialog from "../../parts/Dialog/FileUploadDialog.tsx";
import { useFile } from "../../../hooks/useFile.tsx";
import FileDownloadDialog from "../../parts/Dialog/FileDownloadDialog.tsx";
import FileDeleteDialog from "../../parts/Dialog/FileDeleteDialog.tsx";
import DataInputFeatures from "../../../features/DataInputFeatures.tsx";
import AppContext from "../../../contexts/AppContext.tsx";
import MessageType from "../../../types/Message.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";
import UserContext from "../../../contexts/UserContext.tsx";
import RoleEnum from "../../../types/enum/RoleEnum.tsx";
import { useTranslation } from "react-i18next";
import { UserFuatures } from "../../../features/UserFuatures.tsx";

type DataInputFormProps = {
  datas: FileType[];
  isMultiContract?: boolean;
};

const DataInputField: React.FC<DataInputFormProps> = (props) => {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const role = userContext ? userContext.role : [];
  const Message = MessageType();
  const [uploadOpen, setUploadOpen] = useState<boolean>(false);
  const [downloadOpen, setDownloadOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [isCutomerList, setCutomerList] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [fileType, setFileType] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const { uploadFiles, downloadFiles, deleteFiles, isLoading } = useFile();
  const { isCutomerData, getOrganizationId, isDispDate } = DataInputFeatures();
  const { Role } = RoleEnum();
  const { isDataUpdater } = UserFuatures();

  // アップロード画面オープン処理
  const handleUploadOpen = (type: string, name: string) => {
    setUploadOpen(!uploadOpen);
    setFileType(type);
    setFileName(props.isMultiContract ? name + t("dataInput.multi") : name);
  };

  // ダウンロード画面オープン処理
  const handleDownloadOpen = (type: string, name: string) => {
    const cutomerFlag = isCutomerData(props.isMultiContract || false);
    setCutomerList(cutomerFlag);

    if (cutomerFlag || isDispDate(type)) {
      setDownloadOpen(!downloadOpen);
      setFileType(type);
      setFileName(props.isMultiContract ? name + t("dataInput.multi") : name);
      return;
    }
    const oragnizationId = getOrganizationId();
    if (!oragnizationId) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_ORGANIZATION_DATA);
      return;
    }
    downloadFiles(type, oragnizationId, "");
    handleDownloadClose();
  };

  // 削除画面オープン処理
  const handleDeleteOpen = (type: string, name: string) => {
    const cutomerFlag = isCutomerData(props.isMultiContract || false);
    setCutomerList(cutomerFlag);
    setDeleteOpen(!deleteOpen);
    setFileType(type);
    setFileName(props.isMultiContract ? name + t("dataInput.multi") : name);
  };

  // アップロード画面クローズ処理
  const handleClose = () => {
    setFiles([]);
    setFileType("");
    setUploadOpen(false);
  };

  // ダウンロード画面クローズ処理
  const handleDownloadClose = () => {
    setFileType("");
    setDownloadOpen(false);
  };

  // 削除画面クローズ処理
  const handleDeleteClose = () => {
    setFileType("");
    setDeleteOpen(false);
  };

  // ドロップ処理
  const onDrop = (acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
  };

  // アップロード処理
  const handleUpload = () => {
    uploadFiles(fileType, files);
    setUploadOpen(!uploadOpen);
    setFiles([]);
    setFileType("");
  };

  // ダウンロード処理
  const handleDownload = (oragnizationId: string, date?: string) => {
    downloadFiles(fileType, oragnizationId, date);
    handleDownloadClose();
  };

  // 削除処理
  const handleDelete = (
    fromDate: string,
    toDate: string,
    oragnizationId: string
  ) => {
    deleteFiles(fileType, fromDate, toDate, oragnizationId);
    setFileType("");
    setDeleteOpen(false);
  };

  const isDisabled = (disp: boolean) => {
    if (disp && isDataUpdater()) {
      return false;
    }
    return true;
  };

  return (
    <Container className={classes.container}>
      <List className={classes.dataInputList}>
        {props.datas.map((data, index) => (
          <ListItem
            key={index}
            className={classes.dataList}
            sx={{ pt: 0, pb: 0 }}
          >
            <Grid container spacing={1} className={classes.gridContainer}>
              <Grid item xs={7} className={classes.gridItem}>
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.title}
                >
                  {index + 1 + ". " + data.title}
                </Typography>
              </Grid>
              <Grid item xs={5} className={classes.gridItem}>
                <DownloadButton
                  label="download"
                  iconClassName={classes.icon}
                  className={classes.iconButton}
                  value={data.type}
                  handleClick={() => handleDownloadOpen(data.type, data.title)}
                />
                <UploadButton
                  label="upload"
                  iconClassName={classes.icon}
                  className={classes.iconButton}
                  value={data.type}
                  handleClick={() => handleUploadOpen(data.type, data.title)}
                  disabled={isDisabled(data.disp)}
                />
                {data.disp && (
                  <DeleteButton
                    label="delete"
                    iconClassName={classes.icon}
                    className={classes.iconButton}
                    value={data.type}
                    handleClick={() => handleDeleteOpen(data.type, data.title)}
                    disabled={isDisabled(data.disp)}
                  />
                )}
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>

      {isLoading && <CircularProgressDialog />}
      <FileUploadDialog
        open={uploadOpen}
        files={files}
        handleClose={handleClose}
        handleUpload={() => handleUpload()}
        onDrop={onDrop}
        title={fileName}
      />
      <FileDownloadDialog
        open={downloadOpen}
        handleClose={handleDownloadClose}
        handleDownload={handleDownload}
        isMultiContract={props.isMultiContract}
        isCutomerList={isCutomerList}
        type={fileType}
        title={fileName}
      />
      <FileDeleteDialog
        open={deleteOpen}
        isMultiContract={props.isMultiContract}
        isCutomerList={isCutomerList}
        handleClose={handleDeleteClose}
        handleDelete={handleDelete}
        title={fileName}
      />
    </Container>
  );
};

export default DataInputField;
