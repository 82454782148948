import React from "react";
import { InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

type InputSearchTextProps = {
  placeholder: string;
  className?: string;
  value?: string;
  type?: string;
  inputProps?: {};
  handleInput?: (event) => void;
  handleChange?: (event) => void;
};

const InputSearchText: React.FC<InputSearchTextProps> = (props) => {
  return (
    <Paper
      component="form"
      sx={{ display: "flex" }}
      className={props.className}
      elevation={0}
    >
      <SearchIcon sx={{ alignSelf: "center", color: "text.secondary" }} />
      <InputBase
        sx={{ ml: "0.1vw", flex: 1 }}
        placeholder={props.placeholder}
        inputProps={{ "aria-label": "search input text" }}
        value={props.value}
        type="search"
        onInput={props.handleInput ? props.handleInput : undefined}
        onChange={props.handleChange ? props.handleChange : undefined}
      />
    </Paper>
  );
};

export default InputSearchText;
