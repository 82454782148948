import { useTranslation } from "react-i18next";

const RoleEnum = () => {
  const { t } = useTranslation();

  const Role = {
    administrator: {
      code: "administrator",
      label: t("type.roleEnum.administrator"),
    },
    cs_manager: {
      code: "cs_manager",
      label: t("type.roleEnum.csManager"),
    },
    user_administrator: {
      code: "user_administrator",
      label: t("type.roleEnum.userAdministrator"),
    },
    data_updater: {
      code: "data_updater",
      label: t("type.roleEnum.dataUpdater"),
    },
    read_only: {
      code: "read_only",
      label: t("type.roleEnum.readOnly"),
    },
  };

  return {
    Role,
  };
};

export default RoleEnum;
