import React, { createContext, useState } from "react";
import { OperatorLogs } from "../types/response/OperatorLogsResponse";

type LogProps = {
  operatorLogs: OperatorLogs;
  setOperatorLogs: React.Dispatch<React.SetStateAction<OperatorLogs>>;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  searchOpt: string;
  setSearchOpt: React.Dispatch<React.SetStateAction<string>>;
  searchFromDate: Date;
  setSearchFromDate: React.Dispatch<React.SetStateAction<Date>>;
  searchToDate: Date;
  setSearchToDate: React.Dispatch<React.SetStateAction<Date>>;
};

const defaultValue = {
  operatorLogs: {} as OperatorLogs,
  setOperatorLogs: () => {},
  searchText: "",
  setSearchText: () => {},
  searchOpt: "all",
  setSearchOpt: () => {},
  searchFromDate: new Date(),
  setSearchFromDate: () => {},
  searchToDate: new Date(),
  setSearchToDate: () => {},
};

// ユーザー管理コンテキスト
const LogContext = createContext<LogProps>(defaultValue);

type Props = {
  children: React.ReactNode;
};

export const LogProvider: React.VFC<Props> = ({ children }) => {
  const [operatorLogs, setOperatorLogs] = useState<OperatorLogs>(
    {} as OperatorLogs
  );
  const [searchText, setSearchText] = useState<string>("");
  const [searchOpt, setSearchOpt] = useState<string>("all");
  const [searchFromDate, setSearchFromDate] = useState<Date>(new Date());
  const [searchToDate, setSearchToDate] = useState<Date>(new Date());

  return (
    <LogContext.Provider
      value={{
        operatorLogs,
        setOperatorLogs,
        searchText,
        setSearchText,
        searchOpt,
        setSearchOpt,
        searchFromDate,
        setSearchFromDate,
        searchToDate,
        setSearchToDate,
      }}
    >
      {children}
    </LogContext.Provider>
  );
};

export default LogContext;
