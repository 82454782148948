import React, { useContext } from "react";
import classes from "./UsersButtonLeftField.module.scss";
import { useTranslation } from "react-i18next";
import BaseButton from "../../parts/Button/BaseButton.tsx";
import { IconButton } from "@mui/material";
import { Autorenew } from "@mui/icons-material";
import UsersManageContext from "../../../contexts/UsersManageContext.tsx";
import { UsersManageMode } from "../../../types/enum/UsersManageEnum.tsx";
import { useUser } from "../../../hooks/useUser.tsx";
import { useOrganization } from "../../../hooks/useOrganization.tsx";
import { UserManageFuatures } from "../../../features/UserManageFuatures.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";

type UsersButtonLeftFieldProps = {};

const UsersButtonLeftField: React.FC<UsersButtonLeftFieldProps> = (props) => {
  const { t } = useTranslation();
  const { getOrganizationsApi, userApiLoading } = useUser();
  const { getUsersApi, organizationApiLoading } = useOrganization();
  const { resetSearchData } = UserManageFuatures();
  const usersMagage = useContext(UsersManageContext);
  const mode = usersMagage.mode || UsersManageMode.users;
  /**
   * 顧客・ユーザー一覧切り替えボタンクリックイベント
   */
  const handleChangeClick = () => {
    if (usersMagage) {
      if (usersMagage.mode === "users") {
        usersMagage.setMode("customers");
      } else {
        usersMagage.setMode("users");
      }
      resetSearchData();
    }
  };

  /**
   * リロードボタンクリックイベント
   */
  const handleReloadClick = () => {
    if (usersMagage.mode === UsersManageMode.users) {
      getUsersApi(usersMagage.selectSearch);
    } else if (usersMagage.mode === UsersManageMode.customers) {
      getOrganizationsApi(usersMagage.selectSearch);
    }
    resetSearchData();
  };

  return (
    <>
      <BaseButton
        label={
          mode === UsersManageMode.users
            ? t("setting.users.changeButton")
            : t("setting.customers.changeButton")
        }
        handleClick={handleChangeClick}
        className={classes.changeListButton}
      />
      <IconButton onClick={handleReloadClick} className={classes.reloadButton}>
        <Autorenew />
      </IconButton>
      {(organizationApiLoading || userApiLoading) && <CircularProgressDialog />}
    </>
  );
};

export default UsersButtonLeftField;
