import React from "react";
import ContentLayout from "../../layouts/Common/ContentLayout.tsx";
import ExternalLinksLayout from "../../layouts/ExternalLinks/ExternalLinksLayout.tsx";

const ExternalLinksPage = () => {
  return (
    <ContentLayout>
      <ExternalLinksLayout />
    </ContentLayout>
  );
};

export default ExternalLinksPage;
