import React, { useState, useContext } from "react";
import classes from "./AllowedIPAddressesField.module.scss";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import InputMultilineTextOnLeftLabel from "../../parts/TextField/InputMultilineTextOnLeftLabel.tsx";

const AllowedIPAddressesField = () => {
  const { t } = useTranslation();
  const [content, setContent] = useState<string>("");

  const handleContentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setContent(event.currentTarget.value);
  };

  return (
    <>
      <Grid container spacing={1} className={classes.gridContainer}>
        <Grid item xs={6} className={classes.gridItem}>
          <InputMultilineTextOnLeftLabel
            label=""
            className={classes.multiline}
            labelClassName={classes.multilineLabel}
            placeholder=""
            inputProps={{
              maxLength: 500,
            }}
            row={10}
            value={content}
            handleChange={handleContentChange}
          />
        </Grid>
        <Grid item xs={6} className={classes.gridItem}>
          <div
            dangerouslySetInnerHTML={{
              __html: t("setting.accessControl.allowedMessage"),
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AllowedIPAddressesField;
