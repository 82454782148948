import { useContext, useState } from "react";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";
import ExternalApi from "../features/api/ExternalApi.tsx";

export const useExternal = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);
  const { onCommonError } = ApiCommon();
  const [externalApiLoading, setExternalApiLoading] = useState(false);

  /**
   * グローバルIP取得API成功時
   */
  const onSuccess = (data) => {
    setExternalApiLoading(false);
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    appContext?.setGlobalIP(data.global_ip);
  };

  /**
   * グローバルIP取得API失敗時
   */
  const onError = (error) => {
    onCommonError(error);
    setExternalApiLoading(false);
  };

  /**
   * グローバルIP取得
   */
  const getGlobalIPApi = () => {
    setExternalApiLoading(true);
    exeGlobalIP.mutate();
  };

  // グローバルIP取得API実行
  const exeGlobalIP = ExternalApi.GetGlobalIPAddress(onSuccess, onError);

  return { getGlobalIPApi, externalApiLoading, setExternalApiLoading };
};
