import { useQuery } from "react-query";
import axios from "axios";
import NoticeResponse from "../../types/response/NoticeResponse";
import { ApiCommon } from "../ApiCommon.tsx";

const NoticeApi = () => {
  const { CustomHeaders } = ApiCommon();
  /**
   * お知らせ一覧取得API
   * @param request
   */
  const GetNotices = (onSuccess, onError) => {
    return useQuery(
      "getNotices",
      async () => {
        const response = await axios.get<NoticeResponse>(
          process.env.REACT_APP_HOST_URL + "/v1/notices",
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  return { GetNotices };
};

export default NoticeApi;
