import React, { useContext } from "react";
import { Box, Grid } from "@mui/material";
import classes from "./SearchOrganizationField.module.scss";
import SearchDateField from "./SearchDateField.tsx";
import SearchTextCheckBoxField from "./SearchTextCheckBoxField.tsx";
import SearchButtonField from "./SearchButtonField.tsx";
import { useTranslation } from "react-i18next";
import DatamartSearchContext from "../../../contexts/DatamartSearchContext.tsx";
import SearchFeatures from "../../../features/SearchFeatures.tsx";
import DatamartCompanyContext from "../../../contexts/DatamartCompanyContext.tsx";

type SearchOrganizationFieldProps = {};

const SearchOrganizationField: React.FC<SearchOrganizationFieldProps> = (
  props
) => {
  const { t } = useTranslation();
  const { checkBoxData, searchData } = useContext(DatamartSearchContext);
  const { setSelectCells } = useContext(DatamartCompanyContext);
  const { getCheckBoxInEnergiesData } = SearchFeatures();

  const resetData = () => {
    setSelectCells({});
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <Box className={classes.searchItem}>
          <SearchDateField
            title={t("header.search.company.date")}
            fromDate={searchData.filter_organization_params.from_month}
            toDate={searchData.filter_organization_params.to_month}
          />
        </Box>
      </Grid>
      <Grid item xs={9}>
        <Box className={classes.searchItem}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <SearchTextCheckBoxField
                checkBoxData={checkBoxData.organizations}
                type="organizations"
                title={t("header.search.company.checkBoxOrganization")}
              />
            </Grid>
            <Grid item xs={4}>
              <SearchTextCheckBoxField
                checkBoxData={checkBoxData.offices}
                type="offices"
                title={t("header.search.company.checkBoxOffice")}
              />
            </Grid>
            <Grid item xs={4}>
              <SearchTextCheckBoxField
                checkBoxData={checkBoxData.scopes}
                type="scopes"
                title={t("header.search.company.checkBoxScope")}
              />
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.searchItem}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <SearchTextCheckBoxField
                checkBoxData={getCheckBoxInEnergiesData(checkBoxData)}
                type="energies"
                title={t("header.search.company.checkBoxEnergies")}
              />
            </Grid>
            <Grid item xs={3} className={classes.searchButtonItem}>
              <SearchButtonField resetData={resetData} />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SearchOrganizationField;
