import React, { useContext, useEffect, useState } from "react";
import { Drawer } from "@mui/material";
import classes from "./SideMenuForm.module.scss";
import { useLocation } from "react-router-dom";
import SideMenuField from "../../fields/SideMenu/SideMenuField.tsx";
import AppContext from "../../../contexts/AppContext.tsx";
import { SettingSideMenuEnum } from "../../../types/enum/SideMenuEnum.tsx";

const SideMenuForm = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const [settingFlag, setSettingFlag] = useState<boolean>(false);

  useEffect(() => {
    // パス先値に応じて、サイドメニュー設定
    const pathName = location.pathname.split("/");
    if (pathName.length > 1) {
      appContext?.setSideItem(pathName[1]);
    }
    isSetting(pathName[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 各種設定画面判定処理
   */
  const isSetting = (sideItem) => {
    if (sideItem && SettingSideMenuEnum[sideItem]) {
      return setSettingFlag(true);
    }
    return setSettingFlag(false);
  };

  return (
    <Drawer
      variant="permanent"
      className={`${classes.drawer} ${
        appContext?.sideOpen ? classes.openSide : classes.notOpenSide
      } ${settingFlag ? classes.setting : classes.normal}`}
    >
      <div className={classes.toolbar} />
      <SideMenuField settingFlag={settingFlag} />
    </Drawer>
  );
};

export default SideMenuForm;
