import { useTranslation } from "react-i18next";

const ActiveEnum = () => {
  const { t } = useTranslation();

  const ActiveLabel = {
    active: {
      code: true,
      label: t("type.activeEnum.active"),
    },
    inactive: {
      code: false,
      label: t("type.activeEnum.inactive"),
    },
  };

  return {
    ActiveLabel,
  };
};

export default ActiveEnum;
