import React from "react";
import { Box, Stack } from "@mui/material";
import classes from "./ChangePasswordField.module.scss";
import { useTranslation } from "react-i18next";
import PasswordText from "../../parts/TextField/PasswordText.tsx";

type ChangePasswordFieldProps = {
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  newPassword: string;
  setNewPassword: React.Dispatch<React.SetStateAction<string>>;
  reNewPassword: string;
  setReNewPassword: React.Dispatch<React.SetStateAction<string>>;
};

const ChangePasswordField: React.FC<ChangePasswordFieldProps> = (props) => {
  const { t } = useTranslation();

  /**
   * パスワード入力イベント
   * @param event
   */
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setPassword(event.target.value);
  };

  /**
   * 新しいパスワード入力イベント
   * @param event
   */
  const handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.setNewPassword(event.target.value);
  };

  /**
   * 新しいパスワード（確認）入力イベント
   * @param event
   */
  const handleReNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.setReNewPassword(event.target.value);
  };

  return (
    <Stack>
      <Box sx={{ py: "1vh" }}>
        <PasswordText
          label={t("setting.newPassword.password")}
          classNameForm={classes.input_new_password_form}
          className={classes.input_new_password}
          handleChange={handlePasswordChange}
          value={props.password}
          inputProps={{
            minLength: 8,
          }}
        />
      </Box>
      <Box sx={{ py: "1vh" }}>
        <PasswordText
          label={t("setting.newPassword.newPassword")}
          classNameForm={classes.input_new_password_form}
          className={classes.input_new_password}
          handleChange={handleNewPasswordChange}
          value={props.newPassword}
          inputProps={{
            minLength: 8,
          }}
        />
      </Box>
      <Box sx={{ py: "1vh" }}>
        <PasswordText
          label={t("setting.newPassword.reNewPassword")}
          classNameForm={classes.input_new_password_form}
          className={classes.input_new_password}
          handleChange={handleReNewPasswordChange}
          value={props.reNewPassword}
          inputProps={{
            minLength: 8,
          }}
        />
      </Box>
    </Stack>
  );
};

export default ChangePasswordField;
