import React, { createContext, useState } from "react";
import { UsersManageMode } from "../types/enum/UsersManageEnum.tsx";
import { OrganizationUsers } from "../types/response/OrganizationUsersResponse.tsx";
import { Organizations } from "../types/response/OrganizationsResponse.tsx";

type UsersManageProps = {
  mode: string;
  setMode: React.Dispatch<React.SetStateAction<string>>;
  selectSearch: string;
  setSelectSearch: React.Dispatch<React.SetStateAction<string>>;
  organizations: Organizations[];
  setOrganizations: React.Dispatch<React.SetStateAction<Organizations[]>>;
  organizationUsers: OrganizationUsers[];
  setOrganizationUsers: React.Dispatch<
    React.SetStateAction<OrganizationUsers[]>
  >;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  searchRole: string;
  setSearchRole: React.Dispatch<React.SetStateAction<string>>;
  searchActive: string;
  setSearchActive: React.Dispatch<React.SetStateAction<string>>;
  searchOpt: string;
  setSearchOpt: React.Dispatch<React.SetStateAction<string>>;
};

const defaultValue = {
  mode: "",
  setMode: () => {},
  selectSearch: "",
  setSelectSearch: () => {},
  organizations: [],
  setOrganizations: () => {},
  organizationUsers: [],
  setOrganizationUsers: () => {},
  searchText: "",
  setSearchText: () => {},
  searchRole: "all",
  setSearchRole: () => {},
  searchActive: "all",
  setSearchActive: () => {},
  searchOpt: "all",
  setSearchOpt: () => {},
};

// ユーザー管理コンテキスト
const UsersManageContext = createContext<UsersManageProps>(defaultValue);

type Props = {
  children: React.ReactNode;
};

export const UsersManageProvider: React.VFC<Props> = ({ children }) => {
  const [mode, setMode] = useState<string>(UsersManageMode.users);
  const [selectSearch, setSelectSearch] = useState<string>(
    UsersManageMode.users
  );
  const [organizations, setOrganizations] = useState<Organizations[]>([]);
  const [organizationUsers, setOrganizationUsers] = useState<
    OrganizationUsers[]
  >([]);
  const [searchText, setSearchText] = useState<string>("");
  const [searchRole, setSearchRole] = useState<string>("all");
  const [searchActive, setSearchActive] = useState<string>("all");
  const [searchOpt, setSearchOpt] = useState<string>("all");

  return (
    <UsersManageContext.Provider
      value={{
        mode,
        setMode,
        selectSearch,
        setSelectSearch,
        organizations,
        setOrganizations,
        organizationUsers,
        setOrganizationUsers,
        searchText,
        setSearchText,
        searchRole,
        setSearchRole,
        searchActive,
        setSearchActive,
        searchOpt,
        setSearchOpt,
      }}
    >
      {children}
    </UsersManageContext.Provider>
  );
};

export default UsersManageContext;
