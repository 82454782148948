import React, { createContext, useRef, useState } from "react";
import AppTypes from "../types/AppTypes.tsx";
import SuccessAlert from "../components/parts/Alert/SuccessAlert.tsx";
import ErrorAlert from "../components/parts/Alert/ErrorAlert.tsx";
import WarningAlert from "../components/parts/Alert/WarningAlert.tsx";
import InfoAlert from "../components/parts/Alert/InfoAlert.tsx";
import { JwtPayload } from "jwt-decode";
import { useFullScreenHandle } from "react-full-screen";
import { SideMenuEnum } from "../types/enum/SideMenuEnum.tsx";

const appData: AppTypes = {
  accessToken: {},
  setAccessToken: () => {},
  idToken: {},
  setIdToken: () => {},
  sideOpen: false,
  setSideOpen: () => {},
  sideItem: "",
  setSideItem: () => {},
  successOpen: false,
  setSuccessOpen: (isOpen: boolean) => {},
  errorOpen: false,
  setErrorOpen: (isOpen: boolean) => {},
  warningOpen: false,
  setWarningOpen: (isOpen: boolean) => {},
  infoOpen: false,
  setInfoOpen: (isOpen: boolean) => {},
  alertMessage: "",
  setAlertMessage: () => {},
  globalIP: "",
  setGlobalIP: () => {},
};

// システム共通コンテキスト
const AppContext = createContext<AppTypes>(appData);

type Props = {
  children: React.ReactNode;
};

export const AppProvider: React.VFC<Props> = ({ children }) => {
  const [accessToken, setAccessToken] = useState<JwtPayload>({} as JwtPayload);
  const [idToken, setIdToken] = useState<JwtPayload>({} as JwtPayload);
  const [sideOpen, setSideOpen] = useState<boolean>(true);
  const [sideItem, setSideItem] = useState<string>(SideMenuEnum.home);
  const [successOpen, setSuccess] = useState<boolean>(false);
  const [errorOpen, setError] = useState<boolean>(false);
  const [warningOpen, setWarning] = useState<boolean>(false);
  const [infoOpen, setInfo] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const pdfDownloadRef = useRef();
  const fullScreenHandle = useFullScreenHandle();
  const [globalIP, setGlobalIP] = useState<string>("");

  /**
   * 成功アラート表示処理
   * @param isOpen
   */
  const setSuccessOpen = (isOpen: boolean) => {
    setSuccess(isOpen);
    setError(false);
    setWarning(false);
    setInfo(false);
  };

  /**
   * 失敗アラート表示処理
   * @param isOpen
   */
  const setErrorOpen = (isOpen: boolean) => {
    setSuccess(false);
    setError(isOpen);
    setWarning(false);
    setInfo(false);
  };

  /**
   * 警告アラート表示処理
   * @param isOpen
   */
  const setWarningOpen = (isOpen: boolean) => {
    setSuccess(false);
    setError(false);
    setWarning(isOpen);
    setInfo(false);
  };

  /**
   * インフォメーションアラート表示処理
   * @param isOpen
   */
  const setInfoOpen = (isOpen: boolean) => {
    setSuccess(false);
    setError(false);
    setWarning(false);
    setInfo(isOpen);
  };

  return (
    <AppContext.Provider
      value={{
        accessToken,
        setAccessToken,
        idToken,
        setIdToken,
        sideOpen,
        setSideOpen,
        sideItem,
        setSideItem,
        successOpen,
        setSuccessOpen,
        errorOpen,
        setErrorOpen,
        warningOpen,
        setWarningOpen,
        infoOpen,
        setInfoOpen,
        alertMessage,
        setAlertMessage,
        pdfDownloadRef,
        fullScreenHandle,
        globalIP,
        setGlobalIP,
      }}
    >
      <SuccessAlert />
      <ErrorAlert />
      <WarningAlert />
      <InfoAlert />
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
