import { useTranslation } from "react-i18next";

export const UnitType = () => {
  const { t } = useTranslation();

  const UnitTypes = {
    per_piece: {
      code: "per_piece",
      name: t("type.unitType.unitTypeEnum.perPiece"),
    },
    per_meter: {
      code: "per_meter",
      name: t("type.unitType.unitTypeEnum.perMeter"),
    },
    per_sheet: {
      code: "per_sheet",
      name: t("type.unitType.unitTypeEnum.perSheet"),
    },
    per_batch: {
      code: "per_batch",
      name: t("type.unitType.unitTypeEnum.perBatch"),
    },
    per_yen: { code: "per_yen", name: t("type.unitType.unitTypeEnum.perYen") },
    per_kilometer: {
      code: "per_kilometer",
      name: t("type.unitType.unitTypeEnum.perKilometer"),
    },
    per_box: { code: "per_box", name: t("type.unitType.unitTypeEnum.perBox") },
    per_kwh: { code: "per_kwh", name: t("type.unitType.unitTypeEnum.perKwh") },
    per_kg: { code: "per_kg", name: t("type.unitType.unitTypeEnum.perKg") },
    per_square_meter: {
      code: "per_square_meter",
      name: t("type.unitType.unitTypeEnum.perPiperSquareMeterece"),
    },
    per_book: {
      code: "per_book",
      name: t("type.unitType.unitTypeEnum.perBook"),
    },
    per_mj: { code: "per_mj", name: t("type.unitType.unitTypeEnum.perMj") },
    per_ton: { code: "per_ton", name: t("type.unitType.unitTypeEnum.perTon") },
    per_minute: {
      code: "per_minute",
      name: t("type.unitType.unitTypeEnum.perMinute"),
    },
    per_lot: { code: "per_lot", name: t("type.unitType.unitTypeEnum.perLot") },
    per_others: {
      code: "per_others",
      name: t("type.unitType.unitTypeEnum.perOthers"),
    },
    per_liter: {
      code: "per_liter",
      name: t("type.unitType.unitTypeEnum.perLiter"),
    },
    per_hour: {
      code: "per_hour",
      name: t("type.unitType.unitTypeEnum.perHour"),
    },
    per_pallet: {
      code: "per_pallet",
      name: t("type.unitType.unitTypeEnum.perPallet"),
    },
    per_cubic_meter: {
      code: "per_cubic_meter",
      name: t("type.unitType.unitTypeEnum.perCubicMeter"),
    },
    per_ton_kilometer: {
      code: "per_ton_kilometer",
      name: t("type.unitType.unitTypeEnum.perTonKilometer"),
    },
    per_bag: { code: "per_bag", name: t("type.unitType.unitTypeEnum.perBag") },
  };

  const UnitTypesKg = {
    kg_per_piece: {
      code: "kg_per_piece",
      name: t("type.unitType.unitTypeKgEnum.kgPerPiece"),
    },
    kg_per_meter: {
      code: "kg_per_meter",
      name: t("type.unitType.unitTypeKgEnum.kgPerMeter"),
    },
    kg_per_sheet: {
      code: "kg_per_sheet",
      name: t("type.unitType.unitTypeKgEnum.kgPerSheet"),
    },
    kg_per_batch: {
      code: "kg_per_batch",
      name: t("type.unitType.unitTypeKgEnum.kgPerBatch"),
    },
    kg_per_yen: {
      code: "kg_per_yen",
      name: t("type.unitType.unitTypeKgEnum.kgPerYen"),
    },
    kg_per_kilometer: {
      code: "kg_per_kilometer",
      name: t("type.unitType.unitTypeKgEnum.kgPerKilometer"),
    },
    kg_per_box: {
      code: "kg_per_box",
      name: t("type.unitType.unitTypeKgEnum.kgPerBox"),
    },
    kg_per_kwh: {
      code: "kg_per_kwh",
      name: t("type.unitType.unitTypeKgEnum.kgPerKwh"),
    },
    kg_per_kg: {
      code: "kg_per_kg",
      name: t("type.unitType.unitTypeKgEnum.kgPerKg"),
    },
    kg_per_square_meter: {
      code: "kg_per_square_meter",
      name: t("type.unitType.unitTypeKgEnum.kgPerPiperSquareMeterece"),
    },
    kg_per_book: {
      code: "kg_per_book",
      name: t("type.unitType.unitTypeKgEnum.kgPerBook"),
    },
    kg_per_mj: {
      code: "kg_per_mj",
      name: t("type.unitType.unitTypeKgEnum.kgPerMj"),
    },
    kg_per_ton: {
      code: "kg_per_ton",
      name: t("type.unitType.unitTypeKgEnum.kgPerTon"),
    },
    kg_per_minute: {
      code: "kg_per_minute",
      name: t("type.unitType.unitTypeKgEnum.kgPerMinute"),
    },
    kg_per_lot: {
      code: "kg_per_lot",
      name: t("type.unitType.unitTypeKgEnum.kgPerLot"),
    },
    kg_per_others: {
      code: "kg_per_others",
      name: t("type.unitType.unitTypeKgEnum.kgPerOthers"),
    },
    kg_per_liter: {
      code: "kg_per_liter",
      name: t("type.unitType.unitTypeKgEnum.kgPerLiter"),
    },
    kg_per_hour: {
      code: "kg_per_hour",
      name: t("type.unitType.unitTypeKgEnum.kgPerHour"),
    },
    kg_per_pallet: {
      code: "kg_per_pallet",
      name: t("type.unitType.unitTypeKgEnum.kgPerPallet"),
    },
    kg_per_cubic_meter: {
      code: "kg_per_cubic_meter",
      name: t("type.unitType.unitTypeKgEnum.kgPerCubicMeter"),
    },
    kg_per_ton_kilometer: {
      code: "kg_per_ton_kilometer",
      name: t("type.unitType.unitTypeKgEnum.kgPerTonKilometer"),
    },
    kg_per_bag: {
      code: "kg_per_bag",
      name: t("type.unitType.unitTypeKgEnum.kgperBag"),
    },
  };

  const NumeratorUnit = [
    { value: "g", label: "g" },
    { value: "kg", label: "kg" },
    { value: "t", label: "t" },
  ];

  const DenominatorUnit = [
    {
      value: "unit_managed",
      label: t("type.unitType.denominatorEnum.unitManaged"),
    },
    { value: "piece", label: t("type.unitType.denominatorEnum.piece") },
    { value: "meter", label: t("type.unitType.denominatorEnum.meter") },
    { value: "sheet", label: t("type.unitType.denominatorEnum.sheet") },
    { value: "batch", label: t("type.unitType.denominatorEnum.batch") },
    { value: "yen", label: t("type.unitType.denominatorEnum.yen") },
    { value: "kilometer", label: t("type.unitType.denominatorEnum.kilometer") },
    { value: "box", label: t("type.unitType.denominatorEnum.box") },
    { value: "kwh", label: t("type.unitType.denominatorEnum.kwh") },
    { value: "kg", label: t("type.unitType.denominatorEnum.kg") },
    {
      value: "square_meter",
      label: t("type.unitType.denominatorEnum.squareMeter"),
    },
    { value: "book", label: t("type.unitType.denominatorEnum.book") },
    { value: "mj", label: t("type.unitType.denominatorEnum.mj") },
    { value: "ton", label: t("type.unitType.denominatorEnum.ton") },
    { value: "minute", label: t("type.unitType.denominatorEnum.minute") },
    { value: "lot", label: t("type.unitType.denominatorEnum.lot") },
    { value: "others", label: t("type.unitType.denominatorEnum.others") },
    { value: "liter", label: t("type.unitType.denominatorEnum.liter") },
    { value: "hour", label: t("type.unitType.denominatorEnum.hour") },
    { value: "pallet", label: t("type.unitType.denominatorEnum.pallet") },
    {
      value: "cubic_meter",
      label: t("type.unitType.denominatorEnum.cubicmeter"),
    },
    {
      value: "ton_kilometer",
      label: t("type.unitType.denominatorEnum.tonKilometer"),
    },
    { value: "bag", label: t("type.unitType.denominatorEnum.bag") },
  ];

  const getUnitName = (type: string, code: string) => {
    let unitType;
    if (code === "per_unit_managed" || code === "kg_per_unit_managed") {
      return t("summary.unitSwitching.company");
    }
    if (type === "kg") {
      unitType = UnitTypesKg[code];
    } else {
      unitType = UnitTypes[code];
    }
    if (unitType) {
      return unitType.name;
    }
    return "";
  };

  const UnitTypeEnum = [
    [
      { code: UnitTypes.per_piece.code, name: UnitTypes.per_piece.name },
      { code: UnitTypes.per_meter.code, name: UnitTypes.per_meter.name },
      { code: UnitTypes.per_sheet.code, name: UnitTypes.per_sheet.name },
      { code: UnitTypes.per_batch.code, name: UnitTypes.per_batch.name },
    ],
    [
      { code: UnitTypes.per_yen.code, name: UnitTypes.per_yen.name },
      {
        code: UnitTypes.per_kilometer.code,
        name: UnitTypes.per_kilometer.name,
      },
      { code: UnitTypes.per_box.code, name: UnitTypes.per_box.name },
      { code: UnitTypes.per_kwh.code, name: UnitTypes.per_kwh.name },
    ],
    [
      { code: UnitTypes.per_kg.code, name: UnitTypes.per_kg.name },
      {
        code: UnitTypes.per_square_meter.code,
        name: UnitTypes.per_square_meter.name,
      },
      { code: UnitTypes.per_book.code, name: UnitTypes.per_book.name },
      { code: UnitTypes.per_mj.code, name: UnitTypes.per_mj.name },
    ],
    [
      { code: UnitTypes.per_ton.code, name: UnitTypes.per_ton.name },
      { code: UnitTypes.per_minute.code, name: UnitTypes.per_minute.name },
      { code: UnitTypes.per_lot.code, name: UnitTypes.per_lot.name },
      { code: UnitTypes.per_others.code, name: UnitTypes.per_others.name },
    ],
    [
      { code: UnitTypes.per_liter.code, name: UnitTypes.per_liter.name },
      { code: UnitTypes.per_hour.code, name: UnitTypes.per_hour.name },
      { code: UnitTypes.per_pallet.code, name: UnitTypes.per_pallet.name },
      { code: "", name: "" },
    ],
    [
      {
        code: UnitTypes.per_cubic_meter.code,
        name: UnitTypes.per_cubic_meter.name,
      },
      {
        code: UnitTypes.per_ton_kilometer.code,
        name: UnitTypes.per_ton_kilometer.name,
      },
      { code: UnitTypes.per_bag.code, name: UnitTypes.per_bag.name },
      { code: "", name: "" },
    ],
  ];

  const UnitTypeKgEnum = [
    [
      {
        code: UnitTypesKg.kg_per_piece.code,
        name: UnitTypesKg.kg_per_piece.name,
      },
      {
        code: UnitTypesKg.kg_per_meter.code,
        name: UnitTypesKg.kg_per_meter.name,
      },
      {
        code: UnitTypesKg.kg_per_sheet.code,
        name: UnitTypesKg.kg_per_sheet.name,
      },
      {
        code: UnitTypesKg.kg_per_batch.code,
        name: UnitTypesKg.kg_per_batch.name,
      },
    ],
    [
      { code: UnitTypesKg.kg_per_yen.code, name: UnitTypesKg.kg_per_yen.name },
      {
        code: UnitTypesKg.kg_per_kilometer.code,
        name: UnitTypesKg.kg_per_kilometer.name,
      },
      { code: UnitTypesKg.kg_per_box.code, name: UnitTypesKg.kg_per_box.name },
      { code: UnitTypesKg.kg_per_kwh.code, name: UnitTypesKg.kg_per_kwh.name },
    ],
    [
      { code: UnitTypesKg.kg_per_kg.code, name: UnitTypesKg.kg_per_kg.name },
      {
        code: UnitTypesKg.kg_per_square_meter.code,
        name: UnitTypesKg.kg_per_square_meter.name,
      },
      {
        code: UnitTypesKg.kg_per_book.code,
        name: UnitTypesKg.kg_per_book.name,
      },
      { code: UnitTypesKg.kg_per_mj.code, name: UnitTypesKg.kg_per_mj.name },
    ],
    [
      { code: UnitTypesKg.kg_per_ton.code, name: UnitTypesKg.kg_per_ton.name },
      {
        code: UnitTypesKg.kg_per_minute.code,
        name: UnitTypesKg.kg_per_minute.name,
      },
      { code: UnitTypesKg.kg_per_lot.code, name: UnitTypesKg.kg_per_lot.name },
      {
        code: UnitTypesKg.kg_per_others.code,
        name: UnitTypesKg.kg_per_others.name,
      },
    ],
    [
      {
        code: UnitTypesKg.kg_per_liter.code,
        name: UnitTypesKg.kg_per_liter.name,
      },
      {
        code: UnitTypesKg.kg_per_hour.code,
        name: UnitTypesKg.kg_per_hour.name,
      },
      {
        code: UnitTypesKg.kg_per_pallet.code,
        name: UnitTypesKg.kg_per_pallet.name,
      },
      { code: "", name: "" },
    ],
    [
      {
        code: UnitTypesKg.kg_per_cubic_meter.code,
        name: UnitTypesKg.kg_per_cubic_meter.name,
      },
      {
        code: UnitTypesKg.kg_per_ton_kilometer.code,
        name: UnitTypesKg.kg_per_ton_kilometer.name,
      },
      { code: UnitTypesKg.kg_per_bag.code, name: UnitTypesKg.kg_per_bag.name },
      { code: "", name: "" },
    ],
  ];

  return {
    getUnitName,
    UnitTypeEnum,
    UnitTypeKgEnum,
    NumeratorUnit,
    DenominatorUnit,
  };
};
