import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import classes from "./AddUserDialogForm.module.scss";
import { useTranslation } from "react-i18next";
import InputText from "../../parts/TextField/InputText.tsx";
import PasswordText from "../../parts/TextField/PasswordText.tsx";
import ToggleSwitch from "../../parts/Switch/ToggleSwitch.tsx";
import BaseButton from "../../parts/Button/BaseButton.tsx";
import { OrganizationUsers } from "../../../types/response/OrganizationUsersResponse.tsx";
import SelectItem from "../../parts/Select/SelectItem.tsx";
import RoleEnum from "../../../types/enum/RoleEnum.tsx";
import CommonUtils from "../../../common/utils/CommonUtils.tsx";
import UserContext from "../../../contexts/UserContext.tsx";
import { UserInfo } from "../../../types/response/UserInfoResponse.tsx";
import UpdateUserRequest from "../../../types/request/UpdateUserRequest.tsx";
import CreateUserRequest from "../../../types/request/CreateUserRequest.tsx";
import { UserManageFuatures } from "../../../features/UserManageFuatures.tsx";
import AppContext from "../../../contexts/AppContext.tsx";
import ActiveEnum from "../../../types/enum/ActiveEnum.tsx";

type AddUserDialogFormProps = {
  className?: string;
  open: boolean;
  handleClose: () => void;
  handleSave: (param, userId?) => void;
  editUser?: OrganizationUsers;
  customerName: string;
  customerId: string;
  isAdministrator: boolean;
};

const AddUserDialogForm: React.FC<AddUserDialogFormProps> = (props) => {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const { Role } = RoleEnum();
  const { ActiveLabel } = ActiveEnum();
  const {
    checkCreateUserParam,
    checkUpdateUserParam,
    isDispEditUserPassword,
    createSelectRoles,
  } = UserManageFuatures();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [reNewpassword, setReNewPassword] = useState<string>("");
  const [roleId, setRoleId] = useState<string>(Role.data_updater.code);
  const [activeFlag, setActiveFlag] = useState<boolean>(true);
  const [exeShowPassword, setExeShowPassword] = useState<boolean>(false);

  /**
   * ダイアログ表示時、入力項目を初期化する
   */
  useEffect(() => {
    setFirstName(props.editUser ? props.editUser.first_name : "");
    setLastName(props.editUser ? props.editUser.last_name : "");
    setEmail(props.editUser ? props.editUser.email : "");
    setPassword("");
    setNewPassword("");
    setReNewPassword("");
    setRoleId(props.editUser ? props.editUser.role_id : Role.data_updater.code);
    setActiveFlag(props.editUser ? props.editUser.active_flag : true);
    setExeShowPassword(false);
  }, [props.open]);

  /**
   * パスワード生成ボタン押下
   */
  const handleCreatPasswardClick = () => {
    setNewPassword(CommonUtils.createPassword(8));
    setExeShowPassword(!exeShowPassword);
  };

  /**
   * 新規作成ボタン押下処理
   * @returns
   */
  const handleSaveCreateClick = () => {
    const param = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: newPassword,
      language: userInfo.language,
      organization_id: props.customerId,
      role_id: roleId,
      active_flag: activeFlag,
    } as CreateUserRequest;
    const message = checkCreateUserParam(param, reNewpassword);
    if (message) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(message);
      return;
    }
    props.handleSave(param);
  };

  /**
   * 更新ボタン押下処理
   * @returns
   */
  const handleSaveUpdateClick = () => {
    const param = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      old_password: password,
      new_password: newPassword,
      language: userInfo.language,
      organization_id: props.customerId,
      role_id: roleId,
      active_flag: activeFlag,
    } as UpdateUserRequest;
    const message = checkUpdateUserParam(param, reNewpassword);
    if (message) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(message);
      return;
    }
    props.handleSave(param, props.editUser?.user_id);
  };

  return (
    <Dialog className={classes.dialog} open={props.open}>
      <DialogTitle className={classes.title}>
        {props.editUser && (
          <div>{`${t("setting.users.addEditUser.editUserTitle")}`}</div>
        )}
        {!props.editUser && (
          <div>{`${t("setting.users.addEditUser.addUserTitle")}`}</div>
        )}
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <List className={classes.list}>
          {/* 顧客情報 */}
          <ListItem className={classes.listTitle}>
            {t("setting.users.addEditUser.customerInfo")}
          </ListItem>
          <List sx={{ pl: "1vw" }}>
            <ListItem>
              <ListItemText sx={{ width: "20%" }}>
                {t("setting.users.addEditUser.customerName")}
              </ListItemText>
              <ListItemText
                sx={{ width: "80%" }}
              >{`: ${props.customerName}`}</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText sx={{ width: "20%" }}>
                {t("setting.users.addEditUser.customerId")}
              </ListItemText>
              <ListItemText
                sx={{ width: "80%" }}
              >{`: ${props.customerId}`}</ListItemText>
            </ListItem>
          </List>
          {/* ユーザー情報 */}
          <ListItem className={classes.listTitle}>
            {t("setting.users.addEditUser.userInfo")}
          </ListItem>
          <List sx={{ pl: "1vw" }}>
            <ListItem className={classes.listItem}>
              <InputText
                label={t("setting.users.addEditUser.userLastName")}
                className={`${classes.inputText} ${classes.normalText}`}
                value={lastName}
                handleChange={(e) => setLastName(e.target.value)}
                size="small"
                disabled={!props.isAdministrator}
              />
              <InputText
                label={t("setting.users.addEditUser.userFirstName")}
                className={`${classes.inputText} ${classes.normalText}`}
                value={firstName}
                handleChange={(e) => setFirstName(e.target.value)}
                size="small"
                disabled={!props.isAdministrator}
              />
            </ListItem>
            <ListItem className={classes.listItem}>
              <InputText
                label={t("setting.users.addEditUser.userId")}
                className={`${classes.inputText} ${classes.normalText} ${classes.emailText}`}
                value={email}
                handleChange={(e) => setEmail(e.target.value)}
                size="small"
                disabled={!props.isAdministrator}
              />
            </ListItem>
          </List>
          {/* パスワード */}
          {isDispEditUserPassword(props.editUser?.user_id) && (
            <Box sx={{ p: "1vh 0vh" }}>
              <ListItem className={`${classes.listTitle}`}>
                {t("setting.users.addEditUser.password")}
              </ListItem>
              <List sx={{ pl: "1vw" }}>
                <ListItem>
                  <Box sx={{ alignSelf: "start", width: "35%", pt: 0 }}>
                    {props.editUser && (
                      <PasswordText
                        label={t("setting.users.addEditUser.nowPassword")}
                        value={password}
                        classNameForm={`${classes.inputText} ${classes.passwordText}`}
                        handleChange={(e) => setPassword(e.target.value)}
                        size="small"
                      />
                    )}
                    <PasswordText
                      label={
                        props.editUser
                          ? t("setting.users.addEditUser.newPassword")
                          : t("setting.users.addEditUser.password")
                      }
                      value={newPassword}
                      classNameForm={`${classes.inputText} ${classes.passwordText}`}
                      handleChange={(e) => setNewPassword(e.target.value)}
                      exeShowPassword={exeShowPassword}
                      size="small"
                    />
                    <PasswordText
                      label={
                        props.editUser
                          ? t("setting.users.addEditUser.reNewPassword")
                          : t("setting.users.addEditUser.rePassword")
                      }
                      value={reNewpassword}
                      classNameForm={`${classes.inputText} ${classes.passwordText}`}
                      handleChange={(e) => setReNewPassword(e.target.value)}
                      size="small"
                    />
                  </Box>
                  <Box
                    sx={{ width: "25%", p: "1.5vh 1vw", alignSelf: "start" }}
                  >
                    <BaseButton
                      handleClick={handleCreatPasswardClick}
                      label={t("setting.users.addEditUser.createPassword")}
                      className={classes.createPassword}
                    />
                  </Box>
                  <Box sx={{ width: "40%" }}>
                    <Typography
                      variant="body1"
                      component="div"
                      className={classes.explain}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("setting.newPassword.enterCharacters"),
                        }}
                      />
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className={classes.explain}
                    >
                      {t("setting.newPassword.symbol")}
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            </Box>
          )}
          {/* 権限 */}
          <ListItem className={`${classes.listTitle}`}>
            {t("setting.users.addEditUser.role")}
          </ListItem>
          <List sx={{ pl: "1vw" }}>
            <SelectItem
              title=""
              values={createSelectRoles()}
              selectValue={roleId}
              handleClick={(e) => setRoleId(e.target.value)}
              disabled={!props.isAdministrator}
            />
          </List>
          {/* アクティブ */}
          <ListItem className={classes.listTitle}>
            {t("setting.users.addEditUser.active")}
          </ListItem>
          <List sx={{ pl: "1vw" }}>
            <ToggleSwitch
              defaultChecked={activeFlag}
              handleChange={(e: any) => {
                setActiveFlag(e.target.checked);
              }}
              leftLabel={ActiveLabel.inactive.label}
              rightLabel={ActiveLabel.active.label}
              labelClassName={classes.toggleSwitch}
              disabled={!props.isAdministrator}
            />
          </List>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={props.handleClose}
          color="secondary"
        >
          {t("setting.users.addEditUser.cancel")}
        </Button>
        {!props.editUser && (
          <Button
            className={classes.button}
            onClick={handleSaveCreateClick}
            color="primary"
          >
            {t("setting.users.addEditUser.save")}
          </Button>
        )}
        {props.editUser && (
          <Button
            className={classes.button}
            onClick={handleSaveUpdateClick}
            color="primary"
          >
            {t("setting.users.addEditUser.update")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddUserDialogForm;
