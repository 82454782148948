import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import classes from "./EditOptDialogForm.module.scss";
import { useTranslation } from "react-i18next";
import ToggleSwitch from "../../parts/Switch/ToggleSwitch.tsx";
import UserContext from "../../../contexts/UserContext.tsx";
import { UserInfo } from "../../../types/response/UserInfoResponse.tsx";
import Oragnization from "../../../types/response/Oragnization.tsx";

type EditOptDialogFormProps = {
  className?: string;
  open: boolean;
  handleClose: () => void;
  handleSave: (corporateNumber: string, mfaFlag: boolean) => void;
  editCorporat?: Oragnization;
};

const EditOptDialogForm: React.FC<EditOptDialogFormProps> = (props) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const [otp, setOtp] = useState<boolean>(true);

  /**
   * ダイアログ表示時、入力項目を初期化する
   */
  useEffect(() => {
    setOtp(props.editCorporat ? props.editCorporat.mfa_flag : true);
  }, [props.open]);

  /**
   * 更新ボタン押下処理
   * @returns
   */
  const handleSaveUpdateClick = () => {
    if (props.editCorporat) {
      props.handleSave(props.editCorporat.corporate_number, otp);
    }
  };

  return (
    <Dialog className={classes.dialog} open={props.open}>
      <DialogTitle className={classes.title}>
        <div>{`${t("setting.otpSetting.editOtp.title")}`}</div>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <List className={classes.list}>
          {/* ユーザー情報 */}
          <ListItem className={classes.listTitle}>
            {t("setting.otpSetting.editOtp.userInfo")}
          </ListItem>
          <List sx={{ pl: "1vw" }}>
            <ListItem>
              <ListItemText sx={{ width: "30%" }}>
                {t("setting.otpSetting.editOtp.userName")}
              </ListItemText>
              <ListItemText
                sx={{ width: "70%" }}
              >{`: ${userInfo.last_name} ${userInfo.first_name}`}</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText sx={{ width: "30%" }}>
                {t("setting.otpSetting.editOtp.userId")}
              </ListItemText>
              <ListItemText
                sx={{ width: "70%" }}
              >{`: ${userInfo.email}`}</ListItemText>
            </ListItem>
          </List>
          {/* 顧客情報 */}
          <ListItem className={classes.listTitle}>
            {t("setting.otpSetting.editOtp.customerInfo")}
          </ListItem>
          <List sx={{ pl: "1vw" }}>
            <ListItem>
              <ListItemText sx={{ width: "10%" }}>
                {t("setting.otpSetting.editOtp.customerName")}
              </ListItemText>
              <ListItemText sx={{ width: "90%" }}>{`: ${
                props.editCorporat ? props.editCorporat.company_name : ""
              }`}</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText sx={{ width: "10%" }}>
                {t("setting.otpSetting.editOtp.corporatNumber")}
              </ListItemText>
              <ListItemText sx={{ width: "90%" }}>{`: ${
                props.editCorporat ? props.editCorporat.corporate_number : ""
              }`}</ListItemText>
            </ListItem>
          </List>
          {/* 2段階認証 */}
          <ListItem className={classes.listTitle}>
            {t("setting.otpSetting.editOtp.otp")}
          </ListItem>
          <List sx={{ pl: "1vw" }}>
            <ToggleSwitch
              defaultChecked={otp}
              handleChange={(e: any) => {
                setOtp(e.target.checked);
              }}
              leftLabel={"OFF"}
              rightLabel={"ON"}
              labelClassName={classes.toggleSwitch}
            />
          </List>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={props.handleClose}
          color="secondary"
        >
          {t("setting.otpSetting.editOtp.cancel")}
        </Button>
        <Button
          className={classes.button}
          onClick={handleSaveUpdateClick}
          color="primary"
        >
          {t("setting.otpSetting.editOtp.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditOptDialogForm;
