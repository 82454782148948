import React, { useContext } from "react";
import classes from "./LogsFilterForm.module.scss";
import { useTranslation } from "react-i18next";
import { Paper, Typography } from "@mui/material";
import InputSearchText from "../../parts/TextField/InputSearchText.tsx";
import LogContext from "../../../contexts/LogContext.tsx";

const LogsFilterForm = () => {
  const { t } = useTranslation();
  const logContext = useContext(LogContext);

  const inputSearchTextChange = (e: any) => {
    logContext.setSearchText(e.target.value);
  };

  return (
    <Paper sx={{ display: "flex" }} className={classes.userFilterForm}>
      <Typography variant="body1" component="div" className={classes.title}>
        {t("logs.search.title")}
      </Typography>
      <InputSearchText
        placeholder={t("logs.search.text")}
        className={classes.inputSearchText}
        handleChange={inputSearchTextChange}
      />
    </Paper>
  );
};

export default LogsFilterForm;
