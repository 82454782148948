import React, { useContext, useEffect, useState } from "react";
import classes from "./CusomerListForm.module.scss";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditUserDialogForm from "./EditUserDialogForm.tsx";
import { Organizations } from "../../../types/response/OrganizationsResponse.tsx";
import { UserManageFuatures } from "../../../features/UserManageFuatures.tsx";
import UsersManageContext from "../../../contexts/UsersManageContext.tsx";
import UserContext from "../../../contexts/UserContext.tsx";
import { UserInfo } from "../../../types/response/UserInfoResponse.tsx";
import { useUser } from "../../../hooks/useUser.tsx";
import UpdateUserRequest from "../../../types/request/UpdateUserRequest.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";
import { UserFuatures } from "../../../features/UserFuatures.tsx";

type CusomerListFormProps = {
  datas: Organizations[];
};

const CusomerListForm: React.FC<CusomerListFormProps> = (props) => {
  const { t } = useTranslation();
  const usersMagage = useContext(UsersManageContext);
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const { isAdministrator } = UserFuatures();
  const { dispChipActive, dispChipRole } = UserManageFuatures();
  const { putUserApi, userApiSuccess, userApiLoading } = useUser();
  const [page, setPage] = useState(0);
  const [editCustomer, setEditCustomer] = useState<Organizations>();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [editOpen, setEditOpen] = useState(false);
  const [isAdmin, setAdmin] = useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditClick = (data: Organizations) => {
    setEditCustomer({ ...data });
    setEditOpen(true);
  };
  const handleEditClose = () => {
    setEditOpen(false);
  };
  const handleEditSave = (param: UpdateUserRequest) => {
    putUserApi(param, usersMagage.selectSearch);
  };

  /**
   * ユーザー更新処理が正常終了したらダイアログをクローズする
   */
  useEffect(() => {
    if (userApiSuccess) handleEditClose();
  }, [userApiSuccess]);

  useEffect(() => {
    setAdmin(isAdministrator());
  }, [usersMagage.selectSearch]);

  /**
   * 表示顧客一覧データ作成処理
   * 絞り込み内容に応じて、表示する顧客一覧を作成する
   * @returns
   */
  const dispCustomerList = () => {
    return props.datas
      .filter((data) => {
        return (
          data.company_name.includes(usersMagage.searchText || "") ||
          data.office_name.includes(usersMagage.searchText || "") ||
          data.organization_id.includes(usersMagage.searchText || "")
        );
      })
      .filter((data) => {
        return (
          usersMagage.searchRole === "all" ||
          data.role_id === usersMagage.searchRole
        );
      })
      .filter((data) => {
        return (
          usersMagage.searchActive === "all" ||
          (data.active_flag !== undefined &&
            data.active_flag.toString() === usersMagage.searchActive)
        );
      });
  };

  /**
   * ユーザー名取得
   * @returns
   */
  const getUserData = (type: string) => {
    const user = userInfo.users.find((val) => {
      return val.user_id === usersMagage.selectSearch;
    });
    if (user) {
      if (type === "firstName") {
        return user.first_name;
      } else if (type === "lastName") {
        return user.last_name;
      } else if (type === "email") {
        return user.email;
      }
    }
    return "";
  };

  return (
    <>
      <TableContainer
        className={classes.activeInfoTable}
        sx={{ maxHeight: "55vh" }}
      >
        <Table
          size="small"
          sx={{ tableLayout: "fixed", width: "100%", height: "100%" }}
          stickyHeader
        >
          <TableHead>
            <TableRow key="th">
              <TableCell sx={{ width: "20%" }}>
                {t("setting.customers.customersTable.customerName")}
              </TableCell>
              <TableCell sx={{ width: "20%" }}>
                {t("setting.customers.customersTable.officeName")}
              </TableCell>
              <TableCell sx={{ width: "20%" }}>
                {t("setting.customers.customersTable.customerId")}
              </TableCell>
              <TableCell sx={{ width: "18%" }}>
                {t("setting.customers.customersTable.role")}
              </TableCell>
              <TableCell sx={{ width: "12%" }}>
                {t("setting.customers.customersTable.active")}
              </TableCell>
              <TableCell sx={{ width: "12%" }}>
                {t("setting.customers.customersTable.edit")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ overflowY: "auto" }}>
            {dispCustomerList().map((data, i) => {
              return (
                <TableRow key={"td_" + i}>
                  <TableCell sx={{ width: "20%" }}>
                    {data.company_name}
                  </TableCell>
                  <TableCell sx={{ width: "20%" }}>
                    {data.office_name}
                  </TableCell>
                  <TableCell sx={{ width: "20%" }}>
                    {data.organization_id}
                  </TableCell>
                  <TableCell sx={{ width: "18%" }}>
                    {dispChipRole(data.role_id, classes.chipCell)}
                  </TableCell>
                  <TableCell sx={{ width: "12%" }}>
                    {dispChipActive(data.active_flag, classes.chipCell)}
                  </TableCell>
                  <TableCell sx={{ width: "12%" }}>
                    <IconButton
                      onClick={() => handleEditClick(data)}
                      disabled={!isAdmin}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        labelRowsPerPage={t("common.rowsPerPage")}
        component="div"
        count={dispCustomerList().length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.tablePagination}
      />
      <EditUserDialogForm
        userId={getUserData("email")}
        firstName={getUserData("firstName")}
        lastName={getUserData("lastName")}
        editCustomer={editCustomer}
        open={editOpen}
        handleClose={handleEditClose}
        handleSave={handleEditSave}
      />
      {userApiLoading && <CircularProgressDialog />}
    </>
  );
};

export default CusomerListForm;
