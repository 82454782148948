import RequestCode from "./request/RequestCode.tsx";
import RequestID from "./request/RequestID.tsx";
import RequestScopeType from "./request/RequestScopeType.tsx";

export const defaultDatamartSearchData = {
  dashboard_types: [],
  gco2e_unit_type: "",
  period_type: "",
  numerator_unit_type: "g",
  denominator_unit_type: "unit_managed",
  filter_organization_params: {
    from_month: "",
    to_month: "",
    scopes: [],
    organizations: [],
    energies: [],
  },
  filter_params: {
    from_base_year_month: "",
    to_base_year_month: "",
    from_target_year_month: "",
    to_target_year_month: "",
    major_categories: [],
    middle_categories: [],
    minor_categories: [],
    parts_or_energies: [],
    self_manufacturing: [],
    products: [],
    process: [],
  },
};

// データマート絞り込み条件
interface DatamartSearchData {
  // ダッシュボードタイプ
  dashboard_types: string[];
  // 単位選択
  gco2e_unit_type: string;
  // 表示選択
  period_type: string;
  // 分子単位
  numerator_unit_type: string;
  // 分母単位
  denominator_unit_type: string;
  // 絞り込み条件（企業軸)
  filter_organization_params: {
    // 開始日
    from_month: string;
    // 終了日
    to_month: string;
    // スコープ
    scopes: RequestScopeType[];
    // ユーザー
    organizations: RequestID[];
    // エネルギー
    energies: RequestID[];
  };
  // 絞り込み条件
  filter_params: {
    // 基準日開始日
    from_base_year_month: string;
    // 基準日終了日
    to_base_year_month: string;
    // 対象日開始日
    from_target_year_month: string;
    // 対象日終了日
    to_target_year_month: string;
    // 大分類
    major_categories: RequestCode[];
    // 中分類
    middle_categories: RequestCode[];
    // 小分類
    minor_categories: RequestCode[];
    // 部品/エネルギー
    parts_or_energies: RequestID[];
    // 自社製造
    self_manufacturing?: RequestCode[];
    // 製品
    products: RequestID[];
    // 工程
    process?: RequestCode[];
  };
}

export default DatamartSearchData;
