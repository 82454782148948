import { useQuery } from "react-query";
import axios from "axios";
import { ApiCommon } from "../ApiCommon.tsx";

const RefreshApi = () => {
  const { CustomHeaders } = ApiCommon();
  /**
   * リフレッシュAPI
   * @param request
   */
  const RefreshToken = (onSuccess, onError) => {
    return useQuery(
      "refreshToken",
      async () => {
        const response = await axios.post(
          process.env.REACT_APP_HOST_URL + "/v1/admin/auth/refresh",
          undefined,
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  return { RefreshToken };
};

export default RefreshApi;
