import { useContext } from "react";
import { useTranslation } from "react-i18next";
import LogContext from "../contexts/LogContext.tsx";

export const LogFuatures = () => {
  const { t } = useTranslation();
  const logContext = useContext(LogContext);

  /**
   * 開始日・終了日初期値設定
   */
  const setDefaultFromToDate = () => {
    const fromDay = new Date();
    fromDay.setHours(0);
    fromDay.setMinutes(0);
    fromDay.setMonth(fromDay.getMonth() - 1);
    const toDay = new Date();
    toDay.setHours(0);
    toDay.setMinutes(0);
    toDay.setDate(toDay.getDate() + 1);
    logContext.setSearchFromDate(fromDay);
    logContext.setSearchToDate(toDay);
  };

  return {
    setDefaultFromToDate,
  };
};
