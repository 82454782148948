import { useContext } from "react";
import DatamartPcfChart from "../types/response/DatamartPcfChart.tsx";
import TabsContext from "../contexts/TabsContext.tsx";
import DatamartCompanyContext from "../contexts/DatamartCompanyContext.tsx";
import DatamartEmissionsData from "../types/response/DatamartEmissionsData.tsx";
import CommonUtils from "../common/utils/CommonUtils.tsx";
import { BarLineGraphType } from "../types/GraphType.tsx";
import { useTranslation } from "react-i18next";
import CfpTableData from "../types/response/CfpTable.tsx";

// ログイン処理後の共通処理
const DatamartFeatures = () => {
  const { t } = useTranslation();
  const { tab } = useContext(TabsContext);
  const { selectPieGraph, savePieGraph } = useContext(DatamartCompanyContext);

  const getChartData = (data, type: string) => {
    const tabs = [
      "major_category_data",
      "middle_category_data",
      "minor_category_data",
      "parts_or_energies_data",
    ];
    const chart = data[tabs[tab]];
    if (chart) {
      return chart[type];
    }
    return [];
  };

  /**
   * グラフ用データ作成
   * graphData:[
   *  {
   *    "date": 日付
   *    title: 値
   *    ※titleは各データタイトル
   *  }
   * ]
   * 左グラフ用データキーリスト
   * leftKeys:[title]
   * 右グラフ用データキーリスト
   * rightKeys:[title]
   * @param charts
   * @param graphs
   * @returns
   */
  const createChartData = (
    charts: DatamartPcfChart[],
    graphs: DatamartPcfChart[],
    rightLabel: string
  ) => {
    const graphData: object = {};
    const rightKeys: Array<string> = [];
    const leftKeys: Array<string> = [];
    let integerPartDigits = 0;
    if (!charts.length && !graphs.length) {
      return {
        graphData: [],
        rightKeys: rightKeys,
        leftKeys: leftKeys,
        integerPartDigits: integerPartDigits,
      };
    }

    charts.forEach((chart) => {
      if (!chart.data.length) return;
      const title = chart.title;
      chart.data.forEach((data) => {
        const date = data.x_axis_key;
        if (!graphData[date]) graphData[date] = { date: date };
        graphData[date][title + "(" + rightLabel + ")"] = data.value;
      });
      rightKeys.push(title + "(" + rightLabel + ")");
    });

    graphs.forEach((graph) => {
      if (!graph.data.length) return;
      const title = graph.title;
      graph.data.forEach((data) => {
        const date = data.x_axis_key;
        if (!graphData[date]) graphData[date] = { date: date };
        graphData[date][title] = data.value;
        const digits = CommonUtils.getIntegerPartDigits(data.value) - 2;
        if (digits > 2 && digits > integerPartDigits)
          integerPartDigits = digits;
      });
      leftKeys.push(title);
    });

    return {
      graphData: Object.values(graphData) as object[],
      rightKeys: rightKeys,
      leftKeys: leftKeys,
      integerPartDigits: integerPartDigits,
    };
  };

  /**
   * セルを選択された状態の場合、保持データを返却
   * @param data
   * @param type
   * @returns
   */
  const getPieChartData = (data: DatamartEmissionsData[], type: string) => {
    if (selectPieGraph.length !== 0) {
      switch (type) {
        case "scopes":
          return savePieGraph.data_by_scope;
        case "energies":
          return savePieGraph.data_by_energy;
        case "organizations":
          return savePieGraph.data_by_organization;
      }
    }
    return data;
  };

  /**
   * 棒グラフ、折れ線グラフ用のグラフデータ作成
   * @param datas
   * @returns
   */
  const createBarLineChartData = (
    lineDatas: DatamartPcfChart[],
    barDatas: DatamartPcfChart[]
  ): BarLineGraphType => {
    const lineKeys: string[] = [];
    const barKeys: string[] = [];
    const result = [];
    let integerPartDigits = 0;
    let decimalPointDigits = 0;
    if ((!lineDatas || !lineDatas.length) && (!barDatas || !barDatas.length)) {
      return {
        lineKeys: lineKeys,
        barKeys: barKeys,
        datas: result,
        unit: "",
        integerPartDigits: integerPartDigits,
      };
    }

    const unit = lineDatas[0].unit || "";
    lineDatas.forEach((data, i) => {
      if (!lineKeys.includes(data.title + "(基準)")) {
        lineKeys.push(data.title + "(基準)");
      }
      const chartDatas = data.data;
      chartDatas.forEach((chart, j) => {
        if (!result[chart.x_axis_key]) {
          result[chart.x_axis_key] = {
            date: chart.x_axis_key,
          };
        }
        result[chart.x_axis_key][data.title + "(基準)"] = chart.value;
        const digits = CommonUtils.getIntegerPartDigits(chart.value) - 2;
        const minDigits = CommonUtils.getDecimalPointDigits(chart.value) - 2;
        if (digits > 2 && digits > integerPartDigits)
          integerPartDigits = digits;
        if (minDigits < -2 && decimalPointDigits > minDigits) {
          decimalPointDigits = minDigits;
        }
      });
    });

    barDatas.forEach((data, i) => {
      if (!barKeys.includes(data.title)) {
        barKeys.push(data.title);
      }
      const chartDatas = data.data;
      chartDatas.forEach((chart, j) => {
        if (!result[chart.x_axis_key]) {
          result[chart.x_axis_key] = {
            date: chart.x_axis_key,
          };
        }
        result[chart.x_axis_key][data.title] = chart.value;
        const digits = CommonUtils.getIntegerPartDigits(chart.value) - 2;
        const minDigits = CommonUtils.getDecimalPointDigits(chart.value) - 2;
        if (digits > 2 && digits > integerPartDigits)
          integerPartDigits = digits;
        if (minDigits < -2 && decimalPointDigits > minDigits) {
          decimalPointDigits = minDigits;
        }
      });
    });

    return {
      lineKeys: lineKeys,
      barKeys: barKeys,
      datas: Object.values(result),
      unit: unit,
      integerPartDigits:
        integerPartDigits > decimalPointDigits
          ? integerPartDigits
          : -decimalPointDigits,
    };
  };

  /**
   * 列毎の最大桁数取得処理
   *
   * @param datas
   * @returns
   */
  const getColumnsDigit = (tableData: CfpTableData) => {
    const maxExponents: Record<string, number> = {};

    if (!tableData || !tableData.data || !tableData.data.length) {
      return maxExponents;
    }

    // 数値を5桁以内に調整する関数
    const getDigit = (num: number) => {
      if (!num && num === 0) return 0;
      const strNum = num.toString().replace(".", ""); // 小数点を除いた長さで考える
      if (strNum.length <= 5) return 0;

      const exponent = strNum.length - 5;
      return exponent;
    };

    const values: number[][] = [];
    const datas = tableData.data;

    datas.forEach((value, i) => {
      values.push([value.cfp_all].concat(value.cfp_values));
      value.children.forEach((child, j) => {
        values.push([child.cfp_all].concat(child.cfp_values));
      });
    });

    values[0].forEach((vals, i) => {
      maxExponents[i] = Math.max(...values.map((row) => getDigit(row[i])));
    });

    return maxExponents;
  };

  return {
    getChartData,
    createChartData,
    getPieChartData,
    createBarLineChartData,
    getColumnsDigit,
  };
};

export default DatamartFeatures;
