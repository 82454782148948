import { useTranslation } from "react-i18next";

const MessageType = () => {
  const { t } = useTranslation();

  const Message = {
    ERROR_NO_INPUT_DATA: t("type.message.errorNoInputData"),
    ERROR_SYSTEM_ERROR: t("type.message.errorSystemError"),
    ERROR_ERROR_RELOGIN: t("type.message.errorErrorRelogin"),
    ERROR_ERROR_PASSWORD_CHANGE: t("type.message.errorErrorPasswordChange"),
    ERROR_NO_RESPONSE_DATA: t("type.message.errorNoResponseData"),
    ERROR_NO_MATCH_PASSWORD: t("type.message.errorNoMatchPassword"),
    ERROR_VALIDATION: t("type.message.errorValidation"),
    ERROR_NO_ID_TOKEN: t("type.message.errorNoIdToken"),
    ERROR_STATUS_403: t("type.message.errorStatus403"),
    ERROR_NO_USRE_DATA: t("type.message.errorNoUsreData"),
    ERROR_NO_SEARCH_DATA: t("type.message.errorNoSearchData"),
    ERROR_NO_ORGANIZATION_DATA: t("type.message.errorNoOrganizationData"),
    ERROR_NO_CREATE_OPEN_DIALOG_DATA: t(
      "type.message.errorNoCreateOpenDialogData"
    ),
    SUCCESS_UPLOAD_FILES: t("type.message.successUploadFiles"),
    SUCCESS_DELETE_FILES: t("type.message.successDeleteFiles"),
    SUCCESS_POST_INQUIRY: t("type.message.successPostInquiry"),
    SUCCESS_PASSWORD_CHANGE: t("type.message.successPasswordChange"),
    SUCCESS_UPDATE_GOAL_VALUE: t("type.message.successUpdateGoalValue"),
    SUCCESS_PUT_OTP: t("type.message.successPutOpt"),
    SUCCESS_PUT_LANGUAGE: t("type.message.successPutLanguage"),
    SUCCESS_CREATE_USER: t("type.message.successCreateUser"),
    SUCCESS_UPDATE_USER: t("type.message.successUpdateUser"),
    SUCCESS_DELETE_USER: t("type.message.successDeleteUser"),
    SUCCESS_PUT_ASSIGN_ORGANIZATION: t(
      "type.message.successAssignOrganization"
    ),
    LOG_OVER_LIMIT: t("type.message.logOverLimit"),
  };

  return Message;
};

export default MessageType;
