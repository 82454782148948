import { Card, CardActionArea, CardContent } from "@mui/material";
import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import classes from "./BackButton.module.scss";

type BackButtonProps = {
  className?: string;
  iconClassName?: string;
  handleClick: React.MouseEventHandler;
};

const BackButton: React.FC<BackButtonProps> = (props) => {
  return (
    <Card className={`${props.className} ${classes.card}`}>
      <CardActionArea onClick={props.handleClick}>
        <CardContent
          className={`${props.iconClassName} ${classes.cardContent}`}
        >
          <ArrowBackIosIcon sx={{ color: "text.secondary" }} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default BackButton;
