import { useContext, useState } from "react";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";
import IPAddressApi from "../features/api/IPAddressApi.tsx";

export const useIPAddress = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);
  const { onCommonError } = ApiCommon();
  const [ipAddressApiLoading, setIpAddressApiLoading] = useState(false);
  const [ipAddress, setIpAddress] = useState<string>("");

  /**
   * グローバルIPアドレス取得API成功時
   */
  const onSuccess = (data) => {
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    setIpAddressApiLoading(false);
  };

  /**
   * グローバルIPアドレス取得API失敗時
   */
  const onError = (error) => {
    onCommonError(error);
    setIpAddressApiLoading(false);
  };

  /**
   * グローバルIPアドレス取得
   */
  const getIpAddressApi = () => {
    setIpAddressApiLoading(true);
    // exeGetIpAddress.mutate();
    setIpAddressApiLoading(false);
    setIpAddress("192.0.0.1");
  };

  // グローバルIPアドレス取得API実行
  const exeGetIpAddress = IPAddressApi.GetGlobalIPAddress(onSuccess, onError);

  return {
    getIpAddressApi,
    ipAddressApiLoading,
    ipAddress,
  };
};
