import React from "react";
import classes from "./SettingOtpLayout.module.scss";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CorporatFilterForm from "../../forms/SettingOtp/CorporatFilterForm.tsx";
import CorporatListForm from "../../forms/SettingOtp/CorporatListForm.tsx";

type SettingOtpLayoutProps = {};

const SettingOtpLayout: React.FC<SettingOtpLayoutProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Box className={classes.optManage}>
      <Typography variant="h5" component="div" className={classes.title}>
        {t("setting.otpSetting.title")}
      </Typography>
      <Box className={classes.corporatFilterForm}>
        {/* 絞り込み用Form */}
        <CorporatFilterForm />
      </Box>
      <Box className={classes.corporatListForm}>
        {/* ユーザー一覧Form */}
        <CorporatListForm />
      </Box>
    </Box>
  );
};

export default SettingOtpLayout;
