import { TextField } from "@mui/material";
import React, { useState } from "react";
import classes from "./IPAddress.module.scss";
import BaseButton from "../Button/BaseButton.tsx";
import { useIPAddress } from "../../../hooks/useIPAddress.tsx";
import { useTranslation } from "react-i18next";

type IPAddressProps = {};

const IPAddress: React.FC<IPAddressProps> = (props) => {
  const { t } = useTranslation();
  const { getIpAddressApi, ipAddressApiLoading, ipAddress } = useIPAddress();

  const handleGetButtonClick = () => {
    getIpAddressApi();
  };

  return (
    <>
      <TextField
        id="text_ip_address"
        value={ipAddress}
        InputProps={{
          readOnly: true,
        }}
      />
      <BaseButton
        label={t("setting.accessControl.getButton")}
        handleClick={handleGetButtonClick}
        className={classes.getButton}
      />
    </>
  );
};

export default IPAddress;
