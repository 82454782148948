import React, { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import classes from "./AccessControlForm.module.scss";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../hooks/useUser.tsx";
import CurrentIPAddressField from "../../fields/SettingAccessControl/CurrentIPAddressField.tsx";
import AllowedIPAddressesField from "../../fields/SettingAccessControl/AllowedIPAddressesField.tsx";
import BaseButton from "../../parts/Button/BaseButton.tsx";

const AccessControlForm = () => {
  const { t } = useTranslation();

  const handleSaveButtonClick = () => {};

  return (
    <>
      <Typography variant="h5" component="div" className={classes.title}>
        {t("setting.accessControl.title")}
      </Typography>
      <Divider />
      <Box sx={{ pl: 1, pt: 1 }}>
        <CurrentIPAddressField />
        <AllowedIPAddressesField />
        <BaseButton
          label={t("setting.accessControl.saveButton")}
          handleClick={handleSaveButtonClick}
          className={classes.saveButton}
        />
      </Box>
    </>
  );
};

export default AccessControlForm;
