import React, { useContext } from "react";
import classes from "./CorporatFilterForm.module.scss";
import { useTranslation } from "react-i18next";
import { Paper, Typography } from "@mui/material";
import InputSearchText from "../../parts/TextField/InputSearchText.tsx";
import SelectItem from "../../parts/Select/SelectItem.tsx";
import SecurityContext from "../../../contexts/SecurityContext.tsx";

const CorporatFilterForm = () => {
  const { t } = useTranslation();
  const securityContext = useContext(SecurityContext);

  const selectOtpValues = [
    {
      value: "all",
      label: t("common.selectAll"),
    },
    {
      value: "true",
      label: "ON",
    },
    {
      value: "false",
      label: "OFF",
    },
  ];

  const inputSearchTextChange = (e: any) => {
    securityContext.setSearchText(e.target.value);
  };
  const selectSelectOtpClick = (e: any) => {
    securityContext.setSearchOpt(e.target.value);
  };

  return (
    <Paper sx={{ display: "flex" }} className={classes.userFilterForm}>
      <Typography variant="body1" component="div" className={classes.title}>
        {t("setting.otpSetting.search.title")}
      </Typography>
      <InputSearchText
        placeholder={t("setting.otpSetting.search.text")}
        className={classes.inputSearchText}
        handleChange={inputSearchTextChange}
      />
      <SelectItem
        title={t("setting.otpSetting.search.otp")}
        values={selectOtpValues}
        selectValue={securityContext.searchOpt}
        handleClick={selectSelectOtpClick}
        className={classes.selectItem}
        classNameSelect={classes.selectItemSelect}
        classNameTitle={classes.selectItemTitle}
      />
    </Paper>
  );
};

export default CorporatFilterForm;
