import { useMutation } from "react-query";
import axios from "axios";
import { ApiCommon } from "../ApiCommon.tsx";
import OperatorLogsResponse from "../../types/response/OperatorLogsResponse.tsx";

const LogApi = () => {
  const { CustomHeaders } = ApiCommon();
  /**
   * ログ情報取得API
   * @param useId
   */
  const PostOperatorLogs = (onSuccess, onError) => {
    return useMutation(
      async (request: { fromDate: string; toDate: string }) => {
        const response = await axios.post<OperatorLogsResponse>(
          process.env.REACT_APP_HOST_URL + "/v1/users/operator-logs",
          {
            from_datetime: request.fromDate,
            to_datetime: request.toDate,
          },
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  return {
    PostOperatorLogs,
  };
};

export default LogApi;
