import React from "react";
import classes from "./SearchButtonForm.module.scss";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import LogButtonLeftField from "../../fields/Log/LogButtonLeftField.tsx";
import LogButtonRightField from "../../fields/Log/LogButtonRightField.tsx";

const SearchButtonForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={1} sx={{ pt: "1vh" }}>
        <Grid xs={8} className={classes.leftGrid}>
          <LogButtonLeftField />
        </Grid>
        <Grid xs={4} className={classes.rightGrid}>
          <LogButtonRightField />
        </Grid>
      </Grid>
    </>
  );
};

export default SearchButtonForm;
