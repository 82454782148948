import React, { useContext, useEffect } from "react";
import classes from "./UsersListForm.module.scss";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddUserDialogForm from "./AddUserDialogForm.tsx";
import { OrganizationUsers } from "../../../types/response/OrganizationUsersResponse.tsx";
import { UserManageFuatures } from "../../../features/UserManageFuatures.tsx";
import UserContext from "../../../contexts/UserContext.tsx";
import { UserInfo } from "../../../types/response/UserInfoResponse.tsx";
import UsersManageContext from "../../../contexts/UsersManageContext.tsx";
import { useUser } from "../../../hooks/useUser.tsx";
import UpdateUserRequest from "../../../types/request/UpdateUserRequest.tsx";
import DeleteUserDialog from "./DeleteUserDialog.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";
import { UserFuatures } from "../../../features/UserFuatures.tsx";

type UsersListFormProps = {
  datas: OrganizationUsers[];
};

const UsersListForm: React.FC<UsersListFormProps> = (props) => {
  const { t } = useTranslation();
  const { isAdministrator } = UserFuatures();
  const { dispChipActive, dispChipRole, isDispEditUserPassword } =
    UserManageFuatures();
  const { putUserApi, deleteUserApi, userApiSuccess, userApiLoading } =
    useUser();
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const usersMagage = useContext(UsersManageContext);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [editUser, setEditUser] = React.useState<OrganizationUsers>();
  const [editOpen, setEditOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditClick = (data: OrganizationUsers) => {
    setEditUser({ ...data });
    setEditOpen(true);
  };
  const handleEditClose = () => {
    setEditOpen(false);
  };
  const handleEditSave = (param: UpdateUserRequest, userId: string) => {
    putUserApi(param, userId);
  };

  /**
   * ユーザー更新処理が正常終了したらダイアログをクローズする
   */
  useEffect(() => {
    if (userApiSuccess) handleEditClose();
  }, [userApiSuccess]);

  const handleDeleteClick = (data: OrganizationUsers) => {
    setEditUser({ ...data });
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  const handleDelete = (param: OrganizationUsers) => {
    deleteUserApi(param.user_id);
    setDeleteOpen(false);
  };

  /**
   * 会社名取得
   * @returns
   */
  const getOrganizationName = () => {
    const organization = userInfo.organizations.find((val) => {
      return val.id === usersMagage.selectSearch;
    });
    return organization ? organization.company_name : "";
  };

  /**
   * 表示ユーザー一覧データ作成処理
   * 絞り込み内容に応じて、表示するユーザー一覧を作成する
   * @returns
   */
  const dispUserList = () => {
    return props.datas
      .filter((data) => {
        return (
          data.last_name.includes(usersMagage.searchText || "") ||
          data.first_name.includes(usersMagage.searchText || "") ||
          data.email.includes(usersMagage.searchText || "")
        );
      })
      .filter((data) => {
        return (
          usersMagage.searchRole === "all" ||
          data.role_id === usersMagage.searchRole
        );
      })
      .filter((data) => {
        return (
          usersMagage.searchActive === "all" ||
          (data.active_flag !== undefined &&
            data.active_flag.toString() === usersMagage.searchActive)
        );
      });
  };

  return (
    <>
      <TableContainer
        className={classes.activeInfoTable}
        sx={{ maxHeight: "55vh" }}
      >
        <Table
          size="small"
          sx={{ tableLayout: "fixed", width: "100%", height: "100%" }}
          stickyHeader
        >
          <TableHead>
            <TableRow key="th">
              <TableCell sx={{ width: "27%" }}>
                {t("setting.users.usersTable.userName")}
              </TableCell>
              <TableCell sx={{ width: "27%" }}>
                {t("setting.users.usersTable.mail")}
              </TableCell>
              <TableCell sx={{ width: "16%" }}>
                {t("setting.users.usersTable.role")}
              </TableCell>
              <TableCell sx={{ width: "10%" }}>
                {t("setting.users.usersTable.active")}
              </TableCell>
              <TableCell sx={{ width: "10%" }}>
                {t("setting.users.usersTable.edit")}
              </TableCell>
              <TableCell sx={{ width: "10%" }}>
                {t("setting.users.usersTable.delete")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ overflowY: "auto" }}>
            {dispUserList().map((data, i) => {
              return (
                <TableRow key={"td_" + i}>
                  <TableCell sx={{ width: "27%" }}>
                    {data.last_name + data.first_name}
                  </TableCell>
                  <TableCell sx={{ width: "27%" }}>{data.email}</TableCell>
                  <TableCell sx={{ width: "16%" }}>
                    {dispChipRole(data.role_id, classes.chipCell)}
                  </TableCell>
                  <TableCell sx={{ width: "10%" }}>
                    {dispChipActive(data.active_flag, classes.chipCell)}
                  </TableCell>
                  <TableCell sx={{ width: "10%" }}>
                    <IconButton
                      onClick={() => handleEditClick(data)}
                      disabled={
                        !isAdministrator() &&
                        !isDispEditUserPassword(data.user_id)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell sx={{ width: "10%" }}>
                    <IconButton
                      onClick={() => handleDeleteClick(data)}
                      disabled={!isAdministrator()}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        labelRowsPerPage={t("common.rowsPerPage")}
        component="div"
        count={dispUserList().length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.tablePagination}
      />
      <AddUserDialogForm
        open={editOpen}
        handleClose={handleEditClose}
        handleSave={handleEditSave}
        customerId={usersMagage.selectSearch}
        customerName={getOrganizationName()}
        editUser={editUser}
        isAdministrator={isAdministrator()}
      />
      <DeleteUserDialog
        handleClose={handleDeleteClose}
        handleExecute={handleDelete}
        editUser={editUser}
        open={deleteOpen}
      />
      {userApiLoading && <CircularProgressDialog />}
    </>
  );
};

export default UsersListForm;
