import React from "react";
import { Box } from "@mui/material";
import classes from "./SearchCheckBoxField.module.scss";
import SearchCheckBox from "../../parts/CheckBox/SearchCheckBox.tsx";
import { CheckBoxType } from "../../../types/SearchCheckBoxType.tsx";

type SearchCheckBoxFieldProps = {
  checkBoxData: { [key: string]: CheckBoxType };
  type: string;
  title: string;
};

const SearchCheckBoxField: React.FC<SearchCheckBoxFieldProps> = (props) => {
  return (
    <>
      <Box className={classes.iconField} sx={{ pl: "1.5vw" }}>
        {props.title}
      </Box>
      <Box className={classes.checkBoxField}>
        <SearchCheckBox datas={props.checkBoxData} type={props.type} />
      </Box>
    </>
  );
};

export default SearchCheckBoxField;
