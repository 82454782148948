import React from "react";
import { Stack, Typography } from "@mui/material";
import classes from "./HelloContentField.module.scss";
import CommonUtils from "../../../common/utils/CommonUtils.tsx";
import { useTranslation } from "react-i18next";
import { UserFuatures } from "../../../features/UserFuatures.tsx";

const HelloContentField = () => {
  const { t, i18n } = useTranslation();
  const { getUserName } = UserFuatures();
  return (
    <>
      <Stack>
        <Typography variant="body1" component="div" className={classes.date}>
          {i18n.language === "ja"
            ? CommonUtils.formatDateMMDD_day(new Date())
            : CommonUtils.formatDateMMDD_day_EN(new Date())}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="h6" component="div" className={classes.hello}>
          {`${getUserName()} ${t("top.hello")}`}
        </Typography>
      </Stack>
    </>
  );
};

export default HelloContentField;
