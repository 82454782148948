import { useContext, useState } from "react";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import InquiryApi from "../features/api/InquiryApi.tsx";
import InquiriesRequest from "../types/request/InquiriesRequest.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";

export const useInquiry = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);
  const { onCommonError } = ApiCommon();
  const [inquiryLoading, setInquiryLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const { PostInquiry } = InquiryApi();

  /**
   * お問い合わせ送信API成功時
   */
  const onSuccess = (data) => {
    setInquiryLoading(false);
    appContext?.setSuccessOpen(true);
    appContext?.setAlertMessage(Message.SUCCESS_POST_INQUIRY);
    setTitle("");
    setContent("");
  };

  /**
   * お問い合わせ送信API失敗時
   */
  const onError = (error) => {
    onCommonError(error);
    setInquiryLoading(false);
  };

  /**
   * お問い合わせ送信
   */
  const postInquiryApi = () => {
    setInquiryLoading(true);
    exePostInquiry.refetch();
  };

  const getRequest = () => {
    return {
      title: title,
      content: content,
    } as InquiriesRequest;
  };

  // お問い合わせ送信API実行
  const exePostInquiry = PostInquiry(getRequest(), onSuccess, onError);

  return {
    title,
    setTitle,
    content,
    setContent,
    postInquiryApi,
    inquiryLoading,
  };
};
