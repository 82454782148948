import { useQuery } from "react-query";
import axios from "axios";
import { ApiCommon } from "../ApiCommon.tsx";

const ResetPasswordApi = () => {
  const { CustomHeader } = ApiCommon();
  /**
   * パスワード再設定依頼メールAPI
   * @param email
   */
  const ResetMail = (email: string, onSuccess, onError) => {
    return useQuery(
      "resetMail",
      async () => {
        const response = await axios.post(
          process.env.REACT_APP_HOST_URL + "/v1/auth/password_reset/request",
          {
            email: email,
          },
          {
            headers: CustomHeader(),
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  /**
   * パスワード再設定API
   * @param email
   * @param reset_token
   * @param new_password
   */
  const ResetPassword = (
    email: string,
    reset_token: string,
    new_password: string,
    onSuccess,
    onError
  ) => {
    return useQuery(
      "resetPassword",
      async () => {
        const response = await axios.post(
          process.env.REACT_APP_HOST_URL + "/v1/auth/password_reset/update",
          {
            email: email,
            reset_token: reset_token,
            new_password: new_password,
          },
          {
            headers: CustomHeader(),
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  return { ResetMail, ResetPassword };
};

export default ResetPasswordApi;
