import { useContext, useState } from "react";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";
import LanguageRequest from "../types/request/LanguageRequest.tsx";
import OrganizationApi from "../features/api/OrganizationApi.tsx";
import { OrganizationUsers } from "../types/response/OrganizationUsersResponse.tsx";
import UsersManageContext from "../contexts/UsersManageContext.tsx";

export const useOrganization = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);
  const usersMagage = useContext(UsersManageContext);
  const { onCommonError } = ApiCommon();
  const { GetUsers } = OrganizationApi();
  const [organizationApiLoading, setOrganizationApiLoading] = useState(false);

  /**
   * ユーザー一覧取得API失敗時
   */
  const onError = (error) => {
    onCommonError(error);
    setOrganizationApiLoading(false);
  };

  /**
   * ユーザー一覧取得API成功時
   */
  const onGetUsersSuccess = (
    data: OrganizationUsers[],
    variables: LanguageRequest
  ) => {
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    setOrganizationApiLoading(false);
    usersMagage.setOrganizationUsers([...data]);
  };

  /**
   * ユーザー一覧取得
   */
  const getUsersApi = (organizationId: string) => {
    if (!appContext?.idToken.sub) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_SYSTEM_ERROR);
      return;
    }
    setOrganizationApiLoading(true);
    exeGetUsers.mutate(organizationId);
  };

  // ユーザー一覧取得API実行
  const exeGetUsers = GetUsers(onGetUsersSuccess, onError);

  return {
    organizationApiLoading,
    getUsersApi,
  };
};
