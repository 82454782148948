import React, { useState } from "react";
import { Box } from "@mui/material";
import classes from "./SearchTextCheckBoxField.module.scss";
import SearchCheckBox from "../../parts/CheckBox/SearchCheckBox.tsx";
import { CheckBoxType } from "../../../types/SearchCheckBoxType.tsx";
import InputSearchText from "../../parts/TextField/InputSearchText.tsx";

type SearchTextCheckBoxFieldProps = {
  checkBoxData: { [key: string]: CheckBoxType };
  type: string;
  title: string;
};

const SearchTextCheckBoxField: React.FC<SearchTextCheckBoxFieldProps> = (
  props
) => {
  const [searchText, setSearchText] = useState<string>("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  /**
   * 検索項目に記入された内容を含むチェックボックスを画面上に表示する
   * ただし、検索項目が未記入の場合は、全て表示する
   * @returns
   */
  const dispCheckBox = () => {
    if (!searchText) return props.checkBoxData;
    const dispData: { [key: string]: CheckBoxType } = {};
    Object.keys(props.checkBoxData).forEach((key) => {
      if (props.checkBoxData[key].label.includes(searchText)) {
        dispData[key] = props.checkBoxData[key];
      }
    });
    return dispData;
  };

  return (
    <>
      <Box className={classes.iconField} sx={{ pl: "1.5vw" }}>
        {props.title}
      </Box>
      <Box className={classes.searchField}>
        <InputSearchText
          placeholder=""
          handleChange={handleSearch}
          className={classes.inputSearchText}
        />
      </Box>
      <Box className={classes.checkBoxField}>
        <SearchCheckBox datas={dispCheckBox()} type={props.type} />
      </Box>
    </>
  );
};

export default SearchTextCheckBoxField;
