import { useMutation } from "react-query";
import axios from "axios";
import PrepareCreateAccountsResponse from "../../types/response/PrepareCreateAccountResponse";
import PrepareCreateAccountRequest from "../../types/request/PrepareCreateAccountRequest";
import { ApiCommon } from "../ApiCommon.tsx";

const AccountCreateApi = () => {
  const { CustomHeaders } = ApiCommon();
  /**
   * アカウント発行準備API
   */
  const PrepareCreateAccounts = (onSuccess, onError) => {
    return useMutation(
      async (request: PrepareCreateAccountRequest) => {
        if (
          !(
            request.userFile &&
            request.organizationFile &&
            request.calendarFile &&
            request.contractFile
          )
        ) {
          return Promise.reject(new Error("Invalid parameters"));
        }
        const formData = new FormData();
        formData.append("user_input_file", request.userFile);
        formData.append("organization_input_file", request.organizationFile);
        formData.append("calendar_input_file", request.calendarFile);
        formData.append("contract_input_file", request.contractFile);

        const response = await axios.post<PrepareCreateAccountsResponse>(
          process.env.REACT_APP_HOST_URL + "/v1/admin/accounts/temp",
          formData,
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  /**
   * アカウント発行API
   */
  const CreateAccounts = (onSuccess, onError) => {
    return useMutation(
      async (mailIdList: string[]) => {
        const response = await axios.post<boolean>(
          process.env.REACT_APP_HOST_URL + "/v1/admin/accounts",
          { email_id_list: mailIdList },
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  /**
   * 一時保存アカウント削除API
   */
  const DeleteAccounts = (onSuccess, onError) => {
    return useMutation(
      async (mailIdList: string[]) => {
        const response = await axios.delete<boolean>(
          process.env.REACT_APP_HOST_URL + "/v1/admin/accounts/temp",
          {
            data: { email_id_list: mailIdList },
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  return { PrepareCreateAccounts, CreateAccounts, DeleteAccounts };
};

export default AccountCreateApi;
