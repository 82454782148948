import { useContext, useState } from "react";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";
import { ExternalLinks } from "../types/response/ExternalLinksResponse.tsx";
import LinksApi from "../features/api/LinksApi.tsx";

export const useLinks = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);
  const { onCommonError } = ApiCommon();
  const { GetLinks, GetLinksRedirect } = LinksApi();
  const [linksApiLoading, setLinksApiLoading] = useState(true);
  const [datas, setDatas] = useState<ExternalLinks[]>([]);

  /**
   * リンク取得API成功時
   */
  const onSuccess = (data: ExternalLinks[]) => {
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    setDatas(data);
    setLinksApiLoading(false);
  };

  /**
   * リンク取得API失敗時
   */
  const onError = (error) => {
    onCommonError(error);
    setLinksApiLoading(false);
  };

  /**
   * リンク取得API
   */
  const getLinksApi = async () => {
    setLinksApiLoading(true);
    exeGetLinks.mutate();
  };

  /**
   * リンクリダイレクトPI成功時
   */
  const onSuccessRedirect = () => {
    setLinksApiLoading(false);
  };

  /**
   * リンクリダイレクトAPI
   */
  const getLinksRedirectApi = async (url: string) => {
    setLinksApiLoading(true);
    exeGetLinksRedirect.mutate(url);
  };

  // リンク取得API実行
  const exeGetLinks = GetLinks(onSuccess, onError);

  // リンクリダイレクトAPI実行
  const exeGetLinksRedirect = GetLinksRedirect(onSuccessRedirect, onError);

  return {
    getLinksApi,
    getLinksRedirectApi,
    linksApiLoading,
    setLinksApiLoading,
    datas,
  };
};
