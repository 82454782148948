import React from "react";
import ContentLayout from "../../layouts/Common/ContentLayout.tsx";
import { LogProvider } from "../../../contexts/LogContext.tsx";
import LogLayout from "../../layouts/Log/LogLayout.tsx";

const LogPage = () => {
  return (
    <LogProvider>
      <ContentLayout>
        <LogLayout />
      </ContentLayout>
    </LogProvider>
  );
};

export default LogPage;
