import React from "react";
import ContentLayout from "../../layouts/Common/ContentLayout.tsx";
import ChangePasswordLayout from "../../layouts/SettingChangePassword/ChangePasswordLayout.tsx";

const ChangePasswordPage = () => {
  return (
    <ContentLayout>
      <ChangePasswordLayout />
    </ContentLayout>
  );
};

export default ChangePasswordPage;
