import React, { useEffect } from "react";
import ExternalLinksForm from "../../forms/ExternalLinks/ExternalLinksForm.tsx";
import classes from "./ExternalLinksLayout.module.scss";
import { ListItem, Stack } from "@mui/material";
import { useLinks } from "../../../hooks/useLinks.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";

type ExternalLinksLayoutProps = {};

const ExternalLinksLayout: React.FC<ExternalLinksLayoutProps> = (props) => {
  const { getLinksApi, linksApiLoading, datas } = useLinks();

  useEffect(() => {
    getLinksApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      spacing={1}
      sx={{ width: "100%", overflowY: "auto", height: "100%" }}
    >
      {!linksApiLoading &&
        datas.map((data, i) => {
          return (
            <ListItem className={classes.listItem}>
              <ExternalLinksForm data={data} />
            </ListItem>
          );
        })}

      {linksApiLoading && <CircularProgressDialog />}
    </Stack>
  );
};

export default ExternalLinksLayout;
