import React, { useContext, useEffect, useState } from "react";
import classes from "./UsersButtonForm.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import { Box, Grid, SelectChangeEvent } from "@mui/material";
import SelectItem from "../../parts/Select/SelectItem.tsx";
import UsersButtonLeftField from "../../fields/SettingUsers/UsersButtonLeftField.tsx";
import UsersButtonRightField from "../../fields/SettingUsers/UsersButtonRightField.tsx";
import UsersManageContext from "../../../contexts/UsersManageContext.tsx";
import CusotmerButtonRightField from "../../fields/SettingUsers/CusotmerButtonRightField.tsx";
import { UsersManageMode } from "../../../types/enum/UsersManageEnum.tsx";
import UserContext from "../../../contexts/UserContext.tsx";
import { UserInfo } from "../../../types/response/UserInfoResponse.tsx";
import SelectItemType from "../../../types/SelectItemType.tsx";
import { useOrganization } from "../../../hooks/useOrganization.tsx";
import { useUser } from "../../../hooks/useUser.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";

const UsersButtonForm = () => {
  const { t } = useTranslation();
  const { getOrganizationsApi } = useUser();
  const { getUsersApi, organizationApiLoading } = useOrganization();
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const usersMagage = useContext(UsersManageContext);
  const mode = usersMagage.mode || UsersManageMode.users;
  const [selectValues, setSelectValues] = useState<SelectItemType[]>([]);

  useEffect(() => {
    if (!userInfo) return;
    const values: SelectItemType[] = [];
    if (usersMagage.mode === UsersManageMode.users && userInfo.organizations) {
      // 選択リストを顧客一覧にし、最初の顧客IDにてユーザー一覧情報を取得する
      userInfo.organizations.forEach((organization, i) => {
        if (i === 0) {
          usersMagage.setSelectSearch(organization.id);
          getUsersApi(organization.id);
        }
        values.push({
          value: organization.id,
          label: organization.office_name,
        });
      });
    } else if (
      usersMagage.mode === UsersManageMode.customers &&
      userInfo.users
    ) {
      // 選択リストをユーザー一覧にし、最初のユーザーIDにて顧客一覧情報を取得する
      userInfo.users.forEach((user, i) => {
        if (i === 0) {
          usersMagage.setSelectSearch(user.user_id);
          getOrganizationsApi(user.user_id);
        }

        values.push({
          value: user.user_id,
          label: user.last_name + user.first_name,
        });
      });
    }
    setSelectValues(values);
  }, [usersMagage.mode]);

  /**
   * ユーザーor顧客選択処理
   * @param e
   */
  const selectSelectClick = (e: SelectChangeEvent<string>) => {
    const value = e.target.value;
    usersMagage.setSelectSearch(value);
    if (usersMagage.mode === UsersManageMode.users) {
      getUsersApi(value);
    } else if (usersMagage.mode === UsersManageMode.customers) {
      getOrganizationsApi(value);
    }
  };

  return (
    <>
      <Box className={classes.selectItemBox}>
        <SelectItem
          title={
            mode === UsersManageMode.users
              ? t("setting.users.selectTitle")
              : t("setting.customers.selectTitle")
          }
          values={selectValues}
          selectValue={usersMagage.selectSearch}
          handleClick={selectSelectClick}
          classNameSelect={classes.selectItem}
          classNameTitle={classes.selectItemTitle}
        />
      </Box>
      <Grid container spacing={1} sx={{ pt: "1vh" }}>
        <Grid xs={6} className={classes.leftGrid}>
          <UsersButtonLeftField />
        </Grid>
        <Grid xs={6} className={classes.rightGrid}>
          <AnimatePresence mode="wait">
            <motion.div
              key={mode === UsersManageMode.users ? "water2" : "water1"}
              className="table"
            >
              {mode === UsersManageMode.users && (
                <motion.div
                  key={UsersManageMode.users}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.2 }}
                >
                  <UsersButtonRightField />
                </motion.div>
              )}
              {mode === UsersManageMode.customers && (
                <motion.div
                  key={UsersManageMode.customers}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.2 }}
                >
                  <CusotmerButtonRightField />
                </motion.div>
              )}
            </motion.div>
          </AnimatePresence>
        </Grid>
      </Grid>
      {organizationApiLoading && <CircularProgressDialog />}
    </>
  );
};

export default UsersButtonForm;
