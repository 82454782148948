import React from "react";
import ContentLayout from "../../layouts/Common/ContentLayout.tsx";
import UsersManageLayout from "../../layouts/SettingUsers/UsersManageLayout.tsx";
import { UsersManageProvider } from "../../../contexts/UsersManageContext.tsx";

const SettingUsersPage = () => {
  return (
    <UsersManageProvider>
      <ContentLayout>
        <UsersManageLayout />
      </ContentLayout>
    </UsersManageProvider>
  );
};

export default SettingUsersPage;
