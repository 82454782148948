import { useContext } from "react";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";

export const ApiCommon = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);

  /**
   * API共通エラー処理
   */
  const onCommonError = (error, message?) => {
    const response = error.response;
    const errorMessage = message ? message : Message.ERROR_SYSTEM_ERROR;
    if (response && response.data && response.data.detail) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(response.data.detail);
      return;
    }
    appContext?.setErrorOpen(true);
    appContext?.setAlertMessage(errorMessage);
    return;
  };

  const CustomHeader = () => {
    return {
      "client-global-ip": appContext?.globalIP,
    };
  };

  const CustomHeaders = () => {
    return {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      "client-global-ip": appContext?.globalIP,
    };
  };

  const CustomHeadersFormData = () => {
    return {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      "Content-Type": "multipart/form-data",
      "client-global-ip": appContext?.globalIP,
    };
  };

  const CustomHeadersOctetStream = () => {
    return {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      Accept: "application/octet-stream",
      "client-global-ip": appContext?.globalIP,
    };
  };

  return {
    onCommonError,
    CustomHeader,
    CustomHeaders,
    CustomHeadersFormData,
    CustomHeadersOctetStream,
  };
};
