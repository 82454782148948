import React, { useEffect, useState } from "react";
import classes from "./ToggleSwitch.module.scss";
import { FormGroup, Stack, Switch, Typography } from "@mui/material";

type ToggleSwitchProps = {
  leftLabel: string;
  rightLabel: string;
  defaultChecked: boolean;
  handleChange: React.MouseEventHandler;
  switchClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
};

const ToggleSwitch: React.FC<ToggleSwitchProps> = (props) => {
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    setChecked(props.defaultChecked);
  }, [props.defaultChecked]);

  const handleChange = (e) => {
    setChecked(e.target.checked);
    props.handleChange(e);
  };

  return (
    <FormGroup>
      <Stack
        direction="row"
        className={`${classes.switchLabel} ${props.labelClassName}`}
        spacing={1}
        sx={{ alignItems: "center" }}
      >
        <Typography>{props.leftLabel}</Typography>
        <Switch
          className={`${classes.switch} ${props.switchClassName}`}
          checked={checked}
          onChange={handleChange}
          disabled={props.disabled ? true : false}
        />
        <Typography>{props.rightLabel}</Typography>
      </Stack>
    </FormGroup>
  );
};

export default ToggleSwitch;
