import React, { useContext, useEffect, useRef } from "react";
import { Accordion, AccordionDetails, Container } from "@mui/material";
import classes from "./SearchAccordionField.module.scss";
import AppContext from "../../../contexts/AppContext.tsx";
import SearchOrganizationField from "./SearchOrganizationField.tsx";
import SearchSummaryField from "./SearchSummaryField.tsx";
import SearchProductField from "./SearchProductField.tsx";
import SearchFacilityField from "./SearchFacilityField.tsx";
import SearchRenewableEnergyField from "./SearchRenewableEnergyField.tsx";

type SearchAccordionFieldProps = {
  className: string;
  isSearchOpen: boolean;
  handleCloseSearch: React.MouseEventHandler;
};

const SearchAccordionField: React.FC<SearchAccordionFieldProps> = (props) => {
  const appContext = useContext(AppContext);
  const accordionRef = useRef(null);
  const handleClick = (event) => {
    if (
      accordionRef.current &&
      !accordionRef.current.contains(event.target) &&
      (!document.getElementsByClassName("react-datepicker-popper").length ||
        !document
          .getElementsByClassName("react-datepicker-popper")[0]
          .contains(event.target)) &&
      (!document.getElementsByClassName("MuiPopover-paper").length ||
        !document
          .getElementsByClassName("MuiPopover-paper")[0]
          .contains(event.target)) &&
      (!document.getElementsByClassName("MuiBackdrop-root").length ||
        !document
          .getElementsByClassName("MuiBackdrop-root")[0]
          .contains(event.target)) &&
      document.getElementById("searchingButton") &&
      !document.getElementById("searchingButton").contains(event.target)
    ) {
      props.handleCloseSearch(event);
    }
  };

  useEffect(() => {
    if (props.isSearchOpen) {
      document.addEventListener("mousedown", handleClick);
    } else {
      document.removeEventListener("mousedown", handleClick);
    }
  }, [props.isSearchOpen]);

  const dispSearchField = () => {
    switch (appContext?.sideItem) {
      case "company":
        return <SearchOrganizationField />;
      case "summary":
        return <SearchSummaryField />;
      case "product":
        return <SearchProductField />;
      case "facility":
        return <SearchFacilityField />;
      case "renewable_energy":
        return <SearchRenewableEnergyField />;
      default:
        return "";
    }
  };

  return (
    <Container className={props.className} ref={accordionRef}>
      <Accordion
        className={classes.accordion}
        style={{ width: "100%", height: "50vh" }}
        expanded={props.isSearchOpen}
      >
        <AccordionDetails className={classes.searchField}>
          {dispSearchField()}
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default SearchAccordionField;
