import React, { useContext } from "react";
import classes from "./LogButtonRightField.module.scss";
import { useTranslation } from "react-i18next";
import { IconButton, Typography } from "@mui/material";
import { ReactComponent as CsvImage } from "../../../assets/images/Setting/icon_csv.svg";
import { useFile } from "../../../hooks/useFile.tsx";
import LogContext from "../../../contexts/LogContext.tsx";
import CommonUtils from "../../../common/utils/CommonUtils.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";

const LogButtonRightField = () => {
  const { t } = useTranslation();
  const logContext = useContext(LogContext);
  const { downloadOperatorLogs, isLoading } = useFile();

  const handleDownloadCsvClick = () => {
    downloadOperatorLogs(
      CommonUtils.formatDayYYYYMMDDhhmm(logContext.searchFromDate, true),
      CommonUtils.formatDayYYYYMMDDhhmm(logContext.searchToDate, true)
    );
  };

  return (
    <>
      <IconButton
        onClick={handleDownloadCsvClick}
        className={classes.csvButton}
      >
        <CsvImage />
        <Typography
          variant="body1"
          component="div"
          sx={{ flexGrow: 1 }}
          className={classes.iconTitle}
        >
          {t("common.exxport")}
        </Typography>
      </IconButton>
      {isLoading && <CircularProgressDialog />}
    </>
  );
};

export default LogButtonRightField;
