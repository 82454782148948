import React from "react";
import classes from "./ImageTitleSubCardField.module.scss";
import BackButton from "../../parts/Button/BackButton.tsx";
import ForwardButton from "../../parts/Button/ForwardButton.tsx";
import ImageTitleSubCard from "../../parts/Card/ImageTitleSubCard.tsx";
import { LinkInfo } from "../../../types/response/ExternalLinksResponse.tsx";

type ImageTitleSubCardFieldProps = {
  data: LinkInfo[];
  handleScroll: (direction: "left" | "right") => void;
  isLeftVisible: boolean;
  isRightVisible: boolean;
  isHovered: boolean;
};

const ImageTitleSubCardField: React.FC<ImageTitleSubCardFieldProps> = (
  props
) => {
  const handleBackClick = () => {
    props.handleScroll("left");
  };

  const handleForwardClick = () => {
    props.handleScroll("right");
  };

  return (
    <>
      {props.isLeftVisible && props.isHovered && (
        <BackButton
          className={classes.backButton}
          iconClassName={classes.backButtonContent}
          handleClick={handleBackClick}
        />
      )}
      {props.data.map((item, i) => (
        <ImageTitleSubCard
          image={item.image}
          title={item.title}
          subTitle={item.explanation}
          url={item.url}
          cardClass={classes.cardClass}
          cardContentClass={classes.cardContentClass}
          titleClass={classes.titleClass}
          subTitleClass={classes.subTitleClass}
        />
      ))}
      {props.isRightVisible && props.isHovered && (
        <ForwardButton
          className={classes.forwardButton}
          iconClassName={classes.forwardButtonContent}
          handleClick={handleForwardClick}
        />
      )}
    </>
  );
};

export default ImageTitleSubCardField;
