import React from "react";
import "./App.scss";
import "react-datepicker/dist/react-datepicker.css";
import { Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import DataInputPage from "./econipass/components/pages/DataInputPage/index.tsx";
import CompanyPage from "./econipass/components/pages/CompanyPage/index.tsx";
import CompanyFreeAnalysisPage from "./econipass/components/pages/CompanyFreeAnalysisPage/index.tsx";
import SummaryPage from "./econipass/components/pages/SummaryPage/index.tsx";
import ProductPage from "./econipass/components/pages/ProductPage/index.tsx";
import FacilityPage from "./econipass/components/pages/FacilityPage/index.tsx";
import ProductFreeAnalysisPage from "./econipass/components/pages/ProductFreeAnalysisPage/index.tsx";
import WarmingCountermeasuresPage from "./econipass/components/pages/WarmingCountermeasuresPage/index.tsx";
import EnergySavingPage from "./econipass/components/pages/EnergySavingPage/index.tsx";
import { DatamartSearchProvider } from "./econipass/contexts/DatamartSearchContext.tsx";
import HelpPage from "./econipass/components/pages/HelpPage/index.tsx";
import InquiryPage from "./econipass/components/pages/InquiryPage/index.tsx";

import LoginPage from "./econipass/components/pages/LoginPage/index.tsx";
import ResetMailPage from "./econipass/components/pages/ResetMailPage/index.tsx";
import ResetPasswordPage from "./econipass/components/pages/ResetPasswordPage/index.tsx";
import AccessControlPage from "./econipass/components/pages/SettingAccessControlPage/index.tsx";
import LanguagePage from "./econipass/components/pages/SettingLanguagePage/index.tsx";
import SettingOtpPage from "./econipass/components/pages/SettingOtpPage/index.tsx";
import ChangePasswordPage from "./econipass/components/pages/SettingChangePasswordPage/index.tsx";
import SettingUsersPage from "./econipass/components/pages/SettingUsersPage/index.tsx";
import TopPage from "./econipass/components/pages/TopPage/index.tsx";
import RootPage from "./econipass/components/pages/RootPage/index.tsx";
import { LoginProvider } from "./econipass/contexts/LoginContext.tsx";
import { AppProvider } from "./econipass/contexts/AppContext.tsx";
import { MasterProvider } from "./econipass/contexts/MasterContext.tsx";
import { UserProvider } from "./econipass/contexts/UserContext.tsx";

import AdminLoginPage from "./econipass_management/components/pages/LoginPage/index.tsx";
import AdminAccountCreatePage from "./econipass_management/components/pages/AccountCreatePage/index.tsx";
import AdminNoticeManagementPage from "./econipass_management/components/pages/NoticeManagementPage/index.tsx";
import AdminNoticeCreatePage from "./econipass_management/components/pages/NoticeCreatePage/index.tsx";
import AdminRootPage from "./econipass_management/components/pages/RootPage/index.tsx";
import { AdminAppProvider } from "./econipass_management/contexts/AppContext.tsx";
import { AdminLoginProvider } from "./econipass_management/contexts/LoginContext.tsx";
import { AdminUserProvider } from "./econipass_management/contexts/UserContext.tsx";
import ExternalLinksPage from "./econipass/components/pages/ExternalLinksPage/index.tsx";
import LogPage from "./econipass/components/pages/LogPage/index.tsx";
// import RenewableEnergyPage from "./econipass/components/pages/RenewableEnergyPage/index.tsx";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <LoginProvider>
          <UserProvider>
            <MasterProvider>
              <DatamartSearchProvider>
                <AdminAppProvider>
                  <AdminLoginProvider>
                    <AdminUserProvider>
                      <div className="App" style={{ height: "100vh" }}>
                        <Routes>
                          <Route path="/" element={<RootPage />} />
                          <Route path="/login" element={<LoginPage />} />
                          <Route
                            path="/reset-mail"
                            element={<ResetMailPage />}
                          />
                          <Route
                            path="/reset-password"
                            element={<ResetPasswordPage />}
                          />
                          <Route path="/home" element={<TopPage />} />
                          <Route path="/language" element={<LanguagePage />} />
                          <Route
                            path="/change_password"
                            element={<ChangePasswordPage />}
                          />
                          <Route
                            path="/setting_otp"
                            element={<SettingOtpPage />}
                          />
                          <Route
                            path="/users_manage"
                            element={<SettingUsersPage />}
                          />
                          <Route
                            path="/access_control"
                            element={<AccessControlPage />}
                          />
                          <Route path="/help" element={<HelpPage />} />
                          <Route path="/inquiry" element={<InquiryPage />} />
                          <Route
                            path="/data-input"
                            element={<DataInputPage />}
                          />
                          <Route path="/company" element={<CompanyPage />} />
                          <Route
                            path="/company_free_analysis"
                            element={<CompanyFreeAnalysisPage />}
                          />
                          <Route path="/summary" element={<SummaryPage />} />
                          <Route path="/product" element={<ProductPage />} />
                          <Route path="/facility" element={<FacilityPage />} />
                          <Route
                            path="/product_free_analysis"
                            element={<ProductFreeAnalysisPage />}
                          />
                          <Route
                            path="/energy_saving"
                            element={<EnergySavingPage />}
                          />
                          <Route
                            path="/warming_countermeasures"
                            element={<WarmingCountermeasuresPage />}
                          />
                          <Route
                            path="/external_links"
                            element={<ExternalLinksPage />}
                          />
                          <Route path="/logs" element={<LogPage />} />
                          {/* <Route
                            path="/renewable_energy"
                            element={<RenewableEnergyPage />}
                          /> */}
                          <Route path="/admin" element={<AdminRootPage />} />
                          <Route
                            path="/admin/login"
                            element={<AdminLoginPage />}
                          />
                          <Route
                            path="/admin/account-create"
                            element={<AdminAccountCreatePage />}
                          />
                          <Route
                            path="/admin/notice-management"
                            element={<AdminNoticeManagementPage />}
                          />
                          <Route
                            path="/admin/notice-create"
                            element={<AdminNoticeCreatePage />}
                          />
                        </Routes>
                      </div>
                    </AdminUserProvider>
                  </AdminLoginProvider>
                </AdminAppProvider>
              </DatamartSearchProvider>
            </MasterProvider>
          </UserProvider>
        </LoginProvider>
      </AppProvider>
    </QueryClientProvider>
  );
}

export default App;
