import React from "react";
import ContentLayout from "../../layouts/Common/ContentLayout.tsx";
import SettingAccessControlLayout from "../../layouts/SettingAccessControl/SettingAccessControlLayout.tsx";

const AccessControlPage = () => {
  return (
    <ContentLayout>
      <SettingAccessControlLayout />
    </ContentLayout>
  );
};

export default AccessControlPage;
