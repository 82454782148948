import React, { useContext } from "react";
import classes from "./UsersManageLayout.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import UsersButtonForm from "../../forms/SettingUsers/UsersButtonForm.tsx";
import UsersFilterForm from "../../forms/SettingUsers/UsersFilterForm.tsx";
import UsersListForm from "../../forms/SettingUsers/UsersListForm.tsx";
import UsersManageContext from "../../../contexts/UsersManageContext.tsx";
import CusomerListForm from "../../forms/SettingUsers/CusomerListForm.tsx";
import { UsersManageMode } from "../../../types/enum/UsersManageEnum.tsx";

type UsersManageLayoutProps = {};

const UsersManageLayout: React.FC<UsersManageLayoutProps> = (props) => {
  const { t } = useTranslation();
  const usersMagage = useContext(UsersManageContext);
  const mode = usersMagage.mode || UsersManageMode.users;

  return (
    <Box className={classes.usersManage}>
      <Typography variant="h5" component="div" className={classes.title}>
        {t("setting.users.title")}
      </Typography>
      <Box className={classes.usersButtonForm}>
        {/* ユーザー追加などのボタンForm */}
        <UsersButtonForm />
      </Box>

      <AnimatePresence mode="wait">
        <motion.div
          key={mode === UsersManageMode.users ? "water2" : "water1"}
          className="table"
        >
          {mode === UsersManageMode.users && (
            <motion.div
              key={UsersManageMode.users}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.2 }}
            >
              <Box className={classes.usersFilterForm}>
                {/* 絞り込み用Form */}
                <UsersFilterForm />
              </Box>
              <Box className={classes.usersListForm}>
                {/* ユーザー一覧Form */}
                <UsersListForm datas={usersMagage.organizationUsers} />
              </Box>
            </motion.div>
          )}
          {mode === UsersManageMode.customers && (
            <motion.div
              key={UsersManageMode.customers}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.2 }}
            >
              <Box className={classes.usersFilterForm}>
                {/* 絞り込み用Form */}
                <UsersFilterForm />
              </Box>
              <Box className={classes.usersListForm}>
                {/* ユーザー一覧Form */}
                <CusomerListForm datas={usersMagage.organizations} />
              </Box>
            </motion.div>
          )}
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};

export default UsersManageLayout;
