import React, { useContext } from "react";
import { AppBar, Box, CssBaseline, Toolbar } from "@mui/material";
import classes from "./HeaderForm.module.scss";
import AppContext from "../../../contexts/AppContext.tsx";
import HelpField from "../../fields/Header/HelpField.tsx";
import SettingField from "../../fields/Header/SettingField.tsx";
import UserField from "../../fields/Header/UserField.tsx";
import SearchField from "../../fields/Header/SearchField.tsx";
import { DispSearchItem } from "../../../types/SideMenus.tsx";
import SearchAccordionField from "../../fields/Header/SearchAccordionField.tsx";
import ReportField from "../../fields/Header/ReportField.tsx";
import DatamartSearchContext from "../../../contexts/DatamartSearchContext.tsx";
import ManualField from "../../fields/Header/ManualField.tsx";
import FullScreenField from "../../fields/Header/FullScreenField.tsx";
import LinksField from "../../fields/Header/LinksField.tsx";
import {
  SettingSideMenuEnum,
  SideMenuEnum,
} from "../../../types/enum/SideMenuEnum.tsx";

const HeaderForm = () => {
  const appContext = useContext(AppContext);
  const searchContext = useContext(DatamartSearchContext);

  const handleOpenSearch = (event) => {
    searchContext.setSearchOpen(!searchContext.searchOpen);
  };

  const handleCloseSearch = (event) => {
    searchContext.setSearchOpen(false);
  };

  return (
    <>
      <CssBaseline />
      <AppBar
        className={`${classes.appBar} ${
          appContext?.sideOpen ? classes.openSide : classes.notOpenSide
        }`}
      >
        <Toolbar
          className={`${classes.toolBar} ${
            !SettingSideMenuEnum[appContext?.sideItem || ""]
              ? classes.normalHeader
              : classes.settingHeader
          }`}
        >
          {DispSearchItem.includes(
            (appContext?.sideItem as SideMenuEnum) || ""
          ) && (
            <Box className={classes.headerLeft}>
              <SearchField
                isSearchOpen={searchContext.searchOpen}
                handleClick={handleOpenSearch}
              />
            </Box>
          )}
          <Box className={classes.headerRight}>
            {!SettingSideMenuEnum[appContext?.sideItem || ""] && (
              <>
                <ManualField />
                {DispSearchItem.includes(
                  (appContext?.sideItem as SideMenuEnum) || ""
                ) && (
                  <>
                    <FullScreenField />
                    <ReportField />
                  </>
                )}
                <HelpField />
                <SettingField />
              </>
            )}
            <UserField />
          </Box>
        </Toolbar>
      </AppBar>
      {searchContext.searchOpen && (
        <SearchAccordionField
          className={`${classes.appBar} ${
            appContext?.sideOpen
              ? classes.openSideAccordion
              : classes.notOpenSideAccordion
          }`}
          isSearchOpen={searchContext.searchOpen}
          handleCloseSearch={handleCloseSearch}
        />
      )}
    </>
  );
};

export default HeaderForm;
