import React, { useContext, useEffect, useState } from "react";
import classes from "./CusotmerButtonRightField.module.scss";
import { useTranslation } from "react-i18next";
import BaseButton from "../../parts/Button/BaseButton.tsx";
import AssignCustomerDialogForm from "../../forms/SettingUsers/AssignCustomerDialogForm.tsx";
import UsersManageContext from "../../../contexts/UsersManageContext.tsx";
import { AssignOrganization } from "../../../types/request/AssignOrganizationRequest.tsx";
import { useUser } from "../../../hooks/useUser.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";
import { UserFuatures } from "../../../features/UserFuatures.tsx";

const CusotmerButtonRightField = () => {
  const { t } = useTranslation();
  const usersMagage = useContext(UsersManageContext);
  const { isAdministrator } = UserFuatures();
  const { putAssignOrganizationApi, userApiSuccess, userApiLoading } =
    useUser();
  const [open, setOpen] = useState(false);

  const handleAssignCustomerClick = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSave = (body: AssignOrganization[]) => {
    putAssignOrganizationApi({
      userId: usersMagage.selectSearch,
      requestBody: body,
    });
  };

  useEffect(() => {
    setOpen(false);
  }, [userApiSuccess]);

  return (
    <>
      <BaseButton
        label={t("setting.customers.assignButton")}
        handleClick={handleAssignCustomerClick}
        className={classes.assignCustomerButton}
        disabled={!isAdministrator()}
      />
      <AssignCustomerDialogForm
        open={open}
        handleClose={handleCloseDialog}
        handleSave={handleSave}
      />
      {userApiLoading && <CircularProgressDialog />}
    </>
  );
};

export default CusotmerButtonRightField;
