import { useContext, useState } from "react";
import LoginApi from "../features/api/LoginApi.tsx";
import LoginContext from "../contexts/LoginContext.tsx";
import CheckValue from "../../common/utils/CheckValue.tsx";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import UserContext from "../contexts/UserContext.tsx";
import UserInfoResponse from "../types/response/UserInfoResponse.tsx";
import { LoginInfo } from "../types/response/LoginResponse.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";

export const useLogin = () => {
  const Message = MessageType();
  const { onCommonError } = ApiCommon();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<LoginInfo>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const { Login, Logout, OtpLogin } = LoginApi();

  /**
   * ログインAPI成功時
   */
  const onLoginSuccess = (response) => {
    setIsLoading(false);
    appContext?.setErrorOpen(false);
    appContext?.setAlertMessage("");
    if (!response.data || !response.headers) {
      setErrorMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    const data = response.data;
    const tokens = data.tokens;
    if (tokens === null || tokens === undefined) {
      // 2段階認証ONの場合
      setResponse(data);
    } else {
      // 2段階認証OFFの場合
      setCookie(response.headers);
      setToken(tokens.access_token, tokens.id_token);
      return navigate("/home");
    }
  };

  /**
   * ログインAPI失敗時
   */
  const onLoginError = (error) => {
    setIsLoading(false);
    const response = error.response;
    if (response && response.data && response.data.detail) {
      setErrorMessage(response.data.detail);
      return;
    }
    setErrorMessage(Message.ERROR_SYSTEM_ERROR);
  };

  /**
   * ログインAPI処理
   * @returns
   */
  const useLoginApi = async () => {
    if (
      !CheckValue.isEmptyValues([
        loginContext?.id || "",
        loginContext?.password || "",
      ])
    ) {
      setIsLoading(false);
      setErrorMessage(Message.ERROR_NO_INPUT_DATA);
      return;
    }
    setIsLoading(true);
    setErrorMessage("");
    exeLogin.refetch();
  };

  /**
   * session_id設定
   * @param headers
   */
  const setCookie = (headers) => {
    const sessionId = headers["set-cookie"]?.find((cookie) =>
      cookie.startsWith("session_id=")
    );
    if (sessionId) {
      Cookies.set("session_id", sessionId.split(";")[0].split("=")[1], {
        path: "/",
      });
    }
  };

  /**
   * アクセストークン、IDトークン設定処理
   * @param accessToken
   * @param idToken
   */
  const setToken = (accessToken: string, idToken: string) => {
    const access_token = jwtDecode(accessToken);
    const id_token = jwtDecode(idToken);
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("id_token", idToken);
    appContext?.setAccessToken(access_token);
    appContext?.setIdToken(id_token);
  };

  /**
   * 2段階認証API成功時
   * アクセストークン、IDトークンをローカルストレージに格納し、TOP画面へ遷移する
   */
  const onOtpLoginSuccess = (response) => {
    setIsLoading(false);
    if (!response.data || !response.headers) {
      setErrorMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    // session_id設定
    setCookie(response.headers);

    if (userContext) {
      userContext?.setUserInfo({} as UserInfoResponse);
    }

    // レスポンスデータ設定
    const data = response.data;
    setToken(data.access_token, data.id_token);
    return navigate("/home");
  };

  /**
   * 2段階認証API失敗時
   */
  const onOtpLoginError = (error) => {
    setIsLoading(false);
    const response = error.response;
    if (response && response.data && response.data.detail) {
      setErrorMessage(response.data.detail);
      return;
    }
    setErrorMessage(Message.ERROR_SYSTEM_ERROR);
  };

  /**
   * 2段階認証API処理
   * @returns
   */
  const useOtpLoginApi = async () => {
    setIsLoading(true);
    setErrorMessage("");
    if (!CheckValue.validationValueNum(loginContext?.onetimePassword || "")) {
      setIsLoading(false);
      setErrorMessage(Message.ERROR_VALIDATION);
      return;
    }
    exeOtpLogin.refetch();
  };

  /**
   * ログアウトAPI成功時
   * ローカルストレージに格納されてるアクセストークン、IDトークンを削除し、ログイン画面へ遷移する
   */
  const onLogoutSuccess = (response) => {
    setIsLoading(false);
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    userContext?.setUserInfo({} as UserInfoResponse);
    return navigate("/login");
  };

  /**
   * ログアウトAPI失敗時
   */
  const onLogoutError = (error) => {
    setIsLoading(false);
    onCommonError(error);
  };

  /**
   * ログアウトAPI処理
   * @returns
   */
  const useLogoutApi = async () => {
    setIsLoading(true);
    setErrorMessage("");
    exeLogout.refetch();
  };

  // ログインAPI実行
  const exeLogin = Login(
    loginContext?.id || "",
    loginContext?.password || "",
    localStorage.getItem("language") || "ja",
    onLoginSuccess,
    onLoginError
  );

  // 2段階認証API実行
  const exeOtpLogin = OtpLogin(
    loginContext?.id || "",
    loginContext?.onetimePassword || "",
    onOtpLoginSuccess,
    onOtpLoginError
  );

  // ログアウトAPI実行
  const exeLogout = Logout(onLogoutSuccess, onLogoutError);

  return {
    useLoginApi,
    useOtpLoginApi,
    useLogoutApi,
    isLoading,
    response,
    errorMessage,
  };
};
