// サイドメニューEnumクラス
export enum SideMenuEnum {
  home = "home",
  data_input = "data-input",
  company_parent = "company_parent",
  company = "company",
  product_parent = "product_parent",
  summary = "summary",
  product = "product",
  facility = "facility",
  renewable_energy = "renewable_energy",
}

export enum BottomSideMenusEnum {
  external_links = "external_links",
}

// 各種設定画面サイドメニューEnumクラス
export enum SettingSideMenuEnum {
  language = "language",
  security = "security",
  change_password = "change_password",
  setting_otp = "setting_otp",
  access_control = "access_control",
  users_manage = "users_manage",
  logs = "logs",
}
