import { Paper, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import classes from "./ExternalLinksForm.module.scss";
import ImageTitleSubCardField from "../../fields/ExternalLinks/ImageTitleSubCardField.tsx";
import { ExternalLinks } from "../../../types/response/ExternalLinksResponse.tsx";
import ImageCardField from "../../fields/ExternalLinks/ImageCardField.tsx";
import ImageTitleCardField from "../../fields/ExternalLinks/ImageTitleCardField.tsx";
import { CardTypeEnum } from "../../../types/enum/CardTypeEnum.tsx";

type ExternalLinksFormProps = {
  data: ExternalLinks;
};

const ExternalLinksForm: React.FC<ExternalLinksFormProps> = (props) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isHovered, setHovered] = useState(false);
  const [isLeftVisible, setIsLeftVisible] = useState(false);
  const [isRightVisible, setIsRightVisible] = useState(true);

  /**
   * スクロールイベント時
   * スクロール位置によってスクロールボタンの表示非表示判定処理を行う
   * @returns
   */
  const checkScroll = () => {
    if (!scrollRef.current) return;
    const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
    const isScrollable = scrollWidth > clientWidth;
    setIsLeftVisible(scrollLeft > 0);
    setIsRightVisible(isScrollable && scrollLeft < scrollWidth - clientWidth);
  };

  /**
   * スクロールボタン押下時
   * 対象方向へスクロール
   * @param direction
   * @returns
   */
  const scroll = (direction: "left" | "right") => {
    if (!scrollRef.current) return;
    const scrollAmount = 200;
    scrollRef.current.scrollBy({
      left: direction === "left" ? -scrollAmount : scrollAmount,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    checkScroll();
  }, []);

  /**
   * カードタイプ別に対象のコンポーネントを表示
   * @returns
   */
  const dispCardTypeField = () => {
    if (!props.data.card_type) return "";
    switch (props.data.card_type) {
      case CardTypeEnum.C1:
      case CardTypeEnum.C2:
        return (
          <ImageCardField
            handleScroll={scroll}
            isLeftVisible={isLeftVisible}
            isRightVisible={isRightVisible}
            data={props.data.links}
            isHovered={isHovered}
          />
        );
      case CardTypeEnum.C3:
        return (
          <ImageTitleCardField
            handleScroll={scroll}
            isLeftVisible={isLeftVisible}
            isRightVisible={isRightVisible}
            data={props.data.links}
            isHovered={isHovered}
          />
        );
      case CardTypeEnum.C4:
        return (
          <ImageTitleSubCardField
            handleScroll={scroll}
            isLeftVisible={isLeftVisible}
            isRightVisible={isRightVisible}
            data={props.data.links}
            isHovered={isHovered}
          />
        );
    }
    return (
      <ImageTitleSubCardField
        handleScroll={scroll}
        isLeftVisible={isLeftVisible}
        isRightVisible={isRightVisible}
        data={props.data.links}
        isHovered={isHovered}
      />
    );
  };

  return (
    <Paper className={classes.paperBox}>
      <Typography color="text.secondary" gutterBottom className={classes.title}>
        {props.data.title}
      </Typography>
      <div
        className={classes.cardBox}
        style={{
          overflowX: isHovered ? "auto" : "hidden",
        }}
        ref={scrollRef}
        onScroll={checkScroll}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {dispCardTypeField()}
      </div>
    </Paper>
  );
};

export default ExternalLinksForm;
