import React from "react";
import classes from "./ImageTitleCardField.module.scss";
import BackButton from "../../parts/Button/BackButton.tsx";
import ForwardButton from "../../parts/Button/ForwardButton.tsx";
import ImageTitleCard from "../../parts/Card/ImageTitleCard.tsx";
import { LinkInfo } from "../../../types/response/ExternalLinksResponse.tsx";

type ImageTitleCardFieldProps = {
  data: LinkInfo[];
  handleScroll: (direction: "left" | "right") => void;
  isLeftVisible: boolean;
  isRightVisible: boolean;
  isHovered: boolean;
};

const ImageTitleCardField: React.FC<ImageTitleCardFieldProps> = (props) => {
  const handleBackClick = () => {
    props.handleScroll("left");
  };

  const handleForwardClick = () => {
    props.handleScroll("right");
  };

  return (
    <>
      {props.isLeftVisible && props.isHovered && (
        <BackButton
          className={classes.backButton}
          iconClassName={classes.backButtonContent}
          handleClick={handleBackClick}
        />
      )}
      {props.data.map((item, i) => (
        <ImageTitleCard
          image={item.image}
          title={item.title}
          url={item.url}
          cardClass={classes.cardClass}
          cardContentClass={classes.cardContentClass}
          titleClass={classes.titleClass}
        />
      ))}
      {props.isRightVisible && props.isHovered && (
        <ForwardButton
          className={classes.forwardButton}
          iconClassName={classes.forwardButtonContent}
          handleClick={handleForwardClick}
        />
      )}
    </>
  );
};

export default ImageTitleCardField;
