import { useMutation } from "react-query";
import axios from "axios";
import UserInfoResponse from "../../types/response/UserInfoResponse.tsx";
import LanguageRequest from "../../types/request/LanguageRequest.tsx";
import { ApiCommon } from "../ApiCommon.tsx";

const UserApi = () => {
  const { CustomHeaders } = ApiCommon();
  /**
   * ユーザー情報取得API
   * @param useId
   */
  const GetUserInfo = (onSuccess, onError) => {
    return useMutation(
      async (userId: string) => {
        const response = await axios.get<UserInfoResponse>(
          process.env.REACT_APP_HOST_URL + "/v1/admin/users/" + userId,
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };
  /**
   * 言語設定API
   * @param useId
   */
  const PutLanguage = (onSuccess, onError) => {
    return useMutation(
      async (request: LanguageRequest) => {
        const response = await axios.put(
          process.env.REACT_APP_HOST_URL + "/v1/admin/users/language",
          request,
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  return { GetUserInfo, PutLanguage };
};

export default UserApi;
