import { useMutation } from "react-query";
import axios from "axios";
import { ApiCommon } from "../ApiCommon.tsx";
import OrganizationUsersResponse from "../../types/response/OrganizationUsersResponse.tsx";

const OrganizationApi = () => {
  const { CustomHeaders } = ApiCommon();
  /**
   * ユーザー一覧取得API
   * @param useId
   */
  const GetUsers = (onSuccess, onError) => {
    return useMutation(
      async (organizationId: string) => {
        const response = await axios.get<OrganizationUsersResponse>(
          process.env.REACT_APP_HOST_URL +
            "/v1/organizations/" +
            organizationId +
            "/users",
          {
            headers: CustomHeaders(),
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  };

  return {
    GetUsers,
  };
};

export default OrganizationApi;
