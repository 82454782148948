import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import classes from "./DeleteUserDialog.module.scss";
import { useTranslation } from "react-i18next";
import { OrganizationUsers } from "../../../types/response/OrganizationUsersResponse";

type DeleteUserDialogProps = {
  editUser?: OrganizationUsers;
  className?: string;
  open: boolean;
  handleClose: () => void;
  handleExecute: (param: OrganizationUsers) => void;
};

const DeleteUserDialog: React.FC<DeleteUserDialogProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      className={classes.dialog}
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle className={classes.title}>
        <Typography
          variant="h6"
          component="div"
          className={classes.titleName}
          sx={{
            fontWeight: "bold",
            color: "red",
          }}
        >
          {`${t("setting.users.deleteUser.title")}`}
        </Typography>
        <Typography variant="h6" component="div" className={classes.titleName}>
          {`${t("setting.users.deleteUser.confirm")}`}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {props.editUser && (
          <>
            <Typography
              variant="body1"
              component="div"
              className={classes.contentMessage}
            >
              {`${t("setting.users.deleteUser.userName")}: ${
                props.editUser.last_name
              } ${props.editUser.first_name}`}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              className={classes.contentMessage}
            >
              {`${t("setting.users.deleteUser.mail")}: ${props.editUser.email}`}
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={props.handleClose}
          color="secondary"
        >
          {t("common.cancel")}
        </Button>
        <Button
          className={classes.button}
          onClick={() => props.handleExecute(props.editUser)}
          color="primary"
        >
          {t("setting.users.deleteUser.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserDialog;
