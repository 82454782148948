import { useContext, useState } from "react";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";
import LanguageRequest from "../types/request/LanguageRequest.tsx";
import LogApi from "../features/api/LogApi.tsx";
import { OperatorLogs } from "../types/response/OperatorLogsResponse.tsx";
import LogContext from "../contexts/LogContext.tsx";

export const useLog = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);
  const logContext = useContext(LogContext);
  const { onCommonError } = ApiCommon();
  const { PostOperatorLogs } = LogApi();
  const [logApiLoading, setLogApiLoading] = useState(false);

  /**
   * ログ情報取得API失敗時
   */
  const onError = (error) => {
    onCommonError(error);
    setLogApiLoading(false);
  };

  /**
   * ログ情報取得API成功時
   */
  const onPostOperatorLogsSuccess = (
    data: OperatorLogs,
    variables: LanguageRequest
  ) => {
    setLogApiLoading(false);
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
    }
    if (data.is_over_limit) {
      appContext?.setInfoOpen(true);
      appContext?.setAlertMessage(Message.LOG_OVER_LIMIT);
    }
    logContext.setOperatorLogs(data);
  };

  /**
   * ログ情報取得
   */
  const postOperatorLogsApi = (fromDate: string, toDate: string) => {
    if (!appContext?.idToken.sub) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_SYSTEM_ERROR);
      return;
    }
    setLogApiLoading(true);
    exePostOperatorLogs.mutate({ fromDate: fromDate, toDate: toDate });
  };

  // ログ情報取得API実行
  const exePostOperatorLogs = PostOperatorLogs(
    onPostOperatorLogsSuccess,
    onError
  );

  return {
    logApiLoading,
    postOperatorLogsApi,
  };
};
