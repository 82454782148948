import React, { createContext, useState } from "react";
import { OperatorLogs } from "../types/response/OperatorLogsResponse";

type SecurityProps = {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  searchOpt: string;
  setSearchOpt: React.Dispatch<React.SetStateAction<string>>;
};

const defaultValue = {
  searchText: "",
  setSearchText: () => {},
  searchOpt: "all",
  setSearchOpt: () => {},
};

// ユーザー管理コンテキスト
const SecurityContext = createContext<SecurityProps>(defaultValue);

type Props = {
  children: React.ReactNode;
};

export const SecurityProvider: React.VFC<Props> = ({ children }) => {
  const [searchText, setSearchText] = useState<string>("");
  const [searchOpt, setSearchOpt] = useState<string>("all");

  return (
    <SecurityContext.Provider
      value={{
        searchText,
        setSearchText,
        searchOpt,
        setSearchOpt,
      }}
    >
      {children}
    </SecurityContext.Provider>
  );
};

export default SecurityContext;
