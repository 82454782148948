import React, { useContext } from "react";
import classes from "./LogsListForm.module.scss";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import LogContext from "../../../contexts/LogContext.tsx";
import CommonUtils from "../../../common/utils/CommonUtils.tsx";

type LogsListFormProps = {};

const LogsListForm: React.FC<LogsListFormProps> = (props) => {
  const { t } = useTranslation();
  const logContext = useContext(LogContext);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * 表示ユーザー一覧データ作成処理
   * 絞り込み内容に応じて、表示するユーザー一覧を作成する
   * @returns
   */
  const dispLogsList = () => {
    if (
      !logContext ||
      !logContext.operatorLogs ||
      !logContext.operatorLogs.operator_logs
    )
      return [];
    return logContext.operatorLogs.operator_logs.filter((data) => {
      return (
        data.user_name.includes(logContext.searchText || "") ||
        data.log_detail.includes(logContext.searchText || "")
      );
    });
  };

  return (
    <>
      <TableContainer
        className={classes.activeInfoTable}
        sx={{ maxHeight: "55vh" }}
      >
        <Table
          size="small"
          sx={{ tableLayout: "fixed", width: "100%", height: "100%" }}
          stickyHeader
        >
          <TableHead>
            <TableRow key="th">
              <TableCell sx={{ width: "20%", zIndex: 0 }}>
                {t("logs.usersTable.date")}
              </TableCell>
              <TableCell sx={{ width: "20%", zIndex: 0 }}>
                {t("logs.usersTable.userName")}
              </TableCell>
              <TableCell sx={{ width: "60%", zIndex: 0 }}>
                {t("logs.usersTable.log")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ overflowY: "auto" }}>
            {dispLogsList().map((data, i) => {
              return (
                <TableRow key={"td_" + i}>
                  <TableCell sx={{ width: "20%" }}>
                    {CommonUtils.formatDateYYYYMMDDhhmm_slash(data.operated_at)}
                  </TableCell>
                  <TableCell sx={{ width: "20%" }}>{data.user_name}</TableCell>
                  <TableCell sx={{ width: "60%" }}>{data.log_detail}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        labelRowsPerPage={t("common.rowsPerPage")}
        component="div"
        count={dispLogsList().length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={classes.tablePagination}
      />
    </>
  );
};

export default LogsListForm;
