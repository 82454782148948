import React, { useContext, useEffect } from "react";
import classes from "./LogButtonLeftField.module.scss";
import { useTranslation } from "react-i18next";
import BaseButton from "../../parts/Button/BaseButton.tsx";
import DateField from "../../parts/Date/DateField.tsx";
import { useLog } from "../../../hooks/useLog.tsx";
import { Box } from "@mui/material";
import LogContext from "../../../contexts/LogContext.tsx";
import { LogFuatures } from "../../../features/LogFuatures.tsx";
import CommonUtils from "../../../common/utils/CommonUtils.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";

type LogButtonLeftFieldProps = {};

const LogButtonLeftField: React.FC<LogButtonLeftFieldProps> = (props) => {
  const { t } = useTranslation();
  const logContext = useContext(LogContext);
  const { postOperatorLogsApi, logApiLoading } = useLog();
  const { setDefaultFromToDate } = LogFuatures();

  /**
   * 初期表示時
   * 開始日・終了日をデフォルト値に設定
   */
  useEffect(() => {
    setDefaultFromToDate();
  }, []);

  /**
   * 検索ボタンクリックイベント
   */
  const handleSearchClick = () => {
    postOperatorLogsApi(
      CommonUtils.formatDayYYYYMMDDhhmm(
        new Date(logContext.searchFromDate),
        true
      ),
      CommonUtils.formatDayYYYYMMDDhhmm(new Date(logContext.searchToDate), true)
    );
  };

  /**
   * リセットボタンクリックイベント
   */
  const handleResetClick = () => {
    setDefaultFromToDate();
  };

  return (
    <Box className={classes.leftField}>
      <DateField
        fromDate={logContext.searchFromDate}
        setFromDate={logContext.setSearchFromDate}
        toDate={logContext.searchToDate}
        setToDate={logContext.setSearchToDate}
        className={classes.dateField}
        dateClassName={classes.datePicker}
      />
      <BaseButton
        label={t("logs.searchButton")}
        handleClick={handleSearchClick}
        className={classes.searchButton}
      />
      <BaseButton
        label={t("logs.resetButton")}
        handleClick={handleResetClick}
        className={classes.resetButton}
      />
      {logApiLoading && <CircularProgressDialog />}
    </Box>
  );
};

export default LogButtonLeftField;
