import React, { useContext, useEffect, useState } from "react";
import classes from "./LogLayout.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import UsersButtonForm from "../../forms/SettingUsers/UsersButtonForm.tsx";
import UsersFilterForm from "../../forms/SettingUsers/UsersFilterForm.tsx";
import UsersListForm from "../../forms/SettingUsers/UsersListForm.tsx";
import UsersManageContext from "../../../contexts/UsersManageContext.tsx";
import CusomerListForm from "../../forms/SettingUsers/CusomerListForm.tsx";
import UserContext from "../../../contexts/UserContext.tsx";
import { UsersManageMode } from "../../../types/enum/UsersManageEnum.tsx";
import LogsListForm from "../../forms/Log/LogsListForm.tsx";
import LogsFilterForm from "../../forms/Log/LogsFilterForm.tsx";
import SearchButtonForm from "../../forms/Log/SearchButtonForm.tsx";

type LogLayoutProps = {};

const LogLayout: React.FC<LogLayoutProps> = (props) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const userInfo = userContext ? userContext.userInfo : {};
  const usersMagage = useContext(UsersManageContext);
  const mode = usersMagage.mode || UsersManageMode.users;
  const [select, setSelect] = useState<string>("");

  useEffect(() => {}, [mode, select]);

  return (
    <Box className={classes.usersManage}>
      <Typography variant="h5" component="div" className={classes.title}>
        {t("setting.users.title")}
      </Typography>
      <Box className={classes.usersButtonForm}>
        {/* ユーザー追加などのボタンForm */}
        <SearchButtonForm />
      </Box>
      <Box className={classes.usersFilterForm}>
        {/* 絞り込み用Form */}
        <LogsFilterForm />
      </Box>
      <Box className={classes.usersListForm}>
        {/* ユーザー一覧Form */}
        <LogsListForm />
      </Box>
    </Box>
  );
};

export default LogLayout;
