import { useContext, useState } from "react";
import UserApi from "../features/api/UserApi.tsx";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import UserContext from "../contexts/UserContext.tsx";
// import UserInfoRequest from "../types/request/UserInfoRequest.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";
import { UserInfo } from "../types/response/UserInfoResponse.tsx";
import { useNavigate } from "react-router-dom";
import LanguageRequest from "../types/request/LanguageRequest.tsx";

export const useUser = () => {
  const navigate = useNavigate();
  const Message = MessageType();
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const { onCommonError } = ApiCommon();
  const [userApiLoading, setUserApiLoading] = useState(false);
  const { GetUserInfo, PutLanguage } = UserApi();

  /**
   * 管理者権限ユーザー判定処理
   * @returns true 管理者権限あり false 管理者権限なし
   */
  const isAdministrator = (user?: UserInfo) => {
    const userData = user ? user : userInfo;
    if (userData && userData.organizations.length) {
      if (
        userData.organizations.find((data) => {
          return data.role_id.includes("administrator");
        })
      ) {
        return true;
      }
    }
    return false;
  };

  /**
   * ユーザー情報取得API成功時
   */
  const onSuccess = (data) => {
    setUserApiLoading(false);
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    if (isAdministrator(data)) {
      userContext?.setUserInfo(data);
    } else {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_ADMINISTRATOR_ROLE);
      return navigate("/admin/login");
    }
  };

  /**
   * ユーザー情報取得API成功時（ログイン時）
   */
  const onLoginUserSuccess = (data) => {
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    setUserApiLoading(false);
    if (isAdministrator(data)) {
      userContext?.setUserInfo(data);
      return navigate("/admin/account-create");
    } else {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_ADMINISTRATOR_ROLE);
      return navigate("/admin/login");
    }
  };

  /**
   * ユーザー情報取得API失敗時
   */
  const onError = (error) => {
    onCommonError(error);
    setUserApiLoading(false);
  };

  /**
   * ユーザー情報取得
   */
  const getUserApi = (sub) => {
    setUserApiLoading(true);
    exeGetUser.mutate(sub || "");
  };

  /**
   * ユーザー情報取得(ログイン時)
   */
  const getLoginUserApi = (sub) => {
    setUserApiLoading(true);
    exeLoginGetUser.mutate(sub || "");
  };

  /**
   * 言語設定API成功時(TOP画面専用)
   */
  const onLanguageTopSuccess = (data, variables: LanguageRequest) => {
    setUserApiLoading(false);
    userInfo.language = variables.language;
    userContext?.setUserInfo(userInfo);
  };

  /**
   * 言語設定
   */
  const putLanguageApi = (language: string, isTop?: boolean) => {
    if (!appContext?.idToken.sub) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_SYSTEM_ERROR);
      return;
    }
    const param: LanguageRequest = {
      language: language,
    };
    setUserApiLoading(true);
    exePutTopLanguage.mutate(param);
  };

  // ユーザー情報取得API実行
  const exeGetUser = GetUserInfo(onSuccess, onError);

  // ユーザー情報取得API実行(ログイン時)
  const exeLoginGetUser = GetUserInfo(onLoginUserSuccess, onError);

  // 言語設定API実行(TOP画面専用)
  const exePutTopLanguage = PutLanguage(onLanguageTopSuccess, onError);

  return {
    getUserApi,
    getLoginUserApi,
    userApiLoading,
    isAdministrator,
    putLanguageApi,
  };
};
