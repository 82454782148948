import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import classes from "./ImageCard.module.scss";
import CommonUtils from "../../../common/utils/CommonUtils.tsx";
import { useLinks } from "../../../hooks/useLinks.tsx";

type ImageCardProps = {
  image: string;
  title: string;
  url: string;
  cardClass?: string;
  cardContentClass?: string;
  titleClass?: string;
};

const ImageCard: React.FC<ImageCardProps> = (props) => {
  const { getLinksRedirectApi } = useLinks();
  /**
   * カードクリック時
   * 新規タブにて、対象リンクを開く
   */
  const handleClick = () => {
    getLinksRedirectApi(props.url);
    window.open(props.url, "_blank");
  };

  return (
    <Card className={props.cardClass}>
      <CardActionArea
        style={{ height: "100%" }}
        className={`${classes.imageButton}`}
        onClick={handleClick}
      >
        {props.image && (
          <CardMedia
            component="img"
            image={props.image}
            alt={props.image}
            className={classes.image}
          />
        )}
        {!props.image && props.title && (
          <CardContent className={props.cardContentClass}>
            <Tooltip title={props.title}>
              <Typography gutterBottom className={props.titleClass}>
                {CommonUtils.dispLabelOmisssion(props.title, 25)}
              </Typography>
            </Tooltip>
          </CardContent>
        )}
      </CardActionArea>
    </Card>
  );
};

export default ImageCard;
