import { useContext, useState } from "react";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";
import LanguageRequest from "../types/request/LanguageRequest.tsx";
import CorporateApi from "../features/api/CorporateApi.tsx";

export const useCorporate = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);
  const { onCommonError } = ApiCommon();
  const { PutCorporateMfa } = CorporateApi();
  const [corporateApiLoading, setCorporateApiLoading] = useState(false);
  const [corporateApiSuccess, setCorporateApiSuccess] = useState(false);

  /**
   * 2段階認証設定API失敗時
   */
  const onError = (error) => {
    onCommonError(error);
    setCorporateApiLoading(false);
  };

  /**
   * 2段階認証設定API成功時
   */
  const onPutCorporateMfaSuccess = (data, variables: LanguageRequest) => {
    setCorporateApiLoading(false);
    setCorporateApiSuccess(true);
    appContext?.setAlertMessage(Message.SUCCESS_PUT_OTP);
    appContext?.setSuccessOpen(true);
  };

  /**
   * 2段階認証設定
   */
  const putCorporateMfaApi = (corporateNumber: string, mfaFlag: boolean) => {
    if (!appContext?.idToken.sub) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_SYSTEM_ERROR);
      return;
    }
    setCorporateApiLoading(true);
    setCorporateApiSuccess(false);
    exePutCorporateMfa.mutate({
      corporateNumber: corporateNumber,
      mfaFlag: mfaFlag,
    });
  };

  // 2段階認証設定API実行
  const exePutCorporateMfa = PutCorporateMfa(onPutCorporateMfaSuccess, onError);

  return {
    corporateApiLoading,
    corporateApiSuccess,
    putCorporateMfaApi,
  };
};
