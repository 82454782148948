import { useTranslation } from "react-i18next";
import SideMenu from "./SideMenu";
import {
  BottomSideMenusEnum,
  SettingSideMenuEnum,
  SideMenuEnum,
} from "./enum/SideMenuEnum.tsx";
import { ReportFilesEnum } from "./enum/ReportFilesEnum.tsx";
import { LanguageEnum } from "./enum/LanguageEnum.tsx";

// 絞り込み条件表示対象サイドメニュー
export const DispSearchItem = [
  SideMenuEnum.company_parent,
  SideMenuEnum.company,
  SideMenuEnum.product_parent,
  SideMenuEnum.summary,
  SideMenuEnum.product,
  SideMenuEnum.facility,
  SideMenuEnum.renewable_energy,
];

type ReportOutput = {
  key: string;
  pageName: string;
  reports: {
    type: string;
    title: string;
    disabled: boolean;
    dispLan: string[]; //空の場合は全表示、値がある場合は、その言語の時のみ表示
  }[];
};

const SideMenusType = () => {
  const { t } = useTranslation();
  const SideMenus: SideMenu[] = [
    {
      id: SideMenuEnum.home,
      title: t("type.sideMenu.sideMenus.home"),
      children: [],
    },
    {
      id: SideMenuEnum.data_input,
      title: t("type.sideMenu.sideMenus.data"),
      children: [],
    },
    {
      id: SideMenuEnum.company_parent,
      title: t("type.sideMenu.sideMenus.companyParent"),
      children: [
        {
          id: SideMenuEnum.company,
          title: t("type.sideMenu.sideMenus.company"),
        },
        // {
        //   id: "company_free_analysis",
        //   title: t("type.sideMenu.sideMenus.companyFreeAnalysis"),
        // },
      ],
    },
    {
      id: SideMenuEnum.product_parent,
      title: t("type.sideMenu.sideMenus.productParent"),
      children: [
        {
          id: SideMenuEnum.summary,
          title: t("type.sideMenu.sideMenus.summary"),
        },
        {
          id: SideMenuEnum.product,
          title: t("type.sideMenu.sideMenus.product"),
        },
        {
          id: SideMenuEnum.facility,
          title: t("type.sideMenu.sideMenus.facility"),
        },
        // {
        //   id: SideMenuEnum.renewable_energy,
        //   title: t("type.sideMenu.sideMenus.renewableEnergy"),
        // },
        // {
        //   id: "product_free_analysis",
        //   title: t("type.sideMenu.sideMenus.productFreeAnalysis"),
        // },
      ],
    },
    // {
    //   id: "energy_saving",
    //   title: t("type.sideMenu.sideMenus.energySaving"),
    //   children: [],
    // },
    // {
    //   id: "warming_countermeasures",
    //   title: t("type.sideMenu.sideMenus.warmingCountermeasures"),
    //   children: [],
    // },
  ];
  const BottomSideMenus: SideMenu[] = [
    {
      id: BottomSideMenusEnum.external_links,
      title: t("type.sideMenu.sideMenus.link"),
      children: [],
    },
  ];

  const SettingSideMenus: SideMenu[] = [
    {
      id: SettingSideMenuEnum.users_manage,
      title: t("type.sideMenu.settingSideMunus.usersManage"),
      children: [],
    },
    {
      id: SettingSideMenuEnum.security,
      title: t("type.sideMenu.settingSideMunus.security"),
      children: [
        // {
        //   id: SettingSideMenuEnum.change_password,
        //   title: t("type.sideMenu.settingSideMunus.changePassword"),
        // },
        {
          id: SettingSideMenuEnum.setting_otp,
          title: t("type.sideMenu.settingSideMunus.settingOpt"),
        },
        // {
        //   id: SettingSideMenuEnum.access_control,
        //   title: t("type.sideMenu.settingSideMunus.accessControl"),
        // },
      ],
    },
    {
      id: SettingSideMenuEnum.logs,
      title: t("type.sideMenu.settingSideMunus.logs"),
      children: [],
    },
    {
      id: SettingSideMenuEnum.language,
      title: t("type.sideMenu.settingSideMunus.language"),
      children: [],
    },
  ];

  // サイドメニュー毎の帳票出力項目一覧
  const ReportOutputItems: ReportOutput[] = [
    {
      key: SideMenuEnum.company,
      pageName: t("type.sideMenu.reportOutputItems.company.pageName"),
      reports: [
        {
          type: ReportFilesEnum.data_output,
          title: t("type.sideMenu.reportOutputItems.company.dataOutput"),
          disabled: false,
          dispLan: [],
        },
        {
          type: ReportFilesEnum.energy_saving,
          title: t("type.sideMenu.reportOutputItems.company.checkOutput"),
          disabled: false,
          dispLan: [LanguageEnum.ja],
        },
        {
          type: ReportFilesEnum.registration_data,
          title: t(
            "type.sideMenu.reportOutputItems.company.registrationOutput"
          ),
          disabled: false,
          dispLan: [],
        },
        {
          type: ReportFilesEnum.csv,
          title: t("type.sideMenu.reportOutputItems.company.csv"),
          disabled: false,
          dispLan: [],
        },
        {
          type: ReportFilesEnum.pdf,
          title: t("type.sideMenu.reportOutputItems.company.pdf"),
          disabled: false,
          dispLan: [],
        },
      ],
    },
    {
      key: SideMenuEnum.summary,
      pageName: t("type.sideMenu.reportOutputItems.summary.pageName"),
      reports: [
        {
          type: ReportFilesEnum.data_output,
          title: t("type.sideMenu.reportOutputItems.summary.dataOutput"),
          disabled: false,
          dispLan: [],
        },
        {
          type: ReportFilesEnum.check_output,
          title: t("type.sideMenu.reportOutputItems.summary.checkOutput"),
          disabled: false,
          dispLan: [],
        },
        {
          type: ReportFilesEnum.csv,
          title: t("type.sideMenu.reportOutputItems.summary.csv"),
          disabled: false,
          dispLan: [],
        },
        {
          type: ReportFilesEnum.pdf,
          title: t("type.sideMenu.reportOutputItems.summary.pdf"),
          disabled: false,
          dispLan: [],
        },
      ],
    },
    {
      key: SideMenuEnum.product,
      pageName: t("type.sideMenu.reportOutputItems.product.pageName"),
      reports: [
        {
          type: ReportFilesEnum.data_output,
          title: t("type.sideMenu.reportOutputItems.product.dataOutput"),
          disabled: false,
          dispLan: [],
        },
        {
          type: ReportFilesEnum.csv,
          title: t("type.sideMenu.reportOutputItems.product.csv"),
          disabled: false,
          dispLan: [],
        },
        {
          type: ReportFilesEnum.pdf,
          title: t("type.sideMenu.reportOutputItems.product.pdf"),
          disabled: false,
          dispLan: [],
        },
      ],
    },
    {
      key: SideMenuEnum.facility,
      pageName: t("type.sideMenu.reportOutputItems.facility.pageName"),
      reports: [
        {
          type: ReportFilesEnum.data_output,
          title: t("type.sideMenu.reportOutputItems.facility.dataOutput"),
          disabled: false,
          dispLan: [],
        },
        {
          type: ReportFilesEnum.csv,
          title: t("type.sideMenu.reportOutputItems.facility.csv"),
          disabled: false,
          dispLan: [],
        },
        {
          type: ReportFilesEnum.pdf,
          title: t("type.sideMenu.reportOutputItems.facility.pdf"),
          disabled: false,
          dispLan: [],
        },
      ],
    },
  ];
  return {
    SideMenus,
    BottomSideMenus,
    SettingSideMenus,
    ReportOutputItems,
  };
};

export default SideMenusType;
