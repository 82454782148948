import React from "react";
import ContentLayout from "../../layouts/Common/ContentLayout.tsx";
import LanguageLayout from "../../layouts/SettingLanguage/LanguageLayout.tsx";

const LanguagePage = () => {
  return (
    <ContentLayout>
      <LanguageLayout />
    </ContentLayout>
  );
};

export default LanguagePage;
