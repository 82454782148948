import React from "react";
import classes from "./ChangePasswordLayout.module.scss";
import { Box } from "@mui/material";
import ChangePasswordForm from "../../forms/SettingChangePassword/ChangePasswordForm.tsx";

type ChangePasswordLayoutProps = {};

const ChangePasswordLayout: React.FC<ChangePasswordLayoutProps> = (props) => {
  return (
    <Box className={classes.newPassword}>
      <ChangePasswordForm />
    </Box>
  );
};

export default ChangePasswordLayout;
